import '../Assets/common.css'
import '../Components/Body/body.css'
import Search from '../Components/Header/search';
import Menu from '../Components/Header/menu';
import { Link, useLocation  } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import Modal from '../Utils/modal';
import Comments from '../Components/Body/comments';

import person from '../Assets/images/person-2.png'
import getclubsDetail from '../services/clubDetailService';
import moment from 'moment';
import Footer from '../Components/Footer/footer';
import GoUp from '../Components/Footer/goUp';


//const backEndUrl = "http://localhost:8000/media/"
const backEndUrl = "https://footballback.zeaye.com/media/"
let homeYellowRedCards = 0, awayYellowRedCards = 0, homePenality = 0,awayPenality = 0, homeSubstitution = 0, awaySubstitution = 0;
let homeInjury = 0, awayInjury = 0
function Highlight(){

    const location = useLocation()
    //const {allMatchs, activeH} = location.state || {}
    const {allMatchs, activeH } = location.state || {};

    const [matchs, setMatchs] = useState(allMatchs || [])
    
    const [highlightActive, setHighlightActive] = useState(activeH);

    const prevMatchsRef = useRef(allMatchs);

    var [highlightFiltered, setHighlightFiltered] = useState()
    
    const [homeFormationFinal, setHomeFormationFinal] = useState({});
    const [awayFormationFinal, setAwayFormationFinal] = useState({});
    const [homePlayers, setHomePlayers] = useState();
    const [awayPlayers, setAwayPlayers] = useState();
    const [homeSubstitutionAll, setHomeSubsitutionAll] = useState({});
    const [awaySubstitutionAll, setAwaySubstitutionAll] = useState({});
    const [homeManager, setHomeManager] = useState({})
    const [awayManager, setAwayManager] = useState({})


    
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalType, setModalType] = useState('');

    
    const goalContent = (arg) =>{
        var cont = ''
        if(arg === 'home'){
            if(highlightActive.homeGoalNo > 0){
                const goalScorers = highlightActive.homeGoalScorers?.split(',');
                const goalAssisters = highlightActive.homeGoalAssist?.split(',');
                const goalAt = highlightActive.homeGoalScoredAt?.split(',');

                if(goalScorers){
                    const goal = goalScorers.map((g, index) =>({
                        scoredBy: g,
                        assistBy: goalAssisters[index],
                        scoredAt: goalAt[index]
                    }))
            
                    cont = <table className='modal-table'>
                            <thead>
                                <tr><td colSpan={4} className='modal-title'>{highlightActive.home}'s Goal Detail</td></tr>
                                <tr>
                                    <th></th>
                                    <th>Scored By</th>
                                    <th>Assisted By</th>
                                    <th>at(minutes)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {goal.map((g, index) =>(
                                     <tr  key={index}>
                                        <th><span className='icon-football'><i className='bx bx-football'></i></span></th>
                                        <td>{findPlayer(arg, parseInt(g.scoredBy))[0]?.staffName}</td>
                                        <td>{findPlayer(arg, parseInt(g.assistBy))[0]?.staffName}</td>
                                        <td>{g.scoredAt}</td>
                                     </tr>
                                ))}
                               
                           
                            </tbody>
    
                        </table>
                    
                }
                setModalType('sm');
                setModalContent(cont) ; 
                setIsModalOpen(true) ; 
            } else {
                setIsModalOpen(false);
            }
             
        } else{
            if(highlightActive.awayGoalNo > 0 ){
                const goalScorers = highlightActive.awayGoalScorers?.split(',');
                const goalAssisters = highlightActive.awayGoalAssist?.split(',');
                const goalAt = highlightActive.awayGoalScoredAt?.split(',');

                if(goalScorers){
                    const goal = goalScorers.map((g, index) =>({
                        scoredBy: g,
                        assistBy: goalAssisters[index],
                        scoredAt: goalAt[index]
                    }))

                
                    cont = <table className='modal-table'>
                            <thead>
                                <tr><td colSpan={4} className='modal-title'>{highlightActive.away}'s Goal Detail</td></tr>
                                <tr>
                                    <th></th>
                                    <th>Scored By</th>
                                    <th>Assisted By</th>
                                    <th>at(minutes)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {goal.map((g, index) =>(
                                    <tr key={index}>
                                        <th ><span className='icon-football'><i className='bx bx-football'></i></span></th>
                                        <td>{findPlayer(arg, parseInt(g.scoredBy))[0]?.staffName }</td>
                                        <td>{findPlayer(arg, parseInt(g.assistBy))[0]?.staffName }</td>
                                        <td>{g.scoredAt}</td>
                                    </tr>
                                ))}
                            
                        
                            </tbody>

                    </table>

                }
                setModalType('sm');
                setModalContent(cont) ; 
                setIsModalOpen(true) ;   

            } else{
                setIsModalOpen(false);
            }
        }

    }

    const yellowRedCardContent = (arg) =>{
        var contYellow = ''
        var contRed = ''
        var cont = ''
        if(arg === 'home'){
           
            if(highlightActive.homeYellowCards && highlightActive.homeRedCards){
                const yellowCards = highlightActive.homeYellowCards?.split(',');
                const redCards = highlightActive.homeRedCards?.split(',');
                const yellowCardsAt = highlightActive.homeYellowCardsAt?.split(',');
                const redCardsAt = highlightActive.homeRedCardsAt?.split(',');

                const yellow = yellowCards.map((y, index) =>({
                    yellowCardTo: y,
                    yellowCardAt: yellowCardsAt[index]
                }))

                const red = redCards.map((r, index) =>({
                    redCardTo: r,
                    redCardAt: redCardsAt[index]
                }))
        
                contYellow = <table className='modal-table'>
                            <thead>
                                <tr><td colSpan={3} className='modal-title'>{highlightActive.home}'s Yellow Card Detail</td></tr>
                                <tr>
                                    <th></th>
                                    <th>Yellow Card To</th>
                                    <th>at(minutes)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {yellow.map((yc, index) =>(
                                        <tr  key={index}>
                                        <th><span className='icon-yellow-card'><i className='bx bx-book'></i></span></th>
                                        <td>{findPlayer(arg, parseInt(yc.yellowCardTo))[0]?.staffName }</td>
                                        <td>{yc.yellowCardAt}</td>
                                        </tr>
                                ))}
                                
                            
                            </tbody>

                    </table>

                    contRed = <table className='modal-table'>
                            <thead>
                                <tr><td colSpan={3} className='modal-title'>{highlightActive.home}'s Red Card Detail</td></tr>
                                <tr>
                                    <th></th>
                                    <th>Red Card To</th>
                                    <th>at(minutes)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {red.map((rc, index) =>(
                                        <tr  key={index}>
                                        <th><span className='icon-red-card'><i className='bx bx-book'></i></span></th>
                                        <td>{ findPlayer(arg, parseInt(rc.redCardTo))[0]?.staffName }</td>
                                        <td>{rc.redCardAt}</td>
                                        </tr>
                                ))}
                                

                            </tbody>

                    </table>

                    cont = <div> {contYellow} {contRed} </div>
                    setModalType('sm');
                    setModalContent(cont) ; 
                    setIsModalOpen(true) ; 
            } else if(highlightActive.homeYellowCards) {
                const yellowCards = highlightActive.homeYellowCards?.split(',');
                const yellowCardsAt = highlightActive.homeYellowCardsAt?.split(',');

                const yellow = yellowCards.map((y, index) =>({
                    yellowCardTo: y,
                    yellowCardAt: yellowCardsAt[index]
                }))
        
                contYellow = <table className='modal-table'>
                            <thead>
                                <tr><td colSpan={3} className='modal-title'>{highlightActive.home}'s Yellow Card Detail</td></tr>
                                <tr>
                                    <th></th>
                                    <th>Yellow Card To</th>
                                    <th>at(minutes)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {yellow.map((yc, index) =>(
                                        <tr  key={index}>
                                        <th><span className='icon-yellow-card'><i className='bx bx-book'></i></span></th>
                                        <td>{findPlayer(arg, parseInt(yc.yellowCardTo))[0]?.staffName }</td>
                                        <td>{yc.yellowCardAt}</td>
                                        </tr>
                                ))}
                                
                            
                            </tbody>

                    </table>

                    cont = contYellow

                    setModalType('sm');
                    setModalContent(cont) ; 
                    setIsModalOpen(true) ; 
            } else if(highlightActive.homeRedCards){
                const redCards = highlightActive.homeRedCardsCards?.split(',');
                const redCardsAt = highlightActive.homeRedCardsAt?.split(',');
                const red = redCards.map((r, index) =>({
                    redCardTo: r,
                    redCardAt: redCardsAt[index]
                }))
        
                contRed = <table className='modal-table'>
                        <thead>
                            <tr><td colSpan={3} className='modal-title'>{highlightActive.home}'s Red Card Detail</td></tr>
                            <tr>
                                <th></th>
                                <th>Red Card To</th>
                                <th>at(minutes)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {red.map((rc, index) =>(
                                    <tr  key={index}>
                                    <th><span className='icon-red-card'><i className='bx bx-book'></i></span></th>
                                    <td>{findPlayer(arg, parseInt(rc.redCardTo))[0]?.staffName }</td>
                                    <td>{rc.redCardAt}</td>
                                    </tr>
                            ))}
                            

                        </tbody>

                </table>

                cont = contRed;
                setModalType('sm');
                setModalContent(cont) ; 
                setIsModalOpen(true) ; 
            } else{
                setIsModalOpen(true) ; 
            }
        } else{
           

            if(highlightActive.awayYellowCards && highlightActive.awayRedCards){
                const yellowCards = highlightActive.awayYellowCards?.split(',');
                const redCards = highlightActive.awayRedCards?.split(',');
                const yellowCardsAt = highlightActive.awayYellowCardsAt?.split(',');
                const redCardsAt = highlightActive.awayRedCardsAt?.split(',');

                const yellow = yellowCards.map((y, index) =>({
                    yellowCardTo: y,
                    yellowCardAt: yellowCardsAt[index]
                }))

                const red = redCards.map((r, index) =>({
                    redCardTo: r,
                    redCardAt: redCardsAt[index]
                }))
        
                contYellow = <table className='modal-table'>
                            <thead>
                                <tr><td colSpan={3} className='modal-title'>{highlightActive.away}'s Yellow Card Detail</td></tr>
                                <tr>
                                    <th></th>
                                    <th>Yellow Card To</th>
                                    <th>at(minutes)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {yellow.map((yc, index) =>(
                                        <tr  key={index}>
                                        <th><span className='icon-yellow-card'><i className='bx bx-book'></i></span></th>
                                        <td>{findPlayer(arg, parseInt(yc.yellowCardTo))[0]?.staffName }</td>
                                        <td>{yc.yellowCardAt}</td>
                                        </tr>
                                ))}
                                
                            
                            </tbody>

                    </table>

                    contRed = <table className='modal-table'>
                            <thead>
                                <tr><td colSpan={3} className='modal-title'>{highlightActive.away}'s Red Card Detail</td></tr>
                                <tr>
                                    <th></th>
                                    <th>Red Card To</th>
                                    <th>at(minutes)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {red.map((rc, index) =>(
                                        <tr  key={index}>
                                        <th><span className='icon-red-card'><i className='bx bx-book'></i></span></th>
                                        <td>{ findPlayer(arg, parseInt(rc.redCardTo))[0]?.staffName }</td>
                                        <td>{rc.redCardAt}</td>
                                        </tr>
                                ))}
                                

                            </tbody>

                    </table>

                    cont = <div> {contYellow} {contRed} </div>

                    setModalType('sm');
                    setModalContent(cont) ; 
                    setIsModalOpen(true) ; 
            } else if(highlightActive.awayYellowCards) {
                const yellowCards = highlightActive.awayYellowCards?.split(',');
                const yellowCardsAt = highlightActive.awayYellowCardsAt?.split(',');

                const yellow = yellowCards.map((y, index) =>({
                    yellowCardTo: y,
                    yellowCardAt: yellowCardsAt[index]
                }))
        
                contYellow = <table className='modal-table'>
                            <thead>
                                <tr><td colSpan={3} className='modal-title'>{highlightActive.away}'s Yellow Card Detail</td></tr>
                                <tr>
                                    <th></th>
                                    <th>Yellow Card To</th>
                                    <th>at(minutes)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {yellow.map((yc, index) =>(
                                        <tr  key={index}>
                                        <th><span className='icon-yellow-card'><i className='bx bx-book'></i></span></th>
                                        <td>{findPlayer(arg, parseInt(yc.yellowCardTo))[0]?.staffName }</td>
                                        <td>{yc.yellowCardAt}</td>
                                        </tr>
                                ))}
                                
                            
                            </tbody>

                    </table>

                    cont = contYellow

                    setModalType('sm');
                    setModalContent(cont) ; 
                    setIsModalOpen(true) ; 
            } else if(highlightActive.awayRedCards){
                const redCards = highlightActive.awayRedCardsCards?.split(',');
                const redCardsAt = highlightActive.awayRedCardsAt?.split(',');
                const red = redCards.map((r, index) =>({
                    redCardTo: r,
                    redCardAt: redCardsAt[index]
                }))
        
                contRed = <table className='modal-table'>
                        <thead>
                            <tr><td colSpan={3} className='modal-title'>{highlightActive.away}'s Red Card Detail</td></tr>
                            <tr>
                                <th></th>
                                <th>Red Card To</th>
                                <th>at(minutes)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {red.map((rc, index) =>(
                                    <tr  key={index}>
                                    <th><span className='icon-red-card'><i className='bx bx-book'></i></span></th>
                                    <td>{ findPlayer(arg, parseInt(rc.redCardTo))[0]?.staffName}</td>
                                    <td>{rc.redCardAt}</td>
                                    </tr>
                            ))}
                            

                        </tbody>

                </table>

                cont = contRed;
                setModalType('sm');
                setModalContent(cont) ; 
                setIsModalOpen(true) ; 
            } else{
                setIsModalOpen(true) ; 
            }
        }
            
    }

  
    const penalityContent = (arg) =>{
        var cont = ''
        if(arg === 'home'){
            if(homePenality > 0){
                const penality = highlightActive.homePenality?.split(',');
                const penalitysAt = highlightActive.homePenalityAt?.split(',');
                const penalitysStatus = highlightActive.homePenalityStatus?.split(',');

                if(penality){
                    const penalitys = penality.map((p, index) =>({
                        shootBy: p,
                        penalityAt: penalitysAt[index],
                        status: penalitysStatus[index]
                    }))
            
                    cont = <table className='modal-table'>
                            <thead>
                                <tr><td colSpan={4} className='modal-title'>{highlightActive.home}'s Penality Detail</td></tr>
                                <tr>
                                    <th></th>
                                    <th>shot By</th>
                                    <th>at(minutes)</th>
                                    <th>status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {penalitys.map((pn, index) =>(
                                     <tr  key={index}>
                                        <th><span className={`icon-penality ${pn.status === 'missed'? 'missed': ''}`}><i className='bx bx-football'></i></span></th>
                                        <td>{ findPlayer(arg, parseInt(pn.shootBy))[0]?.staffName }</td>
                                        <td>{pn.penalityAt}</td>
                                        <td>{pn.status === '1'? 'scored' : 'missed'}</td>
                                     </tr>
                                ))}
                               
                           
                            </tbody>
    
                        </table>
                    
                }
                setModalType('sm');
                setModalContent(cont) ; 
                setIsModalOpen(true) ; 
            } else {
                setIsModalOpen(false);
            }
             
        } else{
            if(awayPenality > 0 ){
                const penality = highlightActive.awayPenality?.split(',');
                const penalitysAt = highlightActive.awayPenalityAt?.split(',');
                const penalitysStatus = highlightActive.awayPenalityStatus?.split(',');

                if(penality){
                    const penalitys = penality.map((p, index) =>({
                        shootBy: p,
                        penalityAt: penalitysAt[index],
                        status: penalitysStatus[index]
                    }))
            
                    cont = <table className='modal-table'>
                            <thead>
                                <tr><td colSpan={4} className='modal-title'>{highlightActive.away}'s Penality Detail</td></tr>
                                <tr>
                                    <th></th>
                                    <th>shot By</th>
                                    <th>at(minutes)</th>
                                    <th>status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {penalitys.map((pn, index) =>(
                                     <tr  key={index}>
                                        <th><span className={`icon-penality ${pn.status === 'missed'? 'missed': ''}`}><i className='bx bx-football'></i></span></th>
                                        <td>{findPlayer(arg, parseInt(pn.shootBy))[0]?.staffName }</td>
                                        <td>{pn.penalityAt}</td>
                                        <td>{pn.status === '1'? 'scored' : 'missed'}</td>
                                     </tr>
                                ))}
                               
                           
                            </tbody>
    
                        </table>
                    
                }
                setModalType('sm');
                setModalContent(cont) ; 
                setIsModalOpen(true) ; 
            } else {
                setIsModalOpen(false);
            }
        }

    }

    const substitutionContent = (arg) =>{
        var cont = ''
        if(arg === 'home'){
            if(homeSubstitution > 0){
                const substitutionsIn = highlightActive.homeSubstitutionsIn?.split(',');
                const substitutionsOut = highlightActive.homeSubstitutionsOut?.split(',');
                const subsitutionsAt = highlightActive.homeSubstitutionsAt?.split(',');

                if(substitutionsIn){
                    const substitutions = substitutionsIn.map((sb, index) =>({
                        substitutionIn: sb,
                        substitutionOut: substitutionsOut[index],
                        substitutionAt: subsitutionsAt[index]
                    }))
            
                    cont = <table className='modal-table'>
                            <thead>
                                <tr><td colSpan={4} className='modal-title'>{highlightActive.home}'s Subsitution Detail</td></tr>
                                <tr>
                                    <th></th>
                                    <th>Player In</th>
                                    <th>Player Out</th>
                                    <th>at(minutes)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {substitutions.map((s, index) =>(
                                     <tr  key={index}>
                                        <th><span className='icon-substitution'><i className='bx bx-caret-down'></i><i className='bx bx-caret-up'></i></span></th>
                                        <td className='sub-in'>{findPlayer(arg, parseInt(s.substitutionIn))[0]?.staffName }</td>
                                        <td className='sub-out'>{findPlayer(arg, parseInt(s.substitutionOut))[0]?.staffName }</td>
                                        <td>{s.substitutionAt}</td>
                                     </tr>
                                ))}
                               
                           
                            </tbody>
    
                        </table>
                    
                }
                setModalType('sm');
                setModalContent(cont) ; 
                setIsModalOpen(true) ; 
            } else {
                setIsModalOpen(false);
            }
             
        } else{
            if(awaySubstitution > 0){
                const substitutionsIn = highlightActive.awaySubstitutionsIn?.split(',');
                const substitutionsOut = highlightActive.awaySubstitutionsOut?.split(',');
                const subsitutionsAt = highlightActive.awaySubstitutionsAt?.split(',');

                if(substitutionsIn){
                    const substitutions = substitutionsIn.map((sb, index) =>({
                        substitutionIn: sb,
                        substitutionOut: substitutionsOut[index],
                        substitutionAt: subsitutionsAt[index]
                    }))
            
                    cont = <table className='modal-table'>
                            <thead>
                                <tr><td colSpan={4} className='modal-title'>{highlightActive.away}'s Subsitution Detail</td></tr>
                                <tr>
                                    <th></th>
                                    <th>Player In</th>
                                    <th>Player Out</th>
                                    <th>at(minutes)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {substitutions.map((s, index) =>(
                                     <tr  key={index}>
                                        <th><span className='icon-substitution'><i className='bx bx-caret-down'></i><i className='bx bx-caret-up'></i></span></th>
                                        <td className='sub-in'>{findPlayer(arg, parseInt(s.substitutionIn))[0]?.staffName }</td>
                                        <td className='sub-out'>{findPlayer(arg, parseInt(s.substitutionOut))[0]?.staffName }</td>
                                        <td>{s.substitutionAt}</td>
                                     </tr>
                                ))}
                               
                           
                            </tbody>
    
                        </table>
                    
                }
                setModalType('sm');
                setModalContent(cont) ; 
                setIsModalOpen(true) ; 
            } else {
                setIsModalOpen(false);
            }
        }   
    }

    const injuryContent = (arg) =>{
        var cont = ''
        if(arg === 'home'){
            if(homeInjury > 0){
                const injurys = highlightActive.homeInjury?.split(',');
                const injurysAt = highlightActive.homeInjuryAt?.split(',');

                if(injurys){
                    const Injurys = injurys.map((inj, index) =>({
                        injured: inj,
                        injuryAt: injurysAt[index]
                    }))
            
                    cont = <table className='modal-table'>
                            <thead>
                                <tr><td colSpan={4} className='modal-title'>{highlightActive.home}'s Injuries Detail</td></tr>
                                <tr>
                                    <th></th>
                                    <th>Player Injured</th>
                                    <th>at(minutes)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Injurys.map((jury, index) =>(
                                     <tr  key={index}>
                                        <th><span className='icon-injury'><i className='bx bx-handicap'></i></span></th>
                                        <td>{findPlayer(arg, parseInt(jury.injured))[0]?.staffName }</td>
                                        <td>{jury.injuryAt}</td>
                                     </tr>
                                ))}
                               
                           
                            </tbody>
    
                        </table>
                    
                }
                setModalType('sm');
                setModalContent(cont) ; 
                setIsModalOpen(true) ; 
            } else {
                setIsModalOpen(false);
            }
             
        } else{
            if(awayInjury > 0){
                const injurys = highlightActive.awayInjury?.split(',');
                const injurysAt = highlightActive.awayInjuryAt?.split(',');

                if(injurys){
                    const Injurys = injurys.map((inj, index) =>({
                        injured: inj,
                        injuryAt: injurysAt[index]
                    }))
            
                    cont = <table className='modal-table'>
                            <thead>
                                <tr><td colSpan={4} className='modal-title'>{highlightActive.away}'s Injuries Detail</td></tr>
                                <tr>
                                    <th></th>
                                    <th>Player Injured</th>
                                    <th>at(minutes)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Injurys.map((jury, index) =>(
                                     <tr  key={index}>
                                        <th><span className='icon-injury'><i className='bx bx-male'></i></span></th>
                                        <td>{findPlayer(arg, parseInt(jury.injured))[0]?.staffName }</td>
                                        <td>{jury.injuryAt}</td>
                                     </tr>
                                ))}
                               
                           
                            </tbody>
    
                        </table>
                    
                }
                setModalType('sm');
                setModalContent(cont) ; 
                setIsModalOpen(true) ; 
            } else {
                setIsModalOpen(false);
            }
             
        }   
    }

    /* const findPlayers = (arg, tsNo) =>{
        if(arg === 'home'){
            return highlightActive[0].homePlayers.filter(ply => ply.tshirtNo === parseInt(tsNo));
        } else{
            return highlightActive[0].awayPlayers.filter(ply => ply.tshirtNo === parseInt(tsNo));
        }
    } */

    
    const findPlayer = (arg, id) =>{
        if(arg === 'home'){
         return homePlayers.filter((p) => p.staffId === id);
        } else{
         return awayPlayers.filter((p) => p.staffId === id);
        }
    }

    function findLineUps(plyr){
        const LineUps = [];
        for(let i = 1; i < 6; i++){
            const ln = <div className='vertical-space-both'>
                            <div className="same-line">
                                <div className="player-one">
                                    {
                                        plyr.hasOwnProperty(`${i}1D`) &&
                                        <span className='player-down' >
                                            <div className="players-draggable"  >
                                                <img  src={plyr[`${i}1D`][0].playerPhoto? `${backEndUrl}${plyr[`${i}1D`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}1D`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}1D`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
                                    {
                                        plyr.hasOwnProperty(`${i}1S`) &&
                                        <span className='player-along' >
                                            <div className="players-draggable"  >
                                                <img  src={plyr[`${i}1S`][0].playerPhoto? `${backEndUrl}${plyr[`${i}1S`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}1S`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}1S`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
        
                                    {
                                        plyr.hasOwnProperty(`${i}1U`) &&
                                        <span className='player-up' >
                                            <div className="players-draggable"   >
                                                <img  src={plyr[`${i}1U`][0].playerPhoto? `${backEndUrl}${plyr[`${i}1U`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}1U`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}1U`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
                                </div>
                                <div className="player-two">
                                {
                                        plyr.hasOwnProperty(`${i}2D`) &&
                                        <span className='player-down' >
                                            <div className="players-draggable"   >
                                                <img  src={plyr[`${i}2D`][0].playerPhoto? `${backEndUrl}${plyr[`${i}2D`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}2D`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}2D`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
        
                                    {
                                        plyr.hasOwnProperty(`${i}2S`) &&
                                        <span className='player-along' >
                                            <div className="players-draggable"  >
                                                <img  src={plyr[`${i}2S`][0].playerPhoto? `${backEndUrl}${plyr[`${i}2S`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}2S`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}2S`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                    
                                
                                    {
                                        plyr.hasOwnProperty(`${i}2U`) &&
                                        <span className='player-up'  >
                                            <div className="players-draggable"   >
                                                <img  src={plyr[`${i}2U`][0].playerPhoto? `${backEndUrl}${plyr[`${i}2U`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}2U`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}2U`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
                                </div>
                                <div className="player-three">
                                    {
                                        plyr.hasOwnProperty(`${i}3D`) &&
                                        <span className='player-down' >
                                            <div className="players-draggable"  >
                                                <img  src={plyr[`${i}3D`][0].playerPhoto? `${backEndUrl}${plyr[`${i}3D`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}3D`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}3D`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
        
                                    {
                                        plyr.hasOwnProperty(`${i}3S`) &&
                                        <span className='player-along' >
                                            <div className="players-draggable"  >
                                                <img  src={plyr[`${i}3S`][0].playerPhoto? `${backEndUrl}${plyr[`${i}3S`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}3S`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}3S`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
        
                                    {
                                        plyr.hasOwnProperty(`${i}3U`) &&
                                        <span className='player-up' >
                                            <div className="players-draggable"  >
                                                <img  src={plyr[`${i}3U`][0].playerPhoto? `${backEndUrl}${plyr[`${i}3U`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}3U`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}3U`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
                                </div>
                                <div className="player-four">
                                    {
                                        plyr.hasOwnProperty(`${i}4D`) &&
                                        <span className='player-down' >
                                            <div className="players-draggable" >
                                                <img  src={plyr[`${i}4D`][0].playerPhoto? `${backEndUrl}${plyr[`${i}4D`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}4D`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}4D`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
        
                                    {
                                        plyr.hasOwnProperty(`${i}4S`) &&
                                        <span className='player-along' >
                                            <div className="players-draggable"  >
                                                <img  src={plyr[`${i}4S`][0].playerPhoto? `${backEndUrl}${plyr[`${i}4S`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}4S`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}4S`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
        
                                    {
                                        plyr.hasOwnProperty(`${i}4U`) &&
                                        <span className='player-up' >
                                            <div className="players-draggable" >
                                                <img  src={plyr[`${i}4U`][0].playerPhoto? `${backEndUrl}${plyr[`${i}4U`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}4U`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}4U`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
                                </div>
                                <div className="player-five">
                                {
                                        plyr.hasOwnProperty(`${i}5D`) &&
                                        <span className='player-down' >
                                            <div className="players-draggable"  >
                                                <img  src={plyr[`${i}5D`][0].playerPhoto? `${backEndUrl}${plyr[`${i}5D`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}5D`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}5D`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
        
                                    {
                                        plyr.hasOwnProperty(`${i}5S`) &&
                                        <span className='player-along' >
                                            <div className="players-draggable"  >
                                                <img  src={plyr[`${i}5S`][0].playerPhoto? `${backEndUrl}${plyr[`${i}5S`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}5S`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}5S`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
        
                                    {
                                        plyr.hasOwnProperty(`${i}5U`) &&
                                        <span className='player-up' >
                                            <div className="players-draggable" >
                                                <img  src={plyr[`${i}5U`][0].playerPhoto? `${backEndUrl}${plyr[`${i}5U`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}5U`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}5U`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
        
                            </div>
                                <div className="player-six">
                                {
                                        plyr.hasOwnProperty(`${i}6D`) &&
                                        <span className='player-down' >
                                            <div className="players-draggable" >
                                                <img  src={plyr[`${i}6D`][0].playerPhoto? `${backEndUrl}${plyr[`${i}6D`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}6D`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}6D`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
                                    {
                                        plyr.hasOwnProperty(`${i}6S`) &&
                                        <span className='player-along' >
                                            <div className="players-draggable"  >
                                                <img  src={plyr[`${i}6S`][0].playerPhoto? `${backEndUrl}${plyr[`${i}6S`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}6S`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}6S`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
                                    {
                                        plyr.hasOwnProperty(`${i}6U`) &&
                                        <span className='player-up' >
                                            <div className="players-draggable"  >
                                                <img  src={plyr[`${i}6U`][0].playerPhoto? `${backEndUrl}${plyr[`${i}6U`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}6U`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}6U`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
                            </div>
                                <div className="player-seven">
                                    {
                                        plyr.hasOwnProperty(`${i}7D`) &&
                                        <span className='player-down' >
                                            <div className="players-draggable" >
                                                <img  src={plyr[`${i}7D`][0].playerPhoto? `${backEndUrl}${plyr[`${i}7D`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}7D`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}7D`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
        
                                    {
                                        plyr.hasOwnProperty(`${i}7S`) &&
                                        <span className='player-along' >
                                            <div className="players-draggable"  >
                                                <img  src={plyr[`${i}7S`][0].playerPhoto? `${backEndUrl}${plyr[`${i}7S`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}7S`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}7S`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                
                                    {
                                        plyr.hasOwnProperty(`${i}7U`) &&
                                        <span className='player-up' >
                                            <div className="players-draggable"  >
                                                <img  src={plyr[`${i}7U`][0].playerPhoto? `${backEndUrl}${plyr[`${i}7U`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                <p className="player-thirtNo resized-font">{plyr[`${i}7U`][0].shirtNbr}</p>
                                                <p className="Player-name resized-font">{plyr[`${i}7U`][0].staffName}</p>
                                            </div>
                                        </span>
                                    }
                                </div>      
                        </div>

                        </div>
                     
                    LineUps.push(ln);
        }
    
        return LineUps;
    }

    function findSubstitution(plyr){

        var subst = <div>
          {
              plyr.map(p =>(
                  <li className="col-1-2" key={p[0].staffId} >
                      <div className="players-draggable resized-div" >
                          <img src={p[0].playerPhoto? `${backEndUrl}${p[0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image-sub" />
                          <p className="player-thirtNo resized-font-sub">{p[0].shirtNbr}</p>
                          <p className="Player-name resized-font-sub">{p[0].staffName}</p>
                      </div>
                  </li>
              ))
                                    
          }
        
      </div>
      
     return subst 
  }

    const finalFormationContent = (arg) =>{
        var cont = ''
        if(arg === 'home'){
            if(homeFormationFinal){
                const plyr = { ...homeFormationFinal } 
                const subst = []
            
                for(const key in plyr){
                    plyr[key] = findPlayer('home',parseInt(homeFormationFinal[key]))
                }
        
                for(const sb in homeSubstitutionAll){
                    subst.push(findPlayer('home',parseInt(sb)))   
                }
                cont =  <div className='formation-outer'>
                            <div className="formation-analysis">
                                <ul className="row">
                                    <li className="col-1-5"> 
                                            <div className="manager" >
                                                    <p>Manager</p>
                                                    <img  src={homeManager[0]?.playerPhoto? `${backEndUrl}${homeManager[0].playerPhoto}` : person} alt="person" height='30%' width='120%' />
                                                    <p>{homeManager[0]?.staffName}</p>
                                            </div>             
                                    </li>
                                    <li className="col-2-5" >
                                        <div className="linueup-analysis">
                                                <div className='best-11-analysis'>
                                                    <div className='penality-aria-analysis'>
                                                        <div className='goal-post-analysis'>
                                                            <p></p>
                                                        </div>
                                                        <p className='penality-dot-analysis'></p>
                                                        {
                                                            plyr.hasOwnProperty('000') &&
                                                            <span className='goal-keepper-analysis' >
                                                                <div className="players-draggable"  >
                                                                    <img  src={plyr['000'][0]?.playerPhoto? `${backEndUrl}${plyr['000'][0].playerPhoto}` :person} alt="person" height='60px' width='60px' className="resized-image" />
                                                                    <p className="player-thirtNo resized-font">{plyr['000'][0]?.shirtNbr}</p>
                                                                    <p className="Player-name resized-font">{plyr['000'][0]?.staffName}</p>
                                                                </div>
                                                            </span>
                                                        }
                                                    </div>
                                                    <div className='penality-header-analysis'>
                                                    </div>
                                                    <div className='half-space-analysis'>
                                                        <p className='ball-circle-analysis'></p>
                                                    </div>
                                                    <div className='best-10-analysis'>
                                                        {findLineUps(plyr)}
                                                    </div>
                                                    <div className='penality-header-up-analysis'>
                                                    </div> 
                                                    <div className='penality-aria-up-analysis'>
                                                        <p className='penality-dot-up-analysis'></p>
                                                        <p className='goal-keepper-analysis'></p>
                                                        <div className='goal-post-up-analysis'>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='substitues-analysis'>
                                                    <p className='sub-title-analysis'>Substitutions</p>
                                                    <ul className="substitues-body row" >
                                                        { findSubstitution(subst)}
                                                    </ul>
                                                </div> 
                                                   
                                            </div>   
                                            
                                    </li>

                                </ul>

                            </div>
                            <div className="formation-ok">
                                <button type="submit" name="HomeFormationDetail" onClick={closeModal}>Ok</button>
                            </div> 
                        </div>

                setModalType('lg');
                setModalContent(cont) ; 
                setIsModalOpen(true) ; 
            } else {
                setIsModalOpen(false);
            }

        } else if(arg === 'away'){
            if(awayFormationFinal){

                const plyr = { ...awayFormationFinal } 
                const subst = []
            
                for(const key in plyr){
                    plyr[key] = findPlayer('home',parseInt(awayFormationFinal[key]))
                }
        
                for(const sb in awaySubstitutionAll){
                    subst.push(findPlayer('home',parseInt(sb)))   
                }
                cont =  <div>
                            <div className="formation-analysis">
                                <ul className="row">
                                    <li className="col-1-5">
                                            <ul className="row">
                                                <div className="players-analysis">
                                                        <li className="col-1-2"  >
                                                            <div className="players-draggable" >
                                                                    <img  src={awayManager?.playerPhoto? `${backEndUrl}${awayManager.playerPhoto}` : person} alt="person" height='70%' width='100%' />
                                                                    <p className="Player-name">{awayManager?.staffName}</p>
                                                            </div>
                                                        </li>
                                                    
                                                </div>
                                            </ul>
                                    </li>
                                    <li className="col-2-5" >
                                        <div className="linueup-analysis">
                                                <div className='best-11-analysis'>
                                                    <div className='penality-aria-analysis'>
                                                        <div className='goal-post-analysis'>
                                                            <p></p>
                                                        </div>
                                                        <p className='penality-dot-analysis'></p>
                                                        {
                                                            plyr.hasOwnProperty('000') &&
                                                            <span className='goal-keepper-analysis drop' >
                                                                <div className="players-draggable"  >
                                                                    <img  src={plyr['000'][0]?.playerPhoto? `${backEndUrl}${plyr['000'][0].playerPhoto}` :person} alt="person" height='60px' width='60px' className="resized-image" />
                                                                    <p className="player-thirtNo resized-font">{plyr['000'][0]?.shirtNbr}</p>
                                                                    <p className="Player-name resized-font">{plyr['000'][0]?.staffName}</p>
                                                                </div>
                                                            </span>
                                                        }
                                                    </div>
                                                    <div className='penality-header-analysis'>
                                                    </div>
                                                    <div className='half-space-analysis'>
                                                        <p className='ball-circle-analysis'></p>
                                                    </div>
                                                    <div className='best-10-analysis'>
                                                        {findLineUps(plyr)}
                                                    </div>
                                                    <div className='penality-header-up-analysis'>
                                                    </div> 
                                                    <div className='penality-aria-up-analysis'>
                                                        <p className='penality-dot-up-analysis'></p>
                                                        <p className='goal-keepper-analysis'></p>
                                                        <div className='goal-post-up-analysis'>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='substitues-analysis'>
                                                    <p className='sub-title-analysis'>Substitutions</p>
                                                    <ul className="substitues-body row" >
                                                        { findSubstitution(subst)}
                                                    </ul>
                                                </div>     
                                            </div>   
                                            
                                    </li>

                                </ul>

                            </div>

                            <div className="FormationDetail">
                                <button type="submit" name="HomeFormationDetail" >Save</button>
                            </div>
                </div>

                setModalType('lg');
                setModalContent(cont) ; 
                setIsModalOpen(true) ; 
            } else {
                setIsModalOpen(false);
            }
                
        } 
    }
    
    const openModal = (type, arg) =>{
       
        if (type === 'goal'){
            goalContent(arg);
        } else if (type === 'yellowRC'){
            yellowRedCardContent(arg);
        } else if (type === 'penality'){
            penalityContent(arg);
        } else if (type === 'substitution'){
            substitutionContent(arg);
        }else if (type === 'injury'){
            injuryContent(arg);
        } else if (type === 'formation'){
           // formationContent(arg);
           finalFormationContent(arg)
        } else if(type === 'analysis'){
            analysisContent(arg)
        }
        
    }
     
    const closeModal = () =>{
        setIsModalOpen(false)
    }
    const closeAnaModal = () =>{
        setIsModalOpen(false)
        setShowMatchAnalysis(false)
    }
    
    
    const findHighlighIndexById = (hg, id) =>{
        return hg?.findIndex(h => h.matchId === id)
    }

    const [showMatchfact, setShowMatchFact] = useState(false);
    const [showMatchAnalysis, setShowMatchAnalysis] = useState(false); 
    const [showMatchfactBoth, setShowMatchFactBoth] = useState(false);

    const handleMatchFact = ( ) =>{
        if(showMatchfact){
            setShowMatchFact(false)
        } else{
            setShowMatchFact(true);
        }
    }
    const handleMatchFactBoth = ( ) =>{
        if(showMatchfactBoth){
            setShowMatchFactBoth(false)
        } else{
            setShowMatchFactBoth(true);
        }
    }
    const analysisContent = () =>{
        if(showMatchAnalysis){
            setShowMatchAnalysis(false)
            setIsModalOpen(false)
        } else{
            setShowMatchAnalysis(true);
            const cont = <div dangerouslySetInnerHTML={{__html:highlightActive.analysis}}></div>
            setModalType('bg');
            setModalContent(cont) ; 
            setIsModalOpen(true) ;
        }
    }

  
  
   
   
 

  
  

    const handleActiveHighlight = async (id) =>{
      
        const hgltActive = matchs.filter(h => h.matchId === id);
        setHighlightActive(hgltActive[0]);

       // const hghFilter = matchs.filter(highlight => highlight.leagueName?.toLowerCase().includes(hgltActive.leagueName?.toLowerCase()))
        const hghFilter = matchs.filter(highlight => highlight.leagueName?.toLowerCase().includes(hgltActive[0].leagueName?.toLowerCase()))

        setHomeFormationFinal(hgltActive[0].homeLineupDict)
        setAwayFormationFinal(hgltActive[0].awayLineupDict)
        setHomeSubsitutionAll(hgltActive[0].homeSubstitutionAll)
        setAwaySubstitutionAll(hgltActive[0].awaySubstitutionAll)
        setHighlightFiltered(hghFilter)
        

       
       
        var cri = {
            clubId: hgltActive[0].homeId,
            status: 'Active',
            position: 'Player'
        }

        var players = await getclubsDetail(cri);
        setHomePlayers(players);
        
        console.log('all matchs in handle activehighlight middle:', matchs)

        var criAwy = {
            clubId: hgltActive[0].awayId,
            status: 'Active',
            position: 'Player'
        }
        var playersAw = await getclubsDetail(criAwy);
        setAwayPlayers(playersAw);

        var criM = {
            clubId: hgltActive[0].homeId,
            status: 'Active',
            position: 'Manager'
        }
        var mngr = await getclubsDetail(criM);
        setHomeManager(mngr);

        var criMAwy = {
            clubId: hgltActive[0].awayId,
            status: 'Active',
            position: 'Manager'
        }
        var mngrAw = await getclubsDetail(criMAwy);
        setAwayManager(mngrAw);

        

        updateConstants()

       
    }

 
   
    const firstHighlightIndex = findHighlighIndexById(highlightFiltered, highlightActive?.matchId);


    if(firstHighlightIndex && firstHighlightIndex !== -1){
       const firstHighlight = highlightFiltered[firstHighlightIndex];
       const highlightsUpdated = [firstHighlight, ...highlightFiltered.slice(0, firstHighlightIndex), ...highlightFiltered.slice(firstHighlightIndex + 1)]
       highlightFiltered = [...highlightsUpdated]
    }


    const [activeTab, setActiveTab] = useState('Media1');
    const [score, setScore] = useState('show score');
    
    function showScore(){
        if(score === 'show score')
            setScore(`${highlightActive?.homeGoalNo} : ${highlightActive?.awayGoalNo}`);
        else
            setScore('show score')
    }

    const handleActiveTabs = (tab) => {
            setActiveTab(tab);
    }

    const updateConstants = () =>{
       
        if(highlightActive.homeYellowCards && highlightActive.homeRedCards)
        {
            homeYellowRedCards = highlightActive.homeYellowCards.split(',').length +  highlightActive.homeRedCards.split(',').length;
        } else if (highlightActive.homeYellowCards){
            homeYellowRedCards = highlightActive.homeYellowCards.split(',').length
        } else if (highlightActive.homeRedCards){
            homeYellowRedCards = highlightActive.homeRedCards.split(',').length
        }
    
        if(highlightActive.awayYellowCards && highlightActive.awayRedCards)
        {
            awayYellowRedCards = highlightActive.awayYellowCards.split(',').length +  highlightActive.awayRedCards.split(',').length;
        } else if (highlightActive.awayYellowCards){
            awayYellowRedCards = highlightActive.awayYellowCards.split(',').length
        } else if (highlightActive.awayRedCards){
            awayYellowRedCards = highlightActive.awayRedCards.split(',').length
        }
    
        
        if(highlightActive.homePenality){
            homePenality = highlightActive.homePenality.split(',').length;
        }
        if(highlightActive.awayPenality){
            awayPenality = highlightActive.awayPenality.split(',').length;
        }
    
       
        if(highlightActive.homeSubstitutionsIn){
            homeSubstitution = highlightActive.homeSubstitutionsIn.split(',').length;
        }
        if(highlightActive.awaySubstitutionsIn){
            awaySubstitution = highlightActive.awaySubstitutionsIn.split(',').length;
        }
    
       
        if(highlightActive.homeInjury){
            homeInjury = highlightActive.homeInjury.split(',').length;
        }
        if(highlightActive.awayInjury){
            awayInjury = highlightActive.awayInjury.split(',').length;
        }
    
       
    }
   

    console.log('all matchs:', matchs)

  
   
    useEffect(()=>{

        if(allMatchs && allMatchs !== prevMatchsRef.current){
            setMatchs(allMatchs)
            prevMatchsRef.current = allMatchs
        }
        if(activeH !== undefined){
            setHighlightActive(activeH)
        }
        
        const getLatestMatch = async () =>{
            try{
                
              
                const hghFilter = matchs?.filter(highlight => highlight.leagueName?.toLowerCase().includes(highlightActive.leagueName?.toLowerCase()))

                setHomeFormationFinal(highlightActive?.homeLineupDict)
                setAwayFormationFinal(highlightActive?.awayLineupDict)
                setHomeSubsitutionAll(highlightActive?.homeSubstitutionAll)
                setAwaySubstitutionAll(highlightActive?.awaySubstitutionAll)
                setHighlightFiltered(hghFilter)

                var cri = {
                    clubId: highlightActive?.homeId,
                    status: 'Active',
                    position: 'Player'
                }

                var players = await getclubsDetail(cri);
                setHomePlayers(players);
                
    
                var criAwy = {
                    clubId: highlightActive?.awayId,
                    status: 'Active',
                    position: 'Player'
                }
                var playersAw = await getclubsDetail(criAwy);
                setAwayPlayers(playersAw);

                var criM = {
                    clubId: highlightActive?.homeId,
                    status: 'Active',
                    position: 'Manager'
                }
                var mngr = await getclubsDetail(criM);
                setHomeManager(mngr);

                var criMAwy = {
                    clubId: highlightActive?.awayId,
                    status: 'Active',
                    position: 'Manager'
                }
                var mngrAw = await getclubsDetail(criMAwy);
                setAwayManager(mngrAw);

                if(highlightActive?.homeYellowCards && highlightActive?.homeRedCards)
                    {
                        homeYellowRedCards = highlightActive?.homeYellowCards.split(',').length +  highlightActive?.homeRedCards.split(',').length;
                    } else if (highlightActive?.homeYellowCards){
                        homeYellowRedCards = highlightActive?.homeYellowCards.split(',').length
                    } else if (highlightActive?.homeRedCards){
                        homeYellowRedCards = highlightActive?.homeRedCards.split(',').length
                    }
                
                    if(highlightActive?.awayYellowCards && highlightActive?.awayRedCards)
                    {
                        awayYellowRedCards = highlightActive?.awayYellowCards.split(',').length +  highlightActive?.awayRedCards.split(',').length;
                    } else if (highlightActive?.awayYellowCards){
                        awayYellowRedCards = highlightActive?.awayYellowCards.split(',').length
                    } else if (highlightActive?.awayRedCards){
                        awayYellowRedCards = highlightActive?.awayRedCards.split(',').length
                    }
                
                    
                    if(highlightActive?.homePenality){
                        homePenality = highlightActive?.homePenality.split(',').length;
                    }
                    if(highlightActive?.awayPenality){
                        awayPenality = highlightActive?.awayPenality.split(',').length;
                    }
                
                   
                    if(highlightActive?.homeSubstitutionsIn){
                        homeSubstitution = highlightActive?.homeSubstitutionsIn.split(',').length;
                    }
                    if(highlightActive?.awaySubstitutionsIn){
                        awaySubstitution = highlightActive?.awaySubstitutionsIn.split(',').length;
                    }
                
                   
                    if(highlightActive?.homeInjury){
                        homeInjury = highlightActive?.homeInjury.split(',').length;
                    }
                    if(highlightActive?.awayInjury){
                        awayInjury = highlightActive?.awayInjury.split(',').length;
                    }

                
            }catch(err){
                console.log('Error in get matchs', err)
            }
                
        }
        getLatestMatch() 

    },[activeH, matchs, allMatchs, highlightActive])
    
   

    return (
        <div>
            <div className='my-container'>
                <Menu />
                <Search />
                <div className='highlight'>
                    <Modal isOpen={isModalOpen} onClose= {showMatchAnalysis? closeAnaModal : closeModal} content={modalContent} modalType={modalType}/>
                    <ul className='items-horizontal'>
                        <p className='league-title'>{highlightActive?.leagueName}</p>
                        {
                        highlightFiltered?.map((h) =>(
                            <li className='hor-col'>
                                        <div onClick={()=> handleActiveHighlight(h.matchId)} key={h.matchId}>
                                             <div className="my-card-sm2" key={h.matchId}>
                                                <Link to="/highlight" className='my-card-link'>
                                                    <div className="my-card-body-sm">
                                                        <h4 className="home">{h.home}</h4>
                                                        <h4 className="vs">Vs</h4>
                                                        <h4 className="away">{h.away}</h4>
                                                    </div>
                                                </Link>
                                                    <div className="my-card-fotter-sm">
                                                        <h5 className="date">{ moment(h.dop).format('MMMM D, YYYY')}</h5>
                                                        <img src={`${backEndUrl}${h.logo}`} alt={h.leagueName} className='logoImg' />
                                                    </div>
                                                </div>
                                                <div className='vertical-space'>
                                                </div>

                                        </div>
                                    </li>
                                    ))
                                }
                           
                    </ul>
                    <ul className='row'>
                            <li className='col-1-3'>
                                <p className='league-title'>{highlightActive?.leagueName}</p>
                                {
                                    highlightFiltered?.map((h) =>(
                                        <div onClick={()=> handleActiveHighlight(h.matchId)} key={h.matchId}>
                                             <div className="my-card-sm" key={h.matchId}>
                                                <Link to="/highlight" className='my-card-link'>
                                                    <div className="my-card-body-sm">
                                                        <h4 className="home">{h.home}</h4>
                                                        <h4 className="vs">Vs</h4>
                                                        <h4 className="away">{h.away}</h4>
                                                    </div>
                                                </Link>
                                                    <div className="my-card-fotter-sm">
                                                        <h5 className="date">{ moment(h.dop).format('MMMM D, YYYY')}</h5>
                                                        <img src={`${backEndUrl}${h.logo}`} alt={h.leagueName} className='logoImg' />
                                                    </div>
                                                </div>
                                                <div className='vertical-space'>
                                                </div>

                                        </div>
                                       

                                    ))
                                }
                                
                                      
                                       
                            </li>
                        
                        <li className='col-3-4'>
                            <div className='score'>
                                <div className='score-body'>
                                    <h4 className="home">{highlightActive?.home}</h4>
                                    <button className="show-score" name='show-score' onClick={showScore}>{score}</button>
                                    <h4 className="away">{highlightActive?.away}</h4>
                                </div>
                                <div className='score-footer'>
                                    <p>{moment(highlightActive?.dop).format('MMMM D, YYYY h:mm A')}</p>
                                </div>
                                    
                            </div>
                            <hr />
                           
                            <div className='highlight-video'>
                                        <div className='tab-buttons'>
                                            <button className={activeTab === 'Media1' ? 'tab-active': 'tab'} onClick={() => handleActiveTabs('Media1')}>Chanel 1</button>
                                            <button className={activeTab === 'Media2' ? 'tab-active': 'tab'} onClick={() => handleActiveTabs('Media2')}>Chanel 2</button>
                                            <button className={activeTab === 'Media3' ? 'tab-active': 'tab'} onClick={() => handleActiveTabs('Media3')}>Chanel 3</button>
                                         </div>
                                        <div className='tab-body'>
                                                <div className={ activeTab === 'Media1' ? 'tab-content-show': 'tab-content' } id='Media1'>   
                                                   <ReactPlayer 
                                                    url= {highlightActive?.url1}
                                                    controls
                                                    className='react-player' 
                            
                                                    />
                                                </div>
                                                <div className={ activeTab === 'Media2' ? 'tab-content-show': 'tab-content' } id='Media2'>
                                                    <ReactPlayer 
                                                    url={highlightActive?.url2}
                                                    controls
                                                    className='react-player'
                                                   
                                                    />
                                                </div>
                                                <div className={ activeTab === 'Media3' ? 'tab-content-show': 'tab-content' } id='Media3'>
                                                    <iframe
                                                        src={highlightActive?.url3}
                                                        title='highlight'
                                                        allowFullScreen={true}
                                                        className='frame-player'
                                                       
                                                    >  
                                                    </iframe>
                                                   
                                                </div>

                                        </div>

                            </div>

                            

                            <div className='match-fact-analysis'>
                                <h1 className='match-fact-title' onClick={() => handleMatchFactBoth()}><i className={`bx ${showMatchfactBoth ? 'bx-minus' : 'bx-plus'} `}></i>  Match Fact & Analysis</h1>
                                <div  className={`match-fact ${showMatchfactBoth ? 'show' : ''}`}>
                                    <p>The {highlightActive?.leagueName} match between <strong>{highlightActive?.home}</strong> and <strong>{highlightActive?.away}</strong> was played  {moment(highlightActive?.dop).format('MMMM D, YYYY')}. The match ends by {highlightActive?.homeGoalNo} : {highlightActive?.awayGoalNo} score.
                                    The detail Match fact & analysis of the game is shown below. </p>
                                   
                                    <p className='show-match-fact' onClick={() => handleMatchFact()}><i className={`bx ${showMatchfact ? 'bx-minus' : 'bx-plus'} `}></i>  Match Fact</p>
                                    <div className={`match-fact-body ${showMatchfact ? 'show' : ''}`}>
                                        <table className="my-table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>{highlightActive?.home}</th>
                                                    <th>{highlightActive?.away}</th>
                                                </tr>
                                                
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th>Line up</th>
                                                    <td onClick={() => openModal('formation','home')} className={`${homeFormationFinal ? 'modal-open': ''}`}>{highlightActive?.homeLineupShort}</td>
                                                    <td onClick={() => openModal('formation','away')} className={`${awayFormationFinal ? 'modal-open': ''}`}>{highlightActive?.awayLineupShort}</td>
                                                </tr>
                                                <tr>
                                                    <th>Ball Posession</th>
                                                    <td>{highlightActive?.homeBallPosession}%</td>
                                                    <td>{highlightActive?.awayBallPosession}%</td>
                                                </tr>
                                                <tr>
                                                    <th>Total Attempts</th>
                                                    <td>{highlightActive?.homeAttempts}</td>
                                                    <td>{highlightActive?.awayAttempts}</td>
                                                </tr>
                                                <tr>
                                                    <th>On Targets</th>
                                                    <td>{highlightActive?.homeOnTargets}</td>
                                                    <td>{highlightActive?.awayOnTargets}</td>
                                                </tr>
                                                <tr>
                                                    <th>Key Passes</th>
                                                    <td>{highlightActive?.homeKeyPass}</td>
                                                    <td>{highlightActive?.awayKeyPass}</td>
                                                </tr>
                                                <tr>
                                                    <th>Corners</th>
                                                    <td>{highlightActive?.homeCorners}</td>
                                                    <td>{highlightActive?.awayCorners}</td>
                                                </tr>
                                                <tr>
                                                    <th>Goals</th>
                                                    <td onClick={() => openModal('goal','home')} className={`${highlightActive?.homeGoalNo > 0 ? 'modal-open' : ''}`}>{highlightActive?.homeGoalNo}</td>
                                                    <td onClick={() => openModal('goal','away')} className={`${highlightActive?.awayGoalNo > 0 ? 'modal-open' : ''}`}>{highlightActive?.awayGoalNo}</td>
                                                </tr>
                                               
                                                <tr>
                                                    <th>Yellow / Red Cards</th>
                                                    <td onClick={() => openModal('yellowRC','home')} className={`${homeYellowRedCards > 0 ? 'modal-open' : ''}`}>{homeYellowRedCards }</td>
                                                    <td onClick={() => openModal('yellowRC','away')} className={`${awayYellowRedCards > 0 ? 'modal-open' : ''}`}>{awayYellowRedCards}</td>
                                                </tr>
                                               
                                                <tr>
                                                    <th>Penalites</th>
                                                    <td onClick={() => openModal('penality','home')} className={`${homePenality > 0 ? 'modal-open' : ''}`}>{homePenality}</td>
                                                    <td onClick={() => openModal('penality','away')} className={`${awayPenality > 0 ? 'modal-open' : ''}`}>{awayPenality}</td>
                                                </tr>
                                                <tr>
                                                    <th>Substitutions</th>
                                                    <td onClick={() => openModal('substitution','home')} className={`${homeSubstitution > 0 ? 'modal-open' : ''}`}>{homeSubstitution}</td>
                                                    <td onClick={() => openModal('substitution','away')} className={`${awaySubstitution > 0 ? 'modal-open' : ''}`}>{awaySubstitution}</td>
                                                </tr>
                                                <tr>
                                                    <th>Injuries</th>
                                                    <td onClick={() => openModal('injury','home')} className={`${homeInjury > 0 ? 'modal-open' : ''}`}>{homeInjury}</td>
                                                    <td onClick={() => openModal('injury','away')} className={`${awayInjury > 0 ? 'modal-open' : ''}`}>{awayInjury}</td>
                                                </tr>

                                            </tbody>

                                        </table>


                                    </div>

                                    <p className='show-match-analysis' onClick={() =>  openModal('analysis','both')}><i className={`bx ${showMatchAnalysis ? 'bx-minus' : 'bx-plus'} `}></i>  Match Analysis</p>

        
                                </div>
                                
                            </div>

                            
                            <Comments activeH={highlightActive}/>

                        </li>

                    </ul>

                </div>
            </div>
            
            <Footer />
            <GoUp /> 
        </div>
    )
}
export default Highlight;