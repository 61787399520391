import React, { useEffect, useState } from "react";
import { useSelector, useDispatch} from 'react-redux'
import { clearUser } from "../slices/authSlice";
import '../Components/Body/dashboard.css'
import logofootball from '../Assets/images/myfootball.png'
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import person2 from '../Assets/images/person-2.png'
import getMatchComment from "../services/matchCommentService";
import welcome from "../Assets/images/welcome.png";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, PieChart, Pie, Cell, ResponsiveContainer} from 'recharts'
import getVisitors from "../services/visitorService";


//const backEndUrl = "http://localhost:8000/"
const backEndUrl = "https://footballback.zeaye.com/"


function DashBoard(){
    const userState = useSelector((state) => state.auth)
    const [notification, setNotification] = useState(0)
    const [caretDown, setCaretDown] = useState(false)

    const [smallMob, setSmallMob] = useState(false)
    const [allVisitors, setAllVisitors] = useState([])
    const [uniqueVisitors, setUniqueVisitors] = useState([])
    const [uniqueIps, setUniqueIps] = useState([])
    const [visitorsByCountry, setVisitorsByCountry] = useState([])
    const [visitorsBydate, setVisitorsByDate] = useState([])
    const [visitorsByCountryDisplay, setVisitorsByCountryDisplay] = useState([])
    const [visitorsBydateDisplay, setVisitorsByDateDisplay] = useState([])
    const [visitorsByurl, setVisitorsByUrl] = useState([])
    const [uniqueVisitorsDisplay, setUniqueVisitorsDisplay] = useState([])
    const [legendData, setLegendData] = useState([])


    const location = useLocation()

    const handleSmallMob = () =>{
        setSmallMob(!smallMob)
    }

    const [subMenus, setSubMenus] = useState({
        staff: false,
        football: false,
        match: false,
    })
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const {detail, token } = userState || {}

    const handleLogout = () =>{
        dispatch(clearUser());
        localStorage.removeItem('fb-token')
        localStorage.removeItem('fb-user')
        navigate('/')
    }

    const handleNotification = async () =>{
        try{
            const criteria = {status: 'open'}
            const response = await getMatchComment(criteria);
            navigate('comment', { 
                state: {
                    open_comments: response
                } 
            })
            
        }catch(ex){
            console.log('error in handle notification')
        }
        
    }

    const handleCaretDown = ()=>{
        setCaretDown(!caretDown)
    }

    const getProfilePath = () =>{
        const profilePath = detail?.photo ?  detail.photo.startsWith("/media/") ? `${backEndUrl}${detail.photo}` : `${backEndUrl}/media/${detail.photo}` : person2
        return profilePath
    }

    useEffect(() =>{
        try{
            const notif = async()=>{
                const criteria = {
                    status: 'open'
                }
                const comments = await getMatchComment(criteria)
                setNotification(comments.length)

                const vstrs = await getVisitors();


                const args ={
                    visitorsType: "Unique"
                }

                const uniqueVstrs = await getVisitors(args);
                
                const vstrsByDate = vstrs.reduce((acc, item) =>{
                    const dt = new Date(item.start_time).toLocaleDateString()
                    const existing = acc.find((d) => d.date === dt)
                    if (existing){
                        existing.visits +=1
                    } else{
                        acc.push({date:dt, visits: 1})
                    }
                    return acc;
                },[])

                const vstrsByurl = vstrs.reduce((acc, item) =>{
                    const existing = acc.find((d) => d.url === item.url)
                    if (existing){
                        existing.view_time +=1
                    } else{
                        acc.push({url: item.url, view_time: 1})
                    }
                    return acc;
                },[])

                const vstrsBycountry = vstrs.reduce((acc, item) =>{
                    const existing = acc.find((d) => d.country === item.country)
                    if (existing){
                        existing.count +=1
                    } else{
                        acc.push({country: item.country, count: 1})
                    }
                    return acc;
                },[])

                const colorMapping = {}
                const legendMapping = []

                const unqvstrs = Object.values(
                    uniqueVstrs.reduce((acc, {visit_date, ip_address, visit_count}) =>{
                        acc[visit_date] = acc[visit_date] || {visit_date}
                        acc[visit_date][ip_address] = visit_count

                        //generate unique color for each ip per date
                        const unqKey = `${ip_address}_${visit_date}`;
                        if(!colorMapping[unqKey]){
                            const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`
                            colorMapping[unqKey] =  color
                        }

                        acc[visit_date][`${ip_address}_color`] = colorMapping[unqKey]
                        legendMapping.push({id:unqKey, color:colorMapping[unqKey]})

                        return acc;
                    },[])
                ) 

                // const unqLegendData =  legendMapping.filter(
                //     (item, index, self) =>
                //         index === self.findIndex((i) => i.ip_address === item.ip_address)
                // );

                setLegendData(legendMapping)

                setVisitorsByCountryDisplay(vstrsBycountry.slice(0,10))
                setVisitorsByDateDisplay(vstrsByDate.slice(0, 10))
                setUniqueVisitorsDisplay(uniqueVstrs.slice(0, 10))

                

                const ips = uniqueVstrs.map((item) => item.ip_address)
                setUniqueIps(ips)
                //const keys = uniqueVstrs.length ? Object.keys(uniqueVstrs[0]).filter((k) => k !== 'visit_date' && !k.endsWith('_color')) :[]
                //setUniqueIps(keys)

                setVisitorsByDate(vstrsByDate)
                setVisitorsByUrl(vstrsByurl)
                setVisitorsByCountry(vstrsBycountry)
                setUniqueVisitors(unqvstrs)

                setAllVisitors(vstrs)
            };
            notif()
           } catch(err){
                console.log('error in getting notification page:', err)
           }    
    },[])

    const toggleSubMenu = (menu) =>{
        setSubMenus((prev)=>({
            ...prev,
            [menu]: !prev[menu]
            
        }))
    }

    //console.log('all visitors:', allVisitors)
    //console.log('visitors by country:', visitorsByCountry)
    //console.log('visitors by date:', visitorsBydate)
    //console.log('unique visitors:', uniqueVisitors)

    const moreVisitorsByCountry = ()=>{
        setVisitorsByCountryDisplay(visitorsByCountry)
    }

    const moreVisitorsByDate = ()=>{
        setVisitorsByDateDisplay(visitorsBydate)
    }
    const moreVisitorsByVisit = ()=>{
        setUniqueVisitorsDisplay(uniqueVisitors)
    }

    const selectVisitors = (col, val) =>{
        navigate('visitors', { 
            state: {
                filterBy: col,
                filterVal: val
            } 
        })
    }

    
    return(
        <div className="landing-container">
            <div className="landing-header">
                <div className="landing-logo">
                    <Link to="/" className='link'><img src={logofootball} alt='myFootball' className='logo'/></Link>
                    <div className="hamburger3" onClick={handleSmallMob}>
                            <div className="line"></div>
                            <div className="line"></div>
                            <div className="line"></div>
                    </div>
                        {
                        token && detail?.is_staff && smallMob &&
                            <ul className="small-vertical-menu">
                                    <li className='menu-item'>
                                        <div className="my-nav-title-hdr">
                                            <div className="my-nav-title-bdy">
                                                <i className="bx bxs-dashboard icon-left"></i>
                                                <Link to="/dashboard" className='my-nav-link'>Dashboard</Link>
                                            </div>
                                            
                                        </div> 
                                        
                                    </li>
                                
                                        {
                                            detail?.is_superuser &&
                                            <li className='menu-item' onClick={()=>toggleSubMenu('staff')}>
                                                <div className="my-nav-title-hdr">
                                                    <div className="my-nav-title-bdy">
                                                        <i className="bx bxs-user icon-left"></i>
                                                        <Link to="#" className='my-nav-link' >Staff</Link>
                                                    </div>
                                                    <i className={`bx ${!subMenus.staff ? 'bx-caret-right': 'bx-caret-left'}`} ></i>
                                                </div> 
                                                
                                                <ul className={`sub-menu-item ${subMenus.staff ? 'active': ''}`} >
                                                    <li ><i className="bx bx-cog"></i><Link to="/dashboard/lookup" className='sub-menu-link'>Lookups</Link></li>
                                                    <li ><i className="bx bx-group"></i><Link to="/dashboard/players" className='sub-menu-link'>Players</Link></li>
                                                    <li ><i className="bx bx-user"></i><Link to="/dashboard/users" className='sub-menu-link'>Users</Link></li>
    
                                                </ul>
                                            </li>
                                        
                                        }

                                    <li className='menu-item' onClick={()=>toggleSubMenu('football')}>
                                        <div className="my-nav-title-hdr">
                                            <div className="my-nav-title-bdy">
                                                <i className="bx bx-football icon-left"></i>
                                                <Link to="#" className='my-nav-link' >Football</Link>
                                            </div>
                                            <i className={`bx ${!subMenus.football ? 'bx-caret-right': 'bx-caret-left'}`} ></i>
                                        </div> 
                                        
                                        <ul className={`sub-menu-item ${subMenus.football ? 'active': ''}`} >
                                            <li ><i className="bx bx-heart"></i><Link to="/dashboard/clubs" className='sub-menu-link'>Clubs</Link></li>
                                            <li ><i className="bx bx-group"></i><Link to="/dashboard/leagues" className='sub-menu-link'>Leagues</Link></li>

                                        </ul>
                                    </li>

                                    <li className='menu-item' onClick={()=>toggleSubMenu('match')}>
                                        <div className="my-nav-title-hdr">
                                            <div className="my-nav-title-bdy">
                                                <i className="bx bx-trophy icon-left"></i>
                                                <Link to="#" className='my-nav-link' >Matchs</Link>
                                            </div>
                                            <i className={`bx ${!subMenus.match ? 'bx-caret-right': 'bx-caret-left'}`} ></i>
                                        </div> 
                                        
                                        <ul className={`sub-menu-item ${subMenus.match ? 'active': ''}`} >
                                            <li ><i className="bx bx-calendar"></i><Link to="/dashboard/fixtures" className='sub-menu-link'>Fixture</Link></li>
                                            <li ><i className="bx bx-book"></i><Link to="/dashboard/createHl" className='sub-menu-link'>Highlight</Link></li>
                                            <li ><i className="bx bx-comment"></i><Link to="/dashboard/comment" className='sub-menu-link'>Comment</Link></li>

                                        </ul>
                                    </li>

                                    
                            </ul>
                                
                        }
                    
                </div>
                <div className="landing-horizontal-div">
                    <p className="landing-logo-title"><Link to="/" className="logo-link">Football Highlight & Analysis</Link></p>
                    <div className="landing-profile">
                        <div className="profile-img">
                            <div className="prof-notf">
                                    {notification > 0 && 
                                        <span className="notifcation-nbr" onClick={handleNotification}>{notification}</span>
                                    }
                                    <i className="bx bx-bell"></i>
                            </div>
                            <img src={getProfilePath()} alt="profile-img" />
                            <span className="prof-down" onClick={handleCaretDown}><i className={`bx ${caretDown ? 'bx-caret-up' : 'bx-caret-down'} `}></i></span>
                        
                        </div>
                        <div className="profile-menu">
                            <div className={`profile-setting ${caretDown ? 'active': ''}`}>
                                <Link to="/dashboard/profile" className='my-nav-link' onClick={handleCaretDown}><i className="bx bx-user" ><span className="prof-span">Profile</span></i></Link>
                                <li className='horizontal-divider-withoutmargin'></li>
                                <Link to="/dashboard/passwordChange" className='my-nav-link' onClick={handleCaretDown}><i className="bx bx-key"><span className="prof-span">Change Password</span></i></Link>
                                <li className='horizontal-divider-withoutmargin'></li>
                                <Link to="#"  className='my-nav-link' onClick={handleCaretDown}><i className="bx bx-exit" onClick={handleLogout}><span className="prof-span"></span>Logout</i></Link> 
                                <li className='horizontal-divider-withoutmargin'></li>
                                <Link to="#"  className='my-nav-link' onClick={handleCaretDown}><i className="bx bx-cog"><span className="prof-span"></span>Setting</i></Link> 
                            </div>
                        </div>
                        
                    
                    </div>
                </div>
            </div>
           
            <div className="landing-body">
                <div className="landing-vertical-div">
                    <div className="welcome-div">
                        <img src={welcome} alt="welocome" className="welcome-img" />
                    </div>
                    
                    
                {
                            token && detail?.is_staff &&
                            
                                <ul className="landing-vertical-menu">
                                        <li className='menu-item'>
                                            <div className="my-nav-title-hdr">
                                                <div className="my-nav-title-bdy">
                                                    <i className="bx bxs-dashboard icon-left"></i>
                                                    <Link to="/dashboard" className='my-nav-link'>Dashboard</Link>
                                                </div>
                                                
                                            </div> 
                                            
                                        </li>
                                       
                                            {
                                                detail?.is_superuser &&
                                                <li className='menu-item' onClick={()=>toggleSubMenu('staff')}>
                                                     <div className="my-nav-title-hdr">
                                                        <div className="my-nav-title-bdy">
                                                            <i className="bx bxs-user icon-left"></i>
                                                            <Link to="#" className='my-nav-link' >Staff</Link>
                                                        </div>
                                                        <i className={`bx ${!subMenus.staff ? 'bx-caret-right': 'bx-caret-left'}`} ></i>
                                                    </div> 
                                                    
                                                    <ul className={`sub-menu-item ${subMenus.staff ? 'active': ''}`} >
                                                        <li ><i className="bx bx-cog"></i><Link to="/dashboard/lookup" className='sub-menu-link'>Lookups</Link></li>
                                                        <li ><i className="bx bx-group"></i><Link to="/dashboard/players" className='sub-menu-link'>Players</Link></li>
                                                        <li ><i className="bx bx-user"></i><Link to="/dashboard/users" className='sub-menu-link'>Users</Link></li>
        
                                                    </ul>
                                                </li>
                                               
                                            }

                                        <li className='menu-item' onClick={()=>toggleSubMenu('football')}>
                                            <div className="my-nav-title-hdr">
                                                <div className="my-nav-title-bdy">
                                                    <i className="bx bx-football icon-left"></i>
                                                    <Link to="#" className='my-nav-link' >Football</Link>
                                                </div>
                                                <i className={`bx ${!subMenus.football ? 'bx-caret-right': 'bx-caret-left'}`} ></i>
                                            </div> 
                                            
                                            <ul className={`sub-menu-item ${subMenus.football ? 'active': ''}`} >
                                                <li ><i className="bx bx-heart"></i><Link to="/dashboard/clubs" className='sub-menu-link'>Clubs</Link></li>
                                                <li ><i className="bx bx-group"></i><Link to="/dashboard/leagues" className='sub-menu-link'>Leagues</Link></li>

                                            </ul>
                                        </li>

                                        <li className='menu-item' onClick={()=>toggleSubMenu('match')}>
                                            <div className="my-nav-title-hdr">
                                                <div className="my-nav-title-bdy">
                                                    <i className="bx bx-trophy icon-left"></i>
                                                    <Link to="#" className='my-nav-link' >Matchs</Link>
                                                </div>
                                                <i className={`bx ${!subMenus.match ? 'bx-caret-right': 'bx-caret-left'}`} ></i>
                                            </div> 
                                            
                                            <ul className={`sub-menu-item ${subMenus.match ? 'active': ''}`} >
                                                <li ><i className="bx bx-calendar"></i><Link to="/dashboard/fixtures" className='sub-menu-link'>Fixture</Link></li>
                                                <li ><i className="bx bx-book"></i><Link to="/dashboard/createHl" className='sub-menu-link'>Highlight</Link></li>
                                                <li ><i className="bx bx-comment"></i><Link to="/dashboard/comment" className='sub-menu-link'>Comment</Link></li>

                                            </ul>
                                        </li>

                                        
                                </ul>
                            
                        }
                </div>

                <div className="main-body">
                    {
                        location.pathname === "/dashboard" && 
                           <div>
                                <div className="visitors-summary">
                                    <i className="bx bxs-user-voice"></i><p> Total Visitors: {allVisitors.length}</p>
                                </div>
                                {visitorsByCountryDisplay.length > 0 && 
                                    <div className="horizontal-divider-brown-light">
                                        <p className="divider-title">Visitors By Country</p>
                                    </div>
                                }
                               
                                <div className="visitors-charts">
                                    <ul className="row">
                                        {
                                            visitorsByCountryDisplay?.map((c) =>(
                                                <li className="col-1-4">
                                                    <div className="visitors-cntr" onClick={()=>selectVisitors('country',c.country)}>
                                                        <div className="vistors-nbr">
                                                            <i className="bx bxs-flag-alt"><span>{c.count}</span></i>
                                                        </div>
                                                        <p className="visitors-lbl">{c.country}</p>
                                                    </div>
                                                </li>
                                            ))
                                        }

                                        {visitorsByCountry.length > 8 &&
                                            <li className="col-1-1">
                                                <div className='LoadMoreStatus'>
                                                    <button className='btn-more-status' onClick={moreVisitorsByCountry}>View More</button>
                                                </div>
                                            </li>
                                        }                                   
                                        
                                    </ul>
                                    <ul className="vertical-space-both">
                                    </ul>
                                    {
                                        visitorsBydateDisplay.length > 0 && 
                                        <div className="horizontal-divider-brown-light">
                                            <p className="divider-title">Visitors By date</p>
                                        </div>
                                    }
                                   
                                    <ul className="row">
                                        {
                                            visitorsBydateDisplay?.map((c) =>(
                                                <li className="col-1-4" onClick={()=>selectVisitors('view_time',c.country)}>
                                                    <div className="visitors-cntr">
                                                        <div className="vistors-nbr">
                                                            <i className="bx bxs-time"><span>{c.visits}</span></i>
                                                        </div>
                                                        <p className="visitors-lbl">{c.date}</p>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                        {
                                            visitorsBydate.length > 8 && 
                                            <li className="col-1-1">
                                                <div className='LoadMoreStatus'>
                                                    <button className='btn-more-status' onClick={moreVisitorsByDate}>View More</button>
                                                </div>
                                            </li>
                                        }
                                        
                                        
                                    </ul>
                                    <ul className="vertical-space-both">
                                    </ul>

                                    {
                                        uniqueVisitorsDisplay.length > 0 && 
                                        <div className="horizontal-divider-brown-light">
                                            <p className="divider-title">Visitors by number of visit</p>
                                        </div>
                                    }
                                   
                                    <ul className="row">
                                        {
                                            uniqueVisitorsDisplay?.map((c) =>(
                                                <li className="col-1-4" onClick={()=>selectVisitors('ip_address',c.ip_address)}>
                                                    <div className="visitors-cntr">
                                                        <div className="vistors-nbr">
                                                            <i className="bx bxs-user"><span>{c.visit_count}</span></i>
                                                        </div>
                                                        <p className="visitors-lbl">{c.ip_address}</p>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                        {
                                            uniqueVisitors.length > 8 && 
                                            <li className="col-1-1">
                                                <div className='LoadMoreStatus'>
                                                    <button className='btn-more-status' onClick={moreVisitorsByVisit}>View More</button>
                                                </div>
                                            </li>
                                        }
                                        
                                        
                                    </ul>

                                    <div className="horizontal-divider-brown-light">
                                    </div>
                                    <div className="div-charts">
                                        {
                                            visitorsByurl.length > 0 && 

                                            <ResponsiveContainer width='100%' height={300}>
                                                <BarChart width={500} height={300} data={visitorsByurl}>
                                                <CartesianGrid strokeDasharray="3 3" /> 
                                                <XAxis dataKey="url" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="view_time" fill="#8884d8" />
                                                </BarChart> 
                                            </ResponsiveContainer> 
                                        }
                                        
                                        {
                                            visitorsByCountry.length > 0 && 

                                            <ResponsiveContainer width='100%' height={300}>
                                                <PieChart width={400} height={400}>
                                                <Tooltip />
                                                <Legend />
                                                <Pie 
                                                    data={visitorsByCountry}
                                                    dataKey="count"
                                                    nameKey="country"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    fill="#82ca94"
                                                    label={(entry) => entry.country}
                                                
                                                />
                                                
                                                </PieChart>  
                                            </ResponsiveContainer>
                                        }

                                        
                                    </div>
                                    <div className="vertical-space-both">
                                    </div>

                                    <div className="div-charts">
                                        {visitorsBydate.length > 0 && 
                                        <ResponsiveContainer width='100%' height={300}>
                                            <LineChart  data={visitorsBydate}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="date" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="visits" stroke="#8884d8" />
                                            </LineChart>  
                                        </ResponsiveContainer>
                                    }
                                       
                                       {uniqueVisitors.length > 0 && 
                                            <ResponsiveContainer width='100%' height={300}>
                                                <BarChart width={500} height={300} data={uniqueVisitors}>
                                                <CartesianGrid strokeDasharray="3 3" /> 
                                                <XAxis dataKey="visit_date" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend
                                                    payload={legendData.map((entry) =>({
                                                        id: entry.id,
                                                        value:  entry.id.split('_')[0],
                                                        type: 'square',
                                                        color: entry.color
                                                    }))}
                                                />
                                                {
                                                    uniqueIps.map((key) =>(
                                                        <Bar key={key} dataKey={key} name={key} >
                                                        {uniqueVisitors.map((entry, index) =>(
                                                            <Cell 
                                                            key={`cell-${index}`}
                                                            fill={entry[`${key}_color`] || '#8884d8'}
                                                            />
                                                        ))}
                                                        </Bar>
                                                    ))
                                                }
                                                
                                                </BarChart>
                                            </ResponsiveContainer>  
                                       }

                                        
                                    </div>
                                    
                                </div>
                           </div>
                    }
                    

                    <Outlet />
                </div>
            </div>
            

            
            
        </div>
    )
}
export default DashBoard;

