import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { changePassword} from "../services/usersService";

import { passwordChangeSchema } from "../Validations/passwordChangeValidation";
import { clearUser } from "../slices/authSlice";

function ChangePassword() {
    const userState = useSelector((state) => state.auth)
    const [masterErrors, setMasterErrors] = useState({});
    const [masterSuccess, setMasterSuccess] = useState({});

    
    const {token } = userState || {}

    const navigate = useNavigate();
    const dispatch = useDispatch()


    const [masterData, setMasterData] = useState({
        currentPassword:'',
        newPassword: '',
        confirmPassword: '',
        token:token
     })

    
    //handle master data change
    const handleMasterChange = (e) =>{
        const {name, value} = e.target;
        setMasterData({ ...masterData, [name]: value})
    }
    
    //method to validate the input feilds
  const validateForm = async () =>{
    try{
        await passwordChangeSchema.validate(masterData, {abortEarly: false})
        setMasterErrors({})
        return true
    } catch(err){
        const validationErrors = {};
        err.inner?.forEach(error => {
            validationErrors[error.path] = error.message
        })
        console.log(validationErrors)
        setMasterErrors(validationErrors)
        return false;
    }
   }
 

     //method to handle save button actions
    const submitChangePassword = async (e) =>{
        e.preventDefault();
        const isValid = await validateForm()
        if(isValid){
            try{                
                const res = await changePassword(masterData)
                console.log('response:', res)
                if(res.status === 200){
                    setMasterSuccess({ ...masterSuccess, 'pwrdMsg': 'Password changed successfully. Logi'})
                    setMasterErrors({})
                } else{
                    setMasterErrors({...masterErrors, 'error': res.data.message})
                }
               
               
            } catch(err){
                
                if(err.response){
                    const validationErrors = {};
                    validationErrors['error'] = err.response.data.detail
                    setMasterErrors(validationErrors)
                } else if(err.request){
                    console.error(err.request)
                    setMasterErrors({...masterErrors, 'error': err.request})
                    console.log('no respone from server')
                } else{
                    console.error('error', err.message)
                    setMasterErrors({...masterErrors, 'error': err.message})
                    console.log('unkown error')
                }
            }
        } else {
            console.log('validation error: ', masterErrors)
        }
    }

   
    //method to close the success message
    const closeMsg = () =>{
        setMasterSuccess({'profileMsg': null})
    }
    const handleLogout = () =>{
        dispatch(clearUser());
        localStorage.removeItem('fb-token')
        localStorage.removeItem('fb-user')
        navigate('/login')
    }
    return (
        <div>
            <div className="current-page">
                            <p ><Link to="/dashboard" className="link">Dashboard</Link></p>
                            <p>/</p>
                            <p>Change My Password</p>
            </div>
            <div className="page-content">
                <div className="page-body">
                   {
                    token &&
                    <form onSubmit={submitChangePassword} className="profile-module">
                            <p className="profile-title">Change My Password</p>
                            <ul className='row'>
                                    {masterErrors.error && <p className="form-error-hdr">{masterErrors.error}</p>}

                                    {
                                        masterSuccess['pwrdMsg'] && <div className='success-msg'>
                                                                            <i className='bx bx-x btn-close' onClick={() => closeMsg()}></i>
                                                                            <p className='success-msg2'>Password changed successfully!!!</p>
                                                                            <p className='success-msg2'>please <span className='login-link' onClick={handleLogout}>Log Out</span> to check</p>  
                                                                        </div>
                                    } 

                                    <li className='col-1-1'>
                                        <div className="field-input">
                                            {masterErrors['currentPassword'] && <span className="form-error">{masterErrors['currentPassword']}</span>}
                                            <div className={`add-row ${masterErrors['currentPassword'] ? 'validation': ''}`}>
                                                <label htmlFor="currentPassword">Current Password</label>
                                                <input type="password" className="currentPassword"  name="currentPassword" value={masterData.currentPassword} onChange={handleMasterChange}  />    
                                            </div>
                                        </div>

                                        <div className="field-input">
                                            {masterErrors['newPassword'] && <span className="form-error">{masterErrors['newPassword']}</span>}
                                            <div className={`add-row ${masterErrors['newPassword'] ? 'validation': ''}`}>
                                                <label htmlFor="newPassword">New Password</label>
                                                <input type="password" className="newPassword"  name="newPassword" value={masterData.newPasswrod} onChange={handleMasterChange} />    
                                            </div>
                                        </div>

                                        <div className="field-input">
                                            {masterErrors['confirmPassword'] && <span className="form-error">{masterErrors['confirmPassword']}</span>}
                                            <div className={`add-row ${masterErrors['confirmPassword'] ? 'validation': ''}`}>
                                                <label htmlFor="last_name">Confirm Password</label>
                                                <input type="password" className="confirmPassword"  name="confirmPassword" value={masterData.confirmPassword} onChange={handleMasterChange} />    
                                            </div>
                                        </div>

                                       
                                       
                                    </li>
                                    
                            </ul>
                            <div className="pwrdChange-footer">
                                <button type="submit" onClick={submitChangePassword}>Change Password</button> 
                            </div>
                    </form>
                   }
                    
                </div>
            </div>

        </div>
    )
}
export default ChangePassword;
