
import './App.css';
import About from '../Pages/about';
import Contact from '../Pages/contact';
import Highlight from '../Pages/highlight';
import Home from '../Pages/Home';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import CreateHl from '../Pages/createHl';
import LookupMaster from '../Pages/lookupMaster';
import Players from '../Pages/players';
import Clubs from '../Pages/clubs';
import Leagues from '../Pages/leagues';
import Fixtures from '../Pages/fixtures';
import { useSelector} from 'react-redux'
import Login from '../Pages/login';
import DashBoard from '../Pages/dashBoard';
import Unauthorized from '../Pages/unauthorized';
import CommentsAdmin from '../Pages/commentsAdmin';
import PasswordReset from '../Pages/passwordReset';
import PasswordResetConfirm from '../Pages/passwordResetConfirm';
import EmailVerification from '../Pages/emailVerification';
import OTPConfirmation from '../Pages/otpConfirmation'
import Users from '../Pages/users';
import Profile from '../Pages/profile';
import ChangePassword from '../Pages/changePassword';
import Visitors from '../Pages/visitors';



function App() {
    const userState = useSelector((state) => state.auth)
    const {detail, token } = userState || {}


  return (
    // <AxiosInterceptor>
        <BrowserRouter>
        <Routes>
            <Route path='/dashboard' element={token && detail?.is_staff ? <DashBoard /> : <Unauthorized />}>
              <Route path='createHl'
                  element={token && detail?.is_staff ? <CreateHl /> : <Unauthorized />} 
                />
              <Route path='lookup' 
                element={token && detail?.is_staff ? <LookupMaster /> : <Unauthorized />} 
              />
              <Route path='players'
              element={token && detail?.is_staff ? <Players /> : <Unauthorized />}  
              />
              <Route path='clubs'
                element={token && detail?.is_staff ? <Clubs /> : <Unauthorized />}
              />
              <Route path='leagues' 
                element={token && detail?.is_staff ? <Leagues /> : <Unauthorized />} 
              />
              <Route path='fixtures'
                element={token && detail?.is_staff ? <Fixtures /> : <Unauthorized />} 
              />
               <Route path='users'
                element={token && detail?.is_staff ? <Users /> : <Unauthorized />}
              />
              <Route path='profile'
                element={token && detail?.is_staff ? <Profile /> : <Unauthorized />}
              />
               <Route path='passwordChange'
                element={token && detail?.is_staff ? <ChangePassword /> : <Unauthorized />}
              />
              <Route path='comment'
                element={token && detail?.is_staff ? <CommentsAdmin /> : <Unauthorized />}
              />
              <Route path='visitors'
                element={token && detail?.is_staff ? <Visitors /> : <Unauthorized />}
              />
            </Route>
              <Route path='/' exact element={<Home />} />
              <Route path='/about' element={<About />}/>
              <Route path='/contact' element={<Contact />}/>
              <Route path='/highlight'  element={<Highlight />}/>
              <Route path='/login'
                element={token && detail?.is_staff ? <DashBoard /> : <Login />}
              />
              <Route path='/unauthorized' element={<Unauthorized />} />
              <Route path='/verify-email' element={<EmailVerification />} />
              <Route path='/password-reset-request' element={<PasswordReset />} />
              <Route path='/password-reset-confirm' element={<PasswordResetConfirm />} />
              <Route path='/otp-confirmation' element={<OTPConfirmation />} />
        </Routes>
      </BrowserRouter>
   
   
  );
}

export default App;
