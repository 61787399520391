import React, { useEffect, useRef, useState } from "react";
import { addUser, deleteUser, editUser, getUsers } from "../services/usersService";
import { usersSchema, usersSchemaEdit } from "../Validations/usersValidation";
import CustomLoadingOverlay from "../Components/Body/loadingOverlay";
import ImageRenderer from "../Components/Body/imageRenderer";
import ShowDetail from "../Utils/showDetail";
import { useSelector } from "react-redux";
import { getlookupDetail } from "../services/lookupService";
import imageCompression from 'browser-image-compression'
import ConfirmationPopup from "../Utils/confirmationPopup";
import { Link } from "react-router-dom";
import Actions from "../Components/Header/actions";
import SearchMod from "../Components/Body/searchMod";
import { AgGridReact } from "ag-grid-react";
import AddMod from "../Components/Body/addMod";
import {generate} from 'generate-password-browser'


function Users(){
    const userState = useSelector((state) => state.auth)
    const {detail} = userState || {}
    const updatedBy = detail?.id
    const createdBy = detail?.id

   // state variables
   const [rowData, setRowData] = useState([])
   const [masterErrors, setMasterErrors] = useState({});
   const [showSearch, setShowSearch] = useState(false)
   const [showAdd, setShowAdd] = useState(false)
   const [showEdit, setShowEdit] = useState(false)
   const [selectedRows, setSelectedRows] = useState([])
   const [moduleTitle, setModuleTitle] = useState()
   const [isEdit, setIsEdit] = useState(false)
   const [showPopup, setShowPopup] = useState(false)
   const [loading, setLoading] = useState(false);
   const [statusOptions, setStatusOptions] = useState()
   const [isloading, setIsLoading] = useState(true)
   const [profile, setProfile] = useState(null)
   const [fname, setFName] = useState('No file chosen')

   

   //constant variables
   const gridOptions = {loadingOverlayComponent: CustomLoadingOverlay}
   const gridApiRef = useRef(null)
   const loadingTimoutRef = useRef(null);

   //method to format the date displayed in grid
   const dateFormatter = params =>{
       if(!params.value){
           return '';
       }
       const date = new Date(params.value)
       return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
  }


    

  //method to format the select value
  const selectValueFormatter = (params) =>{
      if (!params.value)
      {
          return ''
      }
      return params.value.label
  }

  
  
  
  //method to format the text and number value displayed in grid
  const textValueFormatter = (params) =>{
      if(params.value === null){
          return ''
      }
      return params.value
  }

  
  
   
   const [columnDefs] = useState([
       {headerName:'Id', field: 'id', hide: true },
       {headerName:'photo', field: 'photo', cellRenderer: ImageRenderer, cellRendererParams:{showDetail: ShowDetail}},
       {headerName:'First Name', field: 'first_name', valueFormatter: textValueFormatter},
       {headerName:'Last Name', field: 'last_name', valueFormatter: textValueFormatter},
       {headerName:'Email', field: 'email', valueFormatter: textValueFormatter},
       {headerName:'Phone Nbr', field: 'phoneNbr', valueFormatter: textValueFormatter},
       {headerName:'Address', field: 'address', valueFormatter: textValueFormatter},
       {headerName:'Staff', field: 'is_staff', valueFormatter: selectValueFormatter},
       {headerName:'Active', field: 'is_active',  valueFormatter:selectValueFormatter},
       {headerName:'Admin', field: 'is_superuser',  valueFormatter:selectValueFormatter},
       {headerName:'OTP Enabled ', field: 'is_otp',  valueFormatter:selectValueFormatter},
       {headerName: 'Date Joined', field:'date_joined', valueFormatter: dateFormatter},
       {headerName: 'Verified At', field:'verifiedAt', valueFormatter: dateFormatter},
       {headerName: 'Created At', field:'createdAt', valueFormatter: dateFormatter},
       {headerName: 'Created By', field:'createdBy', valueFormatter: textValueFormatter},
       {headerName: 'Updated At', field:'updatedAt', valueFormatter: dateFormatter},
       {headerName: 'Updated By', field:'updatedBy', valueFormatter: textValueFormatter},
   ]);
   const [masterData, setMasterData] = useState({
      id:'',
      photo: '',
      first_name: '',
      last_name:'',
      password:'',
      email:'',
      username:'',
      phoneNbr:'',
      address: '',
      is_superuser:'',
      is_staff:'',
      is_active:'',
      is_otp:'',
      date_joined:'',
      verifiedAt:'',
      loginAttempts:'',
      createdAt:''
   })
   
   const [masterDataSearch, setMasterDataSearch] = useState({
        id:'',
        photo: '',
        first_name: '',
        last_name:'',
        email:'',
        username:'',
        phoneNbr:'',
        address: '',
        is_superuser:'',
        is_active:'',
        is_staff:'',
        is_otp:'',
        date_joined:'',
        verifiedAt:'',
        loginAttempts:'',
        createdAt:''
   })

   const rowClassRules = {
       'selected-row': (params) => selectedRows.includes(params.data.id),
       'ag-row-even': (params) => params.node.rowIndex % 2 === 0,
       'ag-row-odd': (params) => params.node.rowIndex % 2 !== 0,
   }
   
   
   //use-effect method to persist the selection state and reapply it
   useEffect(() =>{
       if(gridApiRef.current.api){
           gridApiRef.current.api.forEachNode(node =>{
               node.setSelected(selectedRows.includes(node.data))
           })
       }
   }
   ,[rowData, selectedRows])
   
 

   //methods to remove time out 
   const removeTimeout = () =>{
       if(loadingTimoutRef.current){
           clearTimeout(loadingTimoutRef.current)
       } 
   }

   //method to be executed on ag-grid ready, resize the ag-grid column as per screen width
   const onGridReady = (params) =>{
       gridApiRef.current = params;
      // params.api.sizeColumnsToFit();
       
       params.api.forEachNode(node =>{
           node.setSelected(selectedRows.includes(node.data))
       })
        
   }

   //defaut ag-grid column attributes
   const defaultColDef = {
       resizable: true,
       sortable: true,
      // flex: 1,
       filter: true,
       width:220
      
   }

   
   //method to handle selected rows of ag-grid change
   const onSelectionChanged = (e) =>{
       const selectedRows = e.api.getSelectedRows();
       setSelectedRows(selectedRows)
       setMasterData(selectedRows[0])  
       if(selectedRows[0] && selectedRows[0].photo) {
            setFName(selectedRows[0].photo.split('/').pop())
            setProfile(null)
        } else{
            setFName('No file chosen')
        }           
   }

   
  

  // method to handle refresh 
  const refresh = async (e) =>{
   try{
       setLoading(true);
       loadingTimoutRef.current = setTimeout(() => {
           setLoading(false);    
       }, 60000);

        const response = await getUsers();
        const updatedResponse = response.map(rs =>{
            const is_super = statusOptions?.find(opt => opt.label === rs.is_superuser)
            const is_active = statusOptions?.find(opt => opt.label === rs.is_active)
            const is_staff = statusOptions?.find(opt => opt.label === rs.is_staff)
            const is_otp = statusOptions?.find(opt => opt.label === rs.is_otp)
            return {
                ...rs,
                is_superuser: is_super,
                is_active:is_active,
                is_staff: is_staff,
                is_otp: is_otp 
            }
        })

       setRowData(updatedResponse)
       setLoading(false)
       removeTimeout()

   } catch(err){
       console.log('Error fetching rowData: ', err)
   }
  }

  
  


  //method to excute when search button clicked
  const showSearchDiv = () => {
       if(showSearch){
           setShowSearch(false)
       } else{
           setShowSearch(true)
       }
  }

  

  //method to be excuted when add button click
  const showAddDiv = () => {
       clearAdd()
       setModuleTitle('Create New record')
       setIsEdit(false)
       if(showAdd){
           setShowAdd(false)
       } else{
           setShowAdd(true)
       }
   }

   //methods to be excuted when copy button clicks
   const showCopyDiv = () =>{
       setModuleTitle('Copy & Create record')
       setIsEdit(false)
       if(showAdd){
           setShowAdd(false)
       } else{
           setShowAdd(true)
           setMasterData(selectedRows[0]) 
           if(selectedRows[0] && selectedRows[0].photo) {
                setFName(selectedRows[0].photo.split('/').pop())
                setProfile(null)
            } else{
                setFName('No file chosen')
            }    
       }
       
   }
   //methos to be execute when edit button clicks
   const showEditDiv = () =>{
       setModuleTitle('Edit a record')
       setIsEdit(true)
       if(showEdit){
           setShowEdit(false)
       } else{
           setShowEdit(true)
           setMasterData(selectedRows[0]) 
           if(selectedRows[0] && selectedRows[0].photo) {
                setFName(selectedRows[0].photo.split('/').pop())
                setProfile(null)
            } else{
                setFName('No file chosen')
            }    
       }
       
   }

   //method to be executed when delete button clicks
   const showDeleteDiv = (e) =>{
       setShowPopup(true);
   }
   

   //method to be executed when cancel button clicks
   const handleCancel = () =>{
       setShowPopup(false)
   }


   //method to be executed when cancel button of search module  clicks
  const cancelSearch = () =>{
       setShowSearch(false)
  }

  //method to be excuted when cancel button of add module clicks
  const cancelAdd = () =>{
       setShowAdd(false)
       setShowEdit(false)
  }
  


 

  //handle master search data change
  const handleMasterSeachChange = (e)=>{
      const {name, value} = e.target;
      setMasterDataSearch({ ...masterDataSearch, [name]: value})
  }

  
  //handle master search Select data change
  const handleMasterSeachSelectChange = (selectedOption, actionMeta)=>{
      const {name} = actionMeta;
      setMasterDataSearch({ ...masterDataSearch, [name]: selectedOption})
  }

   //handle master data change
  const handleMasterChange = (e) =>{
       const {name, value} = e.target;
       setMasterData({ ...masterData, [name]: value})
  }
   //handle master data Select change
   const handleMasterSelectChange = (selectedOption, actionMeta) =>{
      const {name } = actionMeta;
      
      setMasterData({ ...masterData, [name]: selectedOption})
      
 }



  // handle seach button actions
  const submitSearch = async () =>{
   try{
       setLoading(true);
       loadingTimoutRef.current = setTimeout(() => {
           setLoading(false);
       }, 60000);

       const response = await getUsers(masterDataSearch);
       if(!response.ok){
          setMasterErrors({...masterErrors, 'error': response.error})
      }

        const updatedResponse = response.map(rs =>{
            const is_super = statusOptions?.find(opt => opt.label === rs.is_superuser)
            const is_active = statusOptions?.find(opt => opt.label === rs.is_active)
            const is_staff = statusOptions?.find(opt => opt.label === rs.is_staff)
            const isOtp = statusOptions?.find(opt => opt.label === rs.is_otp)
            return {
                ...rs,
                is_superuser: is_super,
                is_active:is_active,
                is_staff: is_staff,
                is_otp: isOtp 
            }
        })

      
       setRowData(updatedResponse)
       setShowSearch(false)
       setLoading(false);
       removeTimeout(); 

   } catch(err){
       console.log('Error fetching rowData: ', err)
   }
  }
   
  //method to crear the seach module fields
  const clearSearch = () =>{
      setMasterDataSearch({
        id:'',
        photo: '',
        first_name: '',
        last_name:'',
        email:'',
        phoneNbr:'',
        address: '',
        is_superuser:'',
        is_staff:'',
        is_active:'',
        is_otp:'',
        date_joined:'',
        verifiedAt:'',
        loginAttempts:'',
        createdAt:''
      })
  }

  //method to clear the add module fields
  const clearAdd = () =>{
   setMasterData({
    id:'',
    photo: '',
    first_name: '',
    last_name:'',
    email:'',
    password:'',
    phoneNbr:'',
    address: '',
    is_superuser:'',
    is_staff:'',
    is_active:'',
    is_otp:'',
    date_joined:'',
    verifiedAt:'',
    loginAttempts:'',
    createdAt:''
   });
  }

 
  //method to validate the input feilds
  const validateForm = async () =>{
   try{
        if(isEdit){
            await usersSchemaEdit.validate(masterData, {abortEarly: false})
        } else{
            await usersSchema.validate(masterData, {abortEarly: false})
        }
       
       setMasterErrors({})
       return true
   } catch(err){
       const validationErrors = {};
       err.inner?.forEach(error => {
           validationErrors[error.path] = error.message
       })
       console.log(validationErrors)
       setMasterErrors(validationErrors)
       return false;
   }
  }

  //method to handle the delete button actions
  const handleDelete = async () =>{
   try{
           for(const rw of selectedRows){
               const id = rw.id;
               const res =await deleteUser(id)
               if(!res.ok){
                  setMasterErrors({...masterErrors, 'error': res.error})
              }
           }     
           setShowPopup(false)
           refresh()
       } catch(err){
           if(err.response){
               const validationErrors = {};
               validationErrors['error'] = err.response.data.error
               setMasterErrors(validationErrors)
               
           } else if(err.request){
               console.error(err.request)
               console.log('no respone from server')
           } else{
               console.error('error', err.message)
               console.log('unkown error')
           }
       }
  }
 
  //method to handle save button actions
  const submitAdd = async (e) =>{
       e.preventDefault();
             
       const isValid = await validateForm()
       if(isValid){
           try{
               if(isEdit){
                  const now = new Date();
                  const updatedAt = now.toISOString();
                  const addData = { ...masterData, updatedBy, updatedAt }
                  
                  const res = await editUser(addData.id, addData, profile)
                  
                   if(!res.ok){
                      setMasterErrors({...masterErrors, 'error': res.error})
                  }
                   setShowAdd(false)
                   setShowEdit(false)
                   refresh()
               } else{
                  const now = new Date();
                  const createdAt = now.toISOString();
                  const updatedAt = now.toISOString();
                  const addData = { ...masterData, createdAt, createdBy, updatedAt, updatedBy}
                  const res = await addUser(addData)
                  if(!res.ok){
                      setMasterErrors({...masterErrors, 'error': res.error})
                  }
                   clearAdd()
                   setShowAdd(false)
                   setShowEdit(false)
                   refresh()
               }
               
           } catch(err){
               if(err.response){
                   const validationErrors = {};
                   validationErrors['error'] = err.response.data.detail
                   setMasterErrors(validationErrors)
                   
               } else if(err.request){
                   console.error(err.request)
                   console.log('no respone from server')
               } else{
                   console.error('error', err.message)
                   console.log('unkown error')
               }
           }
       } else {
          console.log('validation error: ', masterErrors)
       }
  }

  //method to handle export button actions
  const handleExport = () =>{
       const params = {
           fileName: 'fixtures_export.csv',
           columnSeparator: ','
       }
       gridApiRef.current.api.exportDataAsCsv(params)
  }

  const getSelectOptions = async (masterId) => {
      try{
              const response = await getlookupDetail(masterId);
         
              const formatedOptions = response.map(opt => ({
                  value: opt.detailId,
                  label: opt.value
              }))
              return formatedOptions;
      } catch(err){
          console.log('Error fetching rowData: ', err)
      }
  };

  //handle file change
  const handleMasterFilechange =  async (e)=>{
    const image = e.target.files[0]
    const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 300,
        useWebWorker: true
    }
    
    if(image){
        try{
            const compressedFile = await imageCompression(image, options)
            const newImg = new File([compressedFile], image.name, {type: image.type})
            setMasterData({ ...masterData ,photo: newImg})
            setProfile(newImg)
            setFName(image.name)
        } catch(err){
            console.error('Error compressing the image')
        }
        
    } else{
        setFName('No file chosen')
    }
    
   
}

const generateNewPassword = () =>{
    const pass = generate({
        length:10,
        numbers: true,
        symbols: true,
        uppercase: true,
        lowercase: true,
        strict: true
    })
    setMasterData({ ...masterData, password: pass})
}


 
 
  useEffect(()=>{
     try{
      const userOpt = async()=>{
          const statusBol = await getSelectOptions(13);
          setStatusOptions(statusBol);
          setIsLoading(false)
      };
      userOpt()
     } catch(err){
      setIsLoading(true)
     } 
      
  },[])


  // search fields
  const searchFields = [
       {id: 'id', desc:'ID', name: 'id', type:'text', value:masterDataSearch.id, onChange:handleMasterSeachChange},
       {id: 'first_name', desc:'First Name', name: 'first_name', type:'text', value:masterDataSearch.first_name, onChange:handleMasterSeachChange},
       {id: 'last_name', desc:'Last Name', name: 'last_name', type:'text', value:masterDataSearch.last_name, onChange:handleMasterSeachChange},
       {id: 'email', desc:'Email', name: 'email', type:'text', value:masterDataSearch.email, onChange:handleMasterSeachChange},
       {id: 'phoneNbr', desc:'Phone Nbr', name: 'phoneNbr', type:'text', value:masterDataSearch.phoneNbr, onChange:handleMasterSeachChange},
       {id: 'address', desc:'Addresss', name: 'address', type:'text', value:masterDataSearch.address, onChange:handleMasterSeachChange},

       {id: 'is_superuser', desc:'is Admin', name: 'is_superuser', type:'Select', options:statusOptions, placeHolder:'is Admin...', value:masterDataSearch.is_superuser, onChange:handleMasterSeachSelectChange},
       {id: 'is_active', desc:'is active', name: 'is_active', type:'Select', options:statusOptions, placeHolder:'is active...', value:masterDataSearch.is_active, onChange:handleMasterSeachSelectChange},
       {id: 'is_staff', desc:'is staff', name: 'is_staff', type:'Select', options:statusOptions, placeHolder:'is Staff...', value:masterDataSearch.is_staff, onChange:handleMasterSeachSelectChange},
       {id: 'is_otp', desc:'OTP enabled', name: 'is_otp', type:'Select', options:statusOptions, placeHolder:'is OTP...', value:masterDataSearch.is_otp, onChange:handleMasterSeachSelectChange},

       {id: 'verifiedAt', desc:'Verification Date', name: 'verifiedAt', type:'date', value:masterDataSearch.verifiedAt, onChange:handleMasterSeachChange},
       {id: 'date_joined', desc:'Joined Date', name: 'date_joined', type:'date', value:masterDataSearch.date_joined, onChange:handleMasterSeachChange},

   ];


   const addFields = [
        {id: 'first_name', desc:'First Name', name: 'first_name', type:'text', readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.first_name: '', onChange:handleMasterChange},
        {id: 'last_name', desc:'Last Name', name: 'last_name', type:'text', readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.last_name: '', onChange:handleMasterChange},
        {id: 'email', desc:'Email', name: 'email', type:'text', readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.email: '', onChange:handleMasterChange},
        {id: 'password', desc:'Password', name: 'password', type:'password', readOnly:true, isGenerate: true, text:'Generate',onClick:generateNewPassword, value:masterData? masterData.password : '', onChange:handleMasterChange},

        
        {id: 'username', desc:'User Name', name: 'username', type:'text', readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.username: '', onChange:handleMasterChange},
        {id: 'phoneNbr', desc:'Phone Nbr', name: 'phoneNbr', type:'number', readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.phoneNbr: '', onChange:handleMasterChange},
        {id: 'address', desc:'Address', name: 'address', type:'text', readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.address: '', onChange:handleMasterChange},

        {id: 'is_superuser', desc:'is admin', name: 'is_superuser', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:statusOptions, placeHolder:'is admin...', value:masterData? masterData.is_superuser:'', onChange:handleMasterSelectChange},       
        {id: 'is_staff', desc:'is staff', name: 'is_staff', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:statusOptions, placeHolder:'is staff...', value:masterData? masterData.is_staff:'', onChange:handleMasterSelectChange},       
        {id: 'is_active', desc:'is active', name: 'is_active', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:statusOptions, placeHolder:'is active...', value:masterData? masterData.is_active:'', onChange:handleMasterSelectChange},       
        {id: 'is_otp', desc:'OTP enabled', name: 'is_otp', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:statusOptions, placeHolder:'is OTP ...', value:masterData? masterData.is_otp:'', onChange:handleMasterSelectChange},       
        {id: 'photo', desc:'Photo', name: 'photo', type:'file', profile:profile, fname, readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.photo:'', onChange:handleMasterFilechange},

    ];

    const editFields = [
        {id: 'first_name', desc:'First Name', name: 'first_name', type:'text', readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.first_name: '', onChange:handleMasterChange},
        {id: 'last_name', desc:'Last Name', name: 'last_name', type:'text', readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.last_name: '', onChange:handleMasterChange},
        {id: 'email', desc:'Email', name: 'email', type:'text', readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.email: '', onChange:handleMasterChange},
        
        {id: 'username', desc:'User Name', name: 'username', type:'text', readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.username: '', onChange:handleMasterChange},
        {id: 'phoneNbr', desc:'Phone Nbr', name: 'phoneNbr', type:'number', readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.phoneNbr: '', onChange:handleMasterChange},
        {id: 'address', desc:'Address', name: 'address', type:'text', readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.address: '', onChange:handleMasterChange},

        {id: 'is_superuser', desc:'is admin', name: 'is_superuser', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:statusOptions, placeHolder:'is admin...', value:masterData? masterData.is_superuser:'', onChange:handleMasterSelectChange},       
        {id: 'is_staff', desc:'is staff', name: 'is_staff', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:statusOptions, placeHolder:'is staff...', value:masterData? masterData.is_staff:'', onChange:handleMasterSelectChange},       
        {id: 'is_active', desc:'is active', name: 'is_active', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:statusOptions, placeHolder:'is active...', value:masterData? masterData.is_active:'', onChange:handleMasterSelectChange},       
        {id: 'is_otp', desc:'OTP enabled', name: 'is_otp', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:statusOptions, placeHolder:'is OTP ...', value:masterData? masterData.is_otp:'', onChange:handleMasterSelectChange},       
        {id: 'photo', desc:'Photo', name: 'photo', type:'file', profile:profile, fname, readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.photo:'', onChange:handleMasterFilechange},

    ];

   return(
       <div>
           <ConfirmationPopup showPopup={showPopup} onDeleteHandler={handleDelete} onCancelHandler={handleCancel} content="Are you sure you want to delete the selected records?" />  
            <div>
                <div className="current-page">
                            <p ><Link to="/dashboard" className="link">Dashboard</Link></p>
                            <p>/</p>
                            <p>Users</p>
                </div>
                <div className="page-content">
                    <Actions title='Adjust Users here' isLoading={isloading} refresh={refresh} showSearchDiv={showSearchDiv}   showAddDiv={showAddDiv} showCopyDiv={showCopyDiv} showEditDiv={showEditDiv} showDeleteDiv={showDeleteDiv} handleExport={handleExport} rowData={rowData} selectedRows={selectedRows} />
                    
                    <div className="page-body">

                        {
                            showSearch ?
                            <SearchMod searchFields={searchFields} submitSearch={submitSearch} cancelSearch={cancelSearch} clearSearch={clearSearch}/>
                            : ''
                        }
                        
                        <div className="ag-theme-quartz ag-grid-container" >
                            <AgGridReact 
                                gridOptions={gridOptions}
                                columnDefs={columnDefs}
                                rowData={rowData} 
                                rowSelection="multiple" 
                                defaultColDef={defaultColDef}
                                domLayout="autoHeight"
                                onSelectionChanged={onSelectionChanged}
                                pagination={true}
                                paginationPageSize={20}
                                paginationPageSizeSelector={[20, 40]}
                                loadingOverlayComponentFramework={CustomLoadingOverlay}
                                loading={loading}
                                onGridReady={onGridReady}
                                ref={gridApiRef}
                                rowClassRules={rowClassRules}
                                alwaysShowVerticalScroll={true}
                                alwaysShowHorizontalScroll={true}
                            />
                    </div>

                        {
                            showAdd &&
                            <AddMod addFields={addFields}  submitAdd={submitAdd} moduleTitle={moduleTitle} masterErrors={masterErrors} cancelAdd={cancelAdd} clearAdd={clearAdd} />
                        }
                        {
                            showEdit &&
                            <AddMod addFields={editFields}  submitAdd={submitAdd} moduleTitle={moduleTitle} masterErrors={masterErrors} cancelAdd={cancelAdd} clearAdd={clearAdd} />
                        }
                    
                    </div>
                    <div className="page-footer">
                    </div>

                </div>
            </div>
        
              
       </div>
   );
}
export default Users