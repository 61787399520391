
import Actions from '../Components/Header/actions'
import { useEffect, useRef, useState } from "react";
import CustomLoadingOverlay from "../Components/Body/loadingOverlay";
import getVisitors from "../services/visitorService";
import SearchMod from "../Components/Body/searchMod";
import { AgGridReact } from "ag-grid-react";
import { getlookupDetail } from "../services/lookupService";
import { useLocation } from 'react-router-dom';


function Visitors (){
    const [isloading, setIsLoading] = useState(true)
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState([])
    const [showSearch, setShowSearch] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [masterErrors, setMasterErrors] = useState({});
    const [nationalityOptions, setNationalityOptions] = useState()
    //const [filterData, setFilterData] = useState({})
   

    const location = useLocation()
    
    const { filterBy, filterVal } = location.state || {};

    // if (filterBy === 'country'){
    //     setFilterData({
    //         'country': filterVal
    //     })
    // } else if(filterBy === 'view_time'){
    //     setFilterData({
    //         'view_time': filterVal
    //     })
    // }
  
    // console.log(filterBy)
    // console.log(filterVal)

    //constant variables
    const gridOptions = {loadingOverlayComponent: CustomLoadingOverlay}
    const gridApiRef = useRef(null)
    const loadingTimoutRef = useRef(null);

    const rowClassRules = {
        'selected-row': (params) => selectedRows.includes(params.data.id),
        'ag-row-even': (params) => params.node.rowIndex % 2 === 0,
        'ag-row-odd': (params) => params.node.rowIndex % 2 !== 0,
    }

     //method to format the text and number value displayed in grid
     const textValueFormatter = (params) =>{
        if(params.value === null){
            return ''
        }
        return params.value
    }

    //method to format the date displayed in grid
     const dateFormatter = params =>{
         if(!params.value){
             return '';
         }
         const date = new Date(params.value)
         return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    }

    
     //defaut ag-grid column attributes
     const defaultColDef = {
        resizable: true,
        sortable: true,
       // flex: 1,
        filter: true,
        width:220
       
    }

     //method to be executed on ag-grid ready, resize the ag-grid column as per screen width
     const onGridReady = (params) =>{
        gridApiRef.current = params;
       // params.api.sizeColumnsToFit();
        
        params.api.forEachNode(node =>{
            node.setSelected(selectedRows.includes(node.data))
        })
         
    }
    
    //method to handle selected rows of ag-grid change
    const onSelectionChanged = (e) =>{
        const selectedRows = e.api.getSelectedRows();
        setSelectedRows(selectedRows)             
    }
 

    const [columnDefs] = useState([
        {headerName:'Session Key', field: 'session_key', hide: true },
        {headerName:'IP Address', field: 'ip_address', valueFormatter: textValueFormatter},
        {headerName:'User Agent', field: 'user_agent', valueFormatter: textValueFormatter},
        {headerName:'View Time', field: 'view_time', valueFormatter: dateFormatter},
        {headerName:'URL', field: 'url', valueFormatter: textValueFormatter},
        {headerName:'Country', field: 'county', valueFormatter: textValueFormatter},
        {headerName:'Region', field: 'region', valueFormatter: textValueFormatter},
        {headerName:'City', field: 'city', valueFormatter: textValueFormatter},
        {headerName:'Latitude', field: 'latitude', valueFormatter: textValueFormatter},
        {headerName:'Longitude', field: 'longitude', valueFormatter: textValueFormatter},
        {headerName:'Start Time', field: 'start_time', valueFormatter: dateFormatter },
        {headerName:'Time On Site', field: 'stime_on_site', valueFormatter: textValueFormatter},
        {headerName:'End Time', field: 'end_time', valueFormatter:dateFormatter},
        {headerName:'User ID', field: 'user_id', valueFormatter: textValueFormatter},
       
    ]);

     const [masterDataSearch, setMasterDataSearch] = useState({
            url:'',
            ip_address: '',
            start_time: '',
            time_on_site:'',
            user_id:'',
            view_time:'',
            country:'',
            region: '',
            city: ''
    })

    //method to handle export button actions
    const handleExport = () =>{
        const params = {
            fileName: 'visitors_export.csv',
            columnSeparator: ','
        }
        gridApiRef.current.api.exportDataAsCsv(params)
   }

    //method to excute when search button clicked
    const showSearchDiv = () => {
        if(showSearch){
            setShowSearch(false)
        } else{
            setShowSearch(true)
        }
    }


    //methods to remove time out 
    const removeTimeout = () =>{
        if(loadingTimoutRef.current){
            clearTimeout(loadingTimoutRef.current)
        } 
    }

    //handle master search data change
    const handleMasterSeachChange = (e)=>{
        const {name, value} = e.target;
        setMasterDataSearch({ ...masterDataSearch, [name]: value})
    }

     //method to be executed when cancel button of search module  clicks
     const cancelSearch = () =>{
        setShowSearch(false)
    }

    //method to crear the seach module fields
    const clearSearch = () =>{
        setMasterDataSearch({
            url:'',
            ip_address: '',
            start_time: '',
            time_on_site:'',
            user_id:'',
            view_time:'',
            country:'',
            region: '',
            city: ''
        })
        
    }
 

     // handle seach button actions
    const submitSearch = async () =>{
        try{
            setLoading(true);
            loadingTimoutRef.current = setTimeout(() => {
                setLoading(false);
            }, 60000);
    
            const response = await getVisitors(masterDataSearch);
            if(!response.ok){
            setMasterErrors({...masterErrors, 'error': response.error})
            }
            const updatedResponse = response.map(rs =>{
                const nation = nationalityOptions?.find(opt => opt.label === rs.country)
                return {
                    ...rs,
                    country: nation,
                }
            })
    
            setRowData(updatedResponse)
            setShowSearch(false)
            setLoading(false);
            removeTimeout(); 
    
        } catch(err){
            console.log('Error fetching rowData: ', err)
        }
    }
         

    // method to handle refresh 
    const refresh = async (e) =>{
        try{
            setLoading(true);
            loadingTimoutRef.current = setTimeout(() => {
                setLoading(false);    
            }, 60000);
    
            const response = await getVisitors();
            const updatedResponse = response.map(rs =>{
                const nation = nationalityOptions?.find(opt => opt.label === rs.country)
                return {
                    ...rs,
                    country: nation,
                }
            })
            setRowData(updatedResponse)
            setLoading(false)
            removeTimeout()
    
        } catch(err){
            console.log('Error fetching rowData: ', err)
        }
    }

    const getSelectOptions = async (masterId) => {
        try{
                const response = await getlookupDetail(masterId);
            
                const formatedOptions = response.map(opt => ({
                    value: opt.detailId,
                    label: opt.value
                }))
                return formatedOptions;
        } catch(err){
            console.log('Error fetching rowData: ', err)
        }
        
        
    };

    //handle master search Select data change
    const handleMasterSeachSelectChange = (selectedOption, actionMeta)=>{
        const {name} = actionMeta;
        setMasterDataSearch({ ...masterDataSearch, [name]: selectedOption})
    }

   

    useEffect(()=>{
        try{
            const footOpt = async()=>{
                const nopt = await getSelectOptions(4);
                setNationalityOptions(nopt);

                setLoading(true);
                loadingTimoutRef.current = setTimeout(() => {
                    setLoading(false);    
                }, 60000);
                
                let filterData = {}
                if(filterBy === 'country'){
                    filterData = {
                        'country': filterVal
                    }
                } else if(filterBy === 'view_time'){
                    filterData = {
                        'view_time': filterVal
                    }
                }
                const response = await getVisitors(filterData)
                const updatedResponse = response.map(rs =>{
                    const nation = nopt?.find(opt => opt.label === rs.country)
                    return {
                        ...rs,
                        country: nation,
                    }
                })
                setRowData(updatedResponse)
                setNationalityOptions(nopt)   
                setLoading(false)
                removeTimeout()
             
            };

            footOpt()
        } catch(err){
            setIsLoading(true)
        } 
        
    },[filterBy, filterVal])


     // search fields
     const searchFields = [
        {id: 'url', desc:'url', name: 'url', type:'text', value:masterDataSearch.url, onChange:handleMasterSeachChange},
        {id: 'ip_address', desc:'Ip address', name: 'ip_address', type:'text', value:masterDataSearch.ip_address, onChange:handleMasterSeachChange},
        {id: 'time_on_site', desc:'Time spent', name: 'time_on_site', type:'number', value:masterDataSearch.time_on_site, onChange:handleMasterSeachChange},
        {id: 'start_time', desc:'Date', name: 'start_time', type:'date', value:masterDataSearch.start_time, onChange:handleMasterSeachChange},
        {id: 'view_time', desc:'Date', name: 'view_time', type:'date', value:masterDataSearch.view_time, onChange:handleMasterSeachChange},
        {id: 'country', desc:'Country', name: 'country', type:'Select', options:nationalityOptions, placeHolder:'Select country...', value:masterDataSearch.country, onChange:handleMasterSeachSelectChange},
        {id: 'city', desc:'City', name: 'city', type:'text', value:masterDataSearch.city, onChange:handleMasterSeachChange},

    ];
     


    return(
        <div>
              <div>
               
                <div className="page-content">
                    <Actions title='Visitors detail' isLoading={isloading} refresh={refresh} showSearchDiv={showSearchDiv}  handleExport={handleExport} rowData={rowData} selectedRows={selectedRows} />
                    
                    <div className="page-body">

                        {
                            showSearch ?
                            <SearchMod searchFields={searchFields} submitSearch={submitSearch} cancelSearch={cancelSearch} clearSearch={clearSearch}/>
                            : ''
                        }
                        
                        <div className="ag-theme-quartz ag-grid-container" >
                            <AgGridReact 
                                gridOptions={gridOptions}
                                columnDefs={columnDefs}
                                rowData={rowData} 
                                rowSelection="multiple" 
                                defaultColDef={defaultColDef}
                                domLayout="autoHeight"
                                onSelectionChanged={onSelectionChanged}
                                pagination={true}
                                paginationPageSize={20}
                                paginationPageSizeSelector={[20, 40]}
                                loadingOverlayComponentFramework={CustomLoadingOverlay}
                                loading={loading}
                                onGridReady={onGridReady}
                                ref={gridApiRef}
                                rowClassRules={rowClassRules}
                                alwaysShowVerticalScroll={true}
                                alwaysShowHorizontalScroll={true}
                            />
                    </div>
                    </div>
                    <div className="page-footer">
                    </div>

                </div>
            </div>
        </div>
    )
}
export default Visitors;