import React from 'react'
import HighlightMatchFact from '../Components/Body/highlightMatchfact';


function CreateHl(){
    return (
        <div className='my-container'>
            <HighlightMatchFact />
        </div>
    )
}
export default CreateHl;
