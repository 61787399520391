import './analysis.css'
import React, { forwardRef, useEffect, useState } from "react"
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import QuillToolbar, {modules, formats}  from '../../Utils/quillEditorToolbar'
import {v4 as uuidv4} from 'uuid'
import { addMatchAnalysis, editMatchAnalysis } from '../../services/matchAnalysis'
import { useSelector } from 'react-redux'


function Analysis ({matchField, setMatchField, isEdit, setIsEdit, handleshowFilter,showDeleteDiv}){

    const userState = useSelector((state) => state.auth)
    const {detail} = userState || {}
    const updatedBy = detail?.id
    const createdBy = detail?.id

    const handleAddSection = () =>{
        if(sectionAdd){
            setSectionAdd(false)
        } else{
            setSectionAdd(true)
        }

    }

    const hideSections = () =>{
        setSectionAdd(false)
    }

   
    const [newSections, setNewSections] = useState([]);
    const [sectionAdd, setSectionAdd] = useState(false);
    const [QuillValue, setQuillValue] = useState({});
    const [masterErrors, setMasterErrors] = useState({});
    const [masterSuccess, setMasterSuccess] = useState({});
    const [quillEdit, setQuillEdit] = useState(false);
    const [editorContents, setEditorContents] = useState([]);


    const QuillEditor = forwardRef((props, ref) => (
        <ReactQuill ref={ref} {...props} />
        ));
        
    const handleQuillValue = (id, val, className) =>{
        setQuillValue((prev) =>({
            ...prev,
            [id]: {id, val, className},
        }))
  
    }
   
    const validateFormAnalysis = async (data) =>{
        if(!data || data?.analysis === ''){
            const validationErrors = {analysis: "*Analysis required"} 
            setMasterErrors(validationErrors)
            return false;
        } else if(data?.analysis?.length < 100){
            const validationErrors ={analysis: "Analysis should contain at least 50 characters"} 
            setMasterErrors(validationErrors)
            return false;
        } else{
            setMasterErrors({})
            return true
        }
   }
   
    const handleAnalysisSubmit = async (data)=>{
        const isValid = await validateFormAnalysis(data)
        if(isValid){
            try{
                if(isEdit && data.analysisId){
                    const now = new Date();
                    const updatedAt = now.toISOString();
                    const addData = { ...data, updatedAt, updatedBy}
                    const res = await editMatchAnalysis(addData.analysisId, addData)
                    if(!res.ok){
                        setMasterErrors({...masterErrors, 'matchAnalysisError': res.error})
                    }
                    setMasterSuccess({ ...masterSuccess, 'matchAnalysisMsg': 'Match Analysis updated successfully'})
                    setQuillEdit(false)
                    setNewSections([])

                } else{
                    const now = new Date();
                    const createdAt = now.toISOString();
                    const updatedAt = now.toISOString();
                    const addData = { ...data, createdAt, createdBy, updatedAt, updatedBy}
                    const res = await addMatchAnalysis(addData)
                    if(!res.ok){
                        setMasterErrors({...masterErrors, 'matchAnalysisError': res.error})
                    }
                    setMasterSuccess({ ...masterSuccess, 'matchAnalysisMsg': 'Match  Analysis saved successfully '})
                    setIsEdit(false)
                    setNewSections([])

                }
                
            } catch(err){
                if(err.response){
                    const validationErrors = {};
                    validationErrors['matchAnalysisError'] = err.response.data.error
                    setMasterErrors(validationErrors)
                    
                } else if(err.request){
                    console.error(err.request)
                    console.log('no respone from server')
                } else{
                    console.error('error', err.message)
                    console.log('unkown error')
                }
            }
        } else {
        console.log('validation error: ', )
        }
   }
   

   const deleteSection = (id) =>{
        console.log('delete section clicked');
        setNewSections(newSections.filter(section => section.id !== id));
   }

    const addOneColumn = () =>{
        const id = uuidv4();
        const sectionId = 'section-' + id;
        let currentDate = new Date();
        var unqNbr = currentDate.getTime();
        const newHtmlContent = (
            <div className='new-section' id={sectionId}>
                <span className='delete-section' onClick={() => deleteSection(sectionId)}><i className='bx bx-x'></i></span>
                <ul className='row' >
                    <li className='col-1-1'>
                        <QuillToolbar  toolbarId= {sectionId}/>
                        <QuillEditor theme='snow' value={QuillValue[unqNbr]} onChange={(val) => handleQuillValue(unqNbr, val, 'col-1-1')} modules={modules(sectionId)} formats={formats} placeholder='compose your analysis here'/>
                    </li>
                   
                    
                </ul>
            </div>
        );
        
        const newSec = {id: sectionId, content: newHtmlContent}
        setNewSections([...newSections, newSec])
        hideSections();
    }
    const addTwoEqualColumn = () =>{
        const id = uuidv4();
        let currentDate = new Date();
        var unqNbr = currentDate.getTime();
        const sectionId = 'section-' + id;
        const newHtmlContent = (
            <div className='new-section' id={sectionId}>
                
                <span className='delete-section' onClick={() => deleteSection(sectionId)}><i className='bx bx-x'></i></span>
                <ul className='row' >
                    <li className='col-1-2'>
                      <QuillToolbar  toolbarId= {'t'+ unqNbr}/>
                      <QuillEditor theme='snow' modules={modules('t' + unqNbr)} value={QuillValue[unqNbr]}  onChange={(val) => handleQuillValue(unqNbr,val,'col-1-2')} formats={formats} placeholder='compose your analysis here' />
                                        
                    </li>
                    <li className='col-1-2'>
                        <QuillToolbar  toolbarId= {'t' + unqNbr + 1}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr + 1)} value={QuillValue[unqNbr + 1]}  onChange={(val) => handleQuillValue(unqNbr + 1,val,'col-1-2')} formats={formats} placeholder='compose your analysis here' />
                                               
                    </li>
                   
                    
                </ul>
            </div>
        );

        const newSec = {id: sectionId, content: newHtmlContent}
        setNewSections([...newSections, newSec])
        hideSections();
    }

    const addThreeEqualColumn = () =>{
        const id = uuidv4();
        let currentDate = new Date();
        var unqNbr = currentDate.getTime();
        const sectionId = 'section-' + id;
        const newHtmlContent = (
            <div className='new-section' id={sectionId}>
                <span className='delete-section' onClick={() => deleteSection(sectionId)}><i className='bx bx-x'></i></span>
                <ul className='row' >

                    <li className='col-1-3'>
                        <QuillToolbar  toolbarId= {'t'+ unqNbr}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr)} value={QuillValue[unqNbr]} onChange={(val) => handleQuillValue(unqNbr,val,'col-1-3')}  formats={formats} placeholder='compose your analysis here' />
                    </li>
                    <li className='col-1-3'>
                        <QuillToolbar  toolbarId= {'t' + unqNbr + 1}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr + 1)} value={QuillValue[unqNbr + 1]} onChange={(val) => handleQuillValue(unqNbr + 1,val,'col-1-3')}  formats={formats} placeholder='compose your analysis here' />
                    </li>
                    <li className='col-1-3'>
                        <QuillToolbar  toolbarId= {'t' + unqNbr + 2}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr + 2)} value={QuillValue[unqNbr + 2]} onChange={(val) => handleQuillValue(unqNbr + 2,val,'col-1-3')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                   
                    
                </ul>
            </div>
        );

        const newSec = {id: sectionId, content: newHtmlContent}
        setNewSections([...newSections, newSec])
        hideSections(); 
    }

    const addFourEqualColumn = () =>{
        const id = uuidv4();
        let currentDate = new Date();
        var unqNbr = currentDate.getTime();
        const sectionId = 'section-' + id;
        const newHtmlContent = (
            <div className='new-section' id={sectionId}>
                <span className='delete-section' onClick={() => deleteSection(sectionId)}><i className='bx bx-x'></i></span>
                <ul className='row' >

                    <li className='col-1-4'>
                        <QuillToolbar  toolbarId= {'t'+ unqNbr}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr)} value={QuillValue[unqNbr]} onChange={(val) => handleQuillValue(unqNbr,val,'col-1-4')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                    <li className='col-1-4'>
                        <QuillToolbar  toolbarId= {'t' + unqNbr + 1}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr + 1)} value={QuillValue[unqNbr + 1]} onChange={(val) => handleQuillValue(unqNbr + 1,val,'col-1-4')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                    <li className='col-1-4'>
                        <QuillToolbar  toolbarId= {'t' + unqNbr + 2}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr + 2)} value={QuillValue[unqNbr + 2]} onChange={(val) => handleQuillValue(unqNbr + 2,val,'col-1-4')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                    <li className='col-1-4'>
                        <QuillToolbar  toolbarId= {'t' + unqNbr + 3}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr + 3)} value={QuillValue[unqNbr + 3]} onChange={(val) => handleQuillValue(unqNbr + 3,val,'col-1-4')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                   
                    
                </ul>
            </div>
        );

        const newSec = {id: sectionId, content: newHtmlContent}
        setNewSections([...newSections, newSec])
        hideSections(); 
    }

    const addTwoLeftSmallColumn = () =>{
        const id = uuidv4();
        let currentDate = new Date();
        var unqNbr = currentDate.getTime();
        const sectionId = 'section-' + id;
        const newHtmlContent = (
            <div className='new-section' id={sectionId}>
                <span className='delete-section' onClick={() => deleteSection(sectionId)}><i className='bx bx-x'></i></span>
                <ul className='row' >

                    <li className='col-2-4'>
                        <QuillToolbar  toolbarId= {'t'+ unqNbr}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr)} value={QuillValue[unqNbr]} onChange={(val) => handleQuillValue(unqNbr,val,'col-2-4')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                    <li className='col-4-5'>
                        <QuillToolbar  toolbarId= {'t' + unqNbr + 1}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr + 1)} value={QuillValue[unqNbr + 1]} onChange={(val) => handleQuillValue(unqNbr + 1,val,'col-4-5')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                   
                    
                </ul>
            </div>
        );

        const newSec = {id: sectionId, content: newHtmlContent}
        setNewSections([...newSections, newSec])
        hideSections();
    }

    const addTwoRightSmallColumn = () =>{
        const id = uuidv4();
        let currentDate = new Date();
        var unqNbr = currentDate.getTime();
        const sectionId = 'section-' + id;
        const newHtmlContent = (
            <div className='new-section' id={sectionId}>
                <span className='delete-section' onClick={() => deleteSection(sectionId)}><i className='bx bx-x'></i></span>
                <ul className='row' >

                    <li className='col-4-5'>
                        <QuillToolbar  toolbarId= {'t'+ unqNbr}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr)} value={QuillValue[unqNbr]} onChange={(val) => handleQuillValue(unqNbr,val,'col-4-5')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                    <li className='col-2-4'>
                        <QuillToolbar  toolbarId= {'t' + unqNbr + 1}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr + 1)} value={QuillValue[unqNbr + 1]} onChange={(val) => handleQuillValue(unqNbr + 1,val,'col-2-4')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                   
                    
                </ul>
            </div>
        );

        const newSec = {id: sectionId, content: newHtmlContent}
        setNewSections([...newSections, newSec])
        hideSections();
    }


    

    const addThreeLeftSmallColumn = () =>{
        const id = uuidv4();
        let currentDate = new Date();
        var unqNbr = currentDate.getTime();
        const sectionId = 'section-' + id;
        const newHtmlContent = (
            <div className='new-section' id={sectionId}>
                <span className='delete-section' onClick={() => deleteSection(sectionId)}><i className='bx bx-x'></i></span>
                <ul className='row' >

                    <li className='col-1-5'>
                        <QuillToolbar  toolbarId= {'t'+ unqNbr}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr)} value={QuillValue[unqNbr]} onChange={(val) => handleQuillValue(unqNbr,val,'col-1-5')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                    <li className='col-1-5'>
                        <QuillToolbar  toolbarId= {'t' + unqNbr + 1}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr + 1)} value={QuillValue[unqNbr + 1]} onChange={(val) => handleQuillValue(unqNbr + 1,val,'col-1-5')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                    <li className='col-3-5'>
                        <QuillToolbar  toolbarId= {'t' + unqNbr + 2}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr + 2)} value={QuillValue[unqNbr + 2]} onChange={(val) => handleQuillValue(unqNbr + 2,val,'col-3-5')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                   
                    
                </ul>
            </div>
        );

        const newSec = {id: sectionId, content: newHtmlContent}
        setNewSections([...newSections, newSec])
        hideSections(); 
    }

    const addThreeRightSmallColumn = () =>{
        const id = uuidv4();
        let currentDate = new Date();
        var unqNbr = currentDate.getTime();
        const sectionId = 'section-' + id;
        const newHtmlContent = (
            <div className='new-section' id={sectionId}>
                <span className='delete-section' onClick={() => deleteSection(sectionId)}><i className='bx bx-x'></i></span>
                <ul className='row' >

                    <li className='col-3-5'>
                        <QuillToolbar  toolbarId= {'t'+ unqNbr}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr)} value={QuillValue[unqNbr]} onChange={(val) => handleQuillValue(unqNbr,val,'col-3-5')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                    <li className='col-1-5'>
                        <QuillToolbar  toolbarId= {'t' + unqNbr + 1}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr + 1)} value={QuillValue[unqNbr + 1]} onChange={(val) => handleQuillValue(unqNbr + 1,val,'col-1-5')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                    <li className='col-1-5'>
                        <QuillToolbar  toolbarId= {'t' + unqNbr + 2}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr + 2)} value={QuillValue[unqNbr + 2]} onChange={(val) => handleQuillValue(unqNbr + 2,val,'col-1-5')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                   
                    
                </ul>
            </div>
        );

        const newSec = {id: sectionId, content: newHtmlContent}
        setNewSections([...newSections, newSec])
        hideSections(); 
    }

    const addThreeLeftRightSmallColumn = () =>{
        const id = uuidv4();
        let currentDate = new Date();
        var unqNbr = currentDate.getTime();
        const sectionId = 'section-' + id;
        const newHtmlContent = (
            <div className='new-section' id={sectionId}>
                <span className='delete-section' onClick={() => deleteSection(sectionId)}><i className='bx bx-x'></i></span>
                <ul className='row' >
                    <li className='col-1-5'>
                        <QuillToolbar  toolbarId= {'t'+ unqNbr}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr)} value={QuillValue[unqNbr]} onChange={(val) => handleQuillValue(unqNbr,val,'col-1-5')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                    <li className='col-3-5'>
                        <QuillToolbar  toolbarId= {'t' + unqNbr + 1}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr + 1)} value={QuillValue[unqNbr + 1]} onChange={(val) => handleQuillValue(unqNbr + 1,val,'col-3-5')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                    <li className='col-1-5'>
                        <QuillToolbar  toolbarId= {'t' + unqNbr + 2}/>
                        <QuillEditor theme='snow' modules={modules('t' + unqNbr + 2)} value={QuillValue[unqNbr + 2]} onChange={(val) => handleQuillValue(unqNbr + 2,val,'col-1-5')} formats={formats} placeholder='compose your analysis here' />
                    </li>
                   
                    
                </ul>
            </div>
        );

        const newSec = {id: sectionId, content: newHtmlContent}
        setNewSections([...newSections, newSec])
        hideSections(); 
    }
    

   
   const handleSections = () =>{
    
    if(quillEdit){
        if(editorContents.length > 0){
            const finalval = `<ul class='row' >
                ${editorContents.map((q) =>{
                        return `<li class=${q.className}> ${q.content }</li>`          
                        })
                        .join('')}
                </ul>`; 
    
                setMatchField(prevState =>{
                    const newState = {
                        ...prevState,
                        analysis:finalval
                    };
    
                    handleAnalysisSubmit(newState);
    
                    return newState;
                })
    
           
        } else{
                setMatchField(prevState =>{
                    const newState = {
                        ...prevState,
                        analysis:''
                };
    
                handleAnalysisSubmit();
    
                return newState
    
            })
        }
    } else{
        const qval = Object.values(QuillValue);
        if(qval.length > 0 && qval[0].val !== '' && qval[0].val !=="<p><br></p>"){
            const finalval = `<ul class='row' >
            ${qval.map((q) =>{
                            return `<li class=${q.className}> ${q.val }</li>`          
                            })
                            .join('')}
                    </ul>`; 
        
                setMatchField(prevState =>{
                    const newState = {
                        ...prevState,
                        analysis:finalval
                };

                handleAnalysisSubmit(newState);

                return newState

            })
    
        } else{
                setMatchField(prevState =>{
                    const newState = {
                        ...prevState,
                        analysis:''
                };

                handleAnalysisSubmit();

                return newState

            })
        }
    }
   
    

        
    
   }
   
   const showEditDiv = () =>{
    setQuillEdit(true)
   }
   function splitHtmlString(htmlString) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const listItems = Array.from(doc.querySelectorAll('ul > li'));
    
        return listItems.map(li => ({
            content: li.innerHTML,
            className: li.className
        }));
  }

  const handleEditorChange = (content, index, cls) => {
    const newEditorContents = [...editorContents];
    newEditorContents[index].content = content;
    newEditorContents[index].className = cls;
    setEditorContents(newEditorContents);
  };


 

  const findQuillEditor = (s) =>{
    const valFinal = <ul className='row'>
        {editorContents.map((sp, index) =>(
            <li key={index} className={sp.className}>
                <QuillToolbar   toolbarId= {'t'+ index}/>
                 <ReactQuill 
                    value={sp.content} 
                    onChange={content => handleEditorChange(content, index, sp.className)}
                    modules={modules('t'+ index)}   
                    formats={formats}
                    theme='snow'
                />

                               
            </li>
        )
        )}
    </ul>
    return valFinal
    
  }
  useEffect(() => {
    if(matchField?.analysis){
        const splittedStrings = splitHtmlString(matchField.analysis);
        setEditorContents(splittedStrings);
    }
    
  }, [matchField?.analysis]);
  
  const handleMatchAnalysisCancel = ()=>{
    setQuillEdit(false)
    setIsEdit(false)
  }
 
    return(
             <div className="analysis" >
                <div className="create-An">
                    <p className="instruction">Compose your analysis here</p>
                        <div className="match-actions">
                                    <div className="tooltip-div">
                                        <button className="btn" data-tooltip="search" onClick={handleshowFilter} ><i className="bx bx-search" ></i></button>
                                    </div>
                                    <div className="tooltip-div">
                                        {!quillEdit && 
                                        <button className="btn" data-tooltip="edit" onClick={showEditDiv} disabled={!(matchField?.analysisId) ? true: false} style={{cursor: !matchField?.analysisId ? 'not-allowed': 'pointer'}}><i className="bx bx-edit"></i></button>
                                        }
                                    </div>
                                    <div className="tooltip-div">
                                        <button className="btn" data-tooltip="delete" onClick={showDeleteDiv} disabled={!(matchField?.analysisId) ? true: false} style={{cursor: !matchField?.analysisId ? 'not-allowed': 'pointer'}}><i className="bx bx-x"></i></button>
                                    </div>
                        </div>
                   
                        <div className='analysis-area'>
                
                            {masterSuccess.matchAnalysisMsg ? <p className="form-success-hdr">{masterSuccess.matchAnalysisMsg}</p> :
                                masterErrors.matchAnalysisError? <p className="form-error-hdr">{masterErrors.matchAnalysisError}</p> :
                                masterErrors['analysis'] ? <span className="form-error">{masterErrors['analysis']}</span> :''
                               
                            }
                    
                           {matchField?.analysis && !quillEdit &&
                           <div dangerouslySetInnerHTML={{__html:matchField.analysis}}></div>
                           
                           }
                           {matchField?.analysis && quillEdit &&
                             <div>
                                {findQuillEditor()}
                            </div>
                           }
                           
        
                            
                            {newSections.map(sec =>(
                                <div key={sec.id} className='all-sections'>
                                    {sec.content}
                                </div>
                            ))}
                           
                           {
                             !(matchField?.analysis)  && 
                            <div className={`section-controls ${sectionAdd ? '': 'active'}`}>
                                <button className='add-section' onClick={handleAddSection} data-tooltip="add new section" disabled={matchField?.matchId ? false: true} style={{cursor: !matchField?.matchId  ? 'not-allowed': 'pointer'}}><i className='bx bx-plus'></i></button>
                            </div>
                           }
                           
                                 
                            <div className={`section-body ${sectionAdd ? 'active': ''}`}>
                                <div className='section-close' onClick={() => hideSections()}>
                                    <i className='bx bx-x'></i>
                                </div>
                                <p className='section-intro'>select your structure</p>
                                
                                <div className='sections'>
                                    <div className='one-column' onClick={() => addOneColumn()}>
                                        <p></p>
                                    </div>
                                    <div className='two-equal-column' onClick={() => addTwoEqualColumn()}>
                                        <p className='each-section'></p>
                                        <span className='section-separator'></span>
                                        <p className='each-section'></p>
                                
                                    </div>
                                    
                                    <div className='three-equal-column' onClick={() => addThreeEqualColumn()}>
                                        <p className='each-section'></p>
                                        <span className='section-separator'></span>
                                        <p className='each-section'></p>
                                        <span className='section-separator'></span>
                                        <p className='each-section'></p>
                                    </div>
                                    <div className='four-equal-column' onClick={() => addFourEqualColumn()}>
                                        <p className='each-section'></p>
                                        <span className='section-separator'></span>
                                        <p className='each-section'></p>
                                        <span className='section-separator'></span>
                                        <p className='each-section'></p>
                                        <span className='section-separator'></span>
                                        <p className='each-section'></p>
                                    </div>
                                    <div className='two-left-small-column' onClick={() => addTwoLeftSmallColumn()}>
                                        <p className='each-section'></p>
                                        <span className='section-separator'></span>
                                        <p className='each-section'></p>
                                    </div>
                                    <div className='two-right-small-column' onClick={() => addTwoRightSmallColumn()}>
                                        <p className='each-section'></p>
                                        <span className='section-separator'></span>
                                        <p className='each-section'></p>
                                    </div>
                                    <div className='three-left-small-column' onClick={() => addThreeLeftSmallColumn()}>
                                        <p className='each-section'></p>
                                        <span className='section-separator'></span>
                                        <p className='each-section'></p>
                                        <span className='section-separator'></span>
                                        <p className='each-section'></p>
                                    </div>
                                    <div className='three-right-small-column' onClick={() => addThreeRightSmallColumn()}>
                                        <p className='each-section'></p>
                                        <span className='section-separator'></span>
                                        <p className='each-section'></p>
                                        <span className='section-separator'></span>
                                        <p className='each-section'></p>
                                    </div>
                                    <div className='three-left-right-small-column' onClick={() => addThreeLeftRightSmallColumn()}>
                                        <p className='each-section'></p>
                                        <span className='section-separator'></span>
                                        <p className='each-section'></p>
                                        <span className='section-separator'></span>
                                        <p className='each-section'></p>
                                    </div>

                                </div>
                                

                            </div>
                        </div>

                        <div className="analysis-button">
                            { quillEdit  && matchField?.analysisId ?
                                    <div>
                                        <button type="button" className="analysis-save active" name="analysis-submit" onClick={handleSections}>Update</button>
                                        <button type="button" className="analysis-cancel" name="analysis-cancel" onClick={handleMatchAnalysisCancel} >Cancel</button>
                                    </div>
                                    :
                                    !(quillEdit && matchField?.analysis) ?
                                        <button type="button" name="analysis-save" className={`analysis-save ${newSections.length > 0  ? 'active': ''}`} onClick={() => handleSections()}>Save</button>
                                    :
                                    ''

                            }
                        </div>
                  
                </div>
                       
            </div>
       
    );
}

export default Analysis;