import React, { useState } from "react";
import { Link } from "react-router-dom";
import logofootball from '../Assets/images/myfootball.png'
import { passwordResetSchema } from "../Validations/passwordResetValidation";

import { passwordReset } from "../services/usersService";

function PasswordReset(){
    const [email, setEmail] = useState()
    const [masterErrors, setMasterErrors] = useState({});
    const [isSubmitted, setIsSubmitted]= useState(false)

    const handlePasswordReset = async (e)=>{
        e.preventDefault()
        const data ={
            email: email,
        }
        const isValid = await validateForm(data)
        if(isValid){
            try{
                const res = await passwordReset(email)
                if(!res.ok){
                    setMasterErrors({...masterErrors, 'passwordResetError': res.error})
                }
                setIsSubmitted(true)
            } catch(err){
                if(err.response){
                    setMasterErrors({...masterErrors, 'passwordResetError': err.response.data.detail || 'User with this email does not exist.'})
                }else {
                    setMasterErrors({ ...masterErrors, 'passwordResetError': 'User with this email does not exist.' });
                }
              
            }
            
        }
    }
    const emailOnChange = (e)=>{
        setEmail(e.target.value)
    }
    const validateForm = async (data) =>{
        try{
            await passwordResetSchema.validate(data, {abortEarly: false})
            setMasterErrors({})
            return true
        } catch(err){
            const validationErrors = {};
            err.inner?.forEach(error => {
                validationErrors[error.path] = error.message
            })
            console.log(validationErrors)
            console.log(err)
            setMasterErrors(validationErrors)
            return false;
        }
    }

    return(
        <div className="login-container">
            <form  className="login">
                <div className="login-hdr">
                    <Link to="/" className='link'><img src={logofootball} alt='myFootball' className='logo'/></Link>
                    <p className="login-title">Password reset</p>
                </div>
                {
                    isSubmitted && <p className="success-msg2">Password reset link sent successfully, check your email</p>
                                    
                } 
                {masterErrors.passwordResetError && <p className="form-error-login-hdr">{masterErrors.passwordResetError}</p>}
                <div className="log-input">
                    {masterErrors['email'] && <span className="form-error-login">{masterErrors['email']}</span>}
                   <div className="login-div">
                        <i className="bx bx-user login-icon"></i>
                        <input name="email" value={email} onChange={emailOnChange} placeholder="Email" />
                    </div> 
                </div>
                <div className="password-reset-div">
                    <button type="submit" className="btn-password-reset" onClick={handlePasswordReset}>Send Password Reset Link</button>
                </div>
            
            </form>
        </div>
    )
}
export default PasswordReset;