import * as yup from 'yup'
const FILE_SIZE = 1024 * 1024 * 2
const SUPPORTED_FORMATS = ['image/jpg','image/jpeg', 'image/gif', 'image/png']


export const usersSchema = yup.object().shape({
    profile: yup.mixed()
        .nullable()
        .test('fileSize', 'Upload an image size < 2MB', value => {return !value ||(value && value.size <= FILE_SIZE)})
        .test('fileFormat', 'Unsupported Format, only jpg, jpeg, gif, png are supported', value => {return !value || (value && SUPPORTED_FORMATS.includes(value.type))}),
    first_name: yup.string().required('* First Name required'),
    last_name: yup.string().required('* Last Name required'),
    email: yup.string()
            .email('Invalid email format')
            
            .required('* Email required'),
    is_superuser: yup.object().required('* is super user required'),
    is_active: yup.object().required('* is active required'),
    is_staff: yup.object().required('* is staff required'),
    is_otp: yup.object().required('* is otp required'),
    password: yup.string()
                .required('* password required')
                .min(8, 'password must be at least 8 characters long')
                .max(20, 'password must be at most 20 characters long')
                .matches(/[a-z]/, 'password must contain lowercase letters')
                .matches(/[A-Z]/, 'password must contain uppercase letters')
                .matches(/[0-9]/, 'password must contain digits')
                .matches(/[@$!%*?&#^()]/, 'password must contain special characters')
});

export const usersSchemaEdit = yup.object().shape({
    profile: yup.mixed()
        .nullable()
        .test('fileSize', 'Upload an image size < 2MB', value => {return !value ||(value && value.size <= FILE_SIZE)})
        .test('fileFormat', 'Unsupported Format, only jpg, jpeg, gif, png are supported', value => {return !value || (value && SUPPORTED_FORMATS.includes(value.type))}),
    first_name: yup.string().required('* First Name required'),
    last_name: yup.string().required('* Last Name required'),
    email: yup.string()
            .email('Invalid email format')
            
            .required('* Email required'),
    is_superuser: yup.object().required('* is super user required'),
    is_active: yup.object().required('* is active required'),
    is_staff: yup.object().required('* is staff required'),
    is_otp: yup.object().required('* is otp required'),
});

