import * as yup from 'yup'

export const loginSchema = yup.object().shape({
    email: yup.string()
                .required('* Email required')
                .email('Invalid email format'),
    passWord: yup.string()
                .required('* password required')
                //.min(8, 'password must be at least 8 characters long')
                //.max(20, 'password must be at most 20 characters long')
                //.matches(/[a-z]/, 'password must contain lowercase letters')
                //.matches(/[A-Z]/, 'password must contain uppercase letters')
                //.matches(/[0-9]/, 'password must contain digits')
                //.matches(/[@$!%*?&#^()]/, 'password must contain special characters')
});

