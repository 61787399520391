import './header.css'
import { TypeAnimation } from 'react-type-animation'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useEffect, useState } from 'react'
import getFixtures from '../../services/fixturesService'
import moment from 'moment'

const dopL = 10
//const backEndUrl = "http://localhost:8000/media/"
const backEndUrl = "https://footballback.zeaye.com/media/"

function Upcoming(){
    const [latestFixtures, setLatestFixtures] = useState()
    const settings = {
        dots: false,
        infinite: latestFixtures?.length > 1,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000
    }

    //method to extract date and time separetely from date time field
    const formatDateTime = (timestamp) => {

       // const dateObj = new Date(timestamp);
       const dateObj = moment.utc(timestamp)
        if(timestamp){
           
           // const optionsDate = { year: 'numeric', month: 'long', day: 'numeric' };
           // const formattedDate = dateObj.toLocaleDateString('en-US', optionsDate);
          
            //const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
           // const optionsTime = { hour: 'numeric', minute: 'numeric', hour12: true };
           // const formattedTime = dateObj.toLocaleTimeString('en-US', optionsTime);

            const formattedDate = dateObj.format('MMMM D, YYYY'); // Output: August 24, 2024
            const formattedTime = dateObj.format('h:mm A'); 
            
            return { date: formattedDate, time: formattedTime };
        }
       
    };
    useEffect(()=>{
        try{
            const getLatestFixture = async () =>{
                const criteria = {dopLatest: dopL}
                const fx = await getFixtures(criteria)
                setLatestFixtures(fx)
            }
            getLatestFixture()

        }catch(err){
            console.log('Error in get matchs')
        }
    },[])
    
    
    return(
        <div>
             <div className='header-upcoming'>
                <h1>
                    <TypeAnimation 
                        sequence={['upcoming',1500, 'fixtures',1500]}
                        speed={400}
                        omitDeletionAnimation={true}
                        repeat={Infinity}
                        style={{color: '#d73953'}}
                    />
                </h1>
            </div>
            <div className="header-preview">
                <Slider {...settings} >
                    {latestFixtures?.map((fxr) => (
                        <div key={fxr.id}>
                                    <h1 className='header-preview-title'>{ formatDateTime(fxr.dop)?.date}</h1>
                                        <div className='header-preview-body'>
                                            <div className='home'>
                                                <img src={`${backEndUrl}${fxr.homeLogo}`} alt={fxr.home} className='logoFxr'/>
                                                <span className='home-text'>{fxr.home}</span>
                                            </div>
                                            
                                            <h1 className='vs'>Vs</h1>
                                            <div className='away'>
                                                <img src={`${backEndUrl}${fxr.awayLogo}`} alt={fxr.away} className='logoFxr'/>
                                                <span className='away-text'>{fxr.away}</span>
                                            </div>
                                            
                                        </div>
                                        <h1 className='header-preview-footer'>{formatDateTime(fxr.dop)?.time }</h1>  
                        </div>
                    ))}
                </Slider>
            </div>

        </div>
    )
}
export default Upcoming;