import { axiosInstance } from "../Utils/axiosInstance";


export default function getVisitors(vst){
    return axiosInstance.get('/visitors', {
        params:{
            url: vst ? vst.url : null,
            ip_address: vst ? vst.ip_address : null,
            start_time: vst? vst.start_time: null,
            time_on_site: vst? vst.time_on_site: null,
            user_id: vst? vst.user_id: null,
            visitorsType: vst? vst.visitorsType: null
        }
        
    })
        .then(response => response.data)
}


