import * as yup from 'yup'

export const passwordChangeSchema = yup.object().shape({
    currentPassword: yup.string()
                .required('* current password required'),
                
    newPassword: yup.string()
                .required('* new password required')
                .min(8, 'new password must be at least 8 characters long')
                .max(20, 'new password must be at most 20 characters long')
                .matches(/[a-z]/, 'new password must contain lowercase letters')
                .matches(/[A-Z]/, 'new password must contain uppercase letters')
                .matches(/[0-9]/, 'new password must contain digits')
                .matches(/[@$!%*?&#^()]/, 'new password must contain special characters')
                .test(
                    'not-same-as-current',
                    'can\'t use the old password. New password must be different from current password',
                    function(v){
                        return v !== this.parent.currentPassword
                    }
                ),

    confirmPassword: yup.string()
    .required('* password confirm required')
    .test(
        'not-same-as-current',
        'can\'t use the old password. New password must be different from current password',
        function(v){
            return v !== this.parent.currentPassword
        }
    )
    .oneOf([yup.ref('newPassword'), null], 'confirm password must match with the new password'),

    token: yup.string()
        .required('user token required')
        .matches(/^[A-Za-z0-9-_.+]*\.[A-Za-z0-9-_.+]*\.[A-Za-z0-9-_.+]*$/, 'Invalid token')
});

