import React, { useState } from "react";
import { loginSchema } from "../Validations/loginValidation";
import {getUser,  login } from "../services/usersService";
import "../Components/Body/user.css"
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import logofootball from '../Assets/images/myfootball.png'
import { setUser } from "../slices/authSlice";
import ReCAPTCHA from 'react-google-recaptcha'



//v3 const captcha_site_key = '6LfacFYqAAAAANPAOlmZcwjQTXWCTjTp9LpTrckw'
const captcha_site_key = '6LfS5sEqAAAAAE0OTXPtPVQ_I1qIkg8p_zEJ9fvj'

//test recapthca key
//const captcha_site_key = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
//const captcha_secrete_key = '6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe'
function Login(){

    const[email, setEmail] = useState()
    const[passWord, setPassWord] = useState()
    const [captchaToken, setCapthcaToken] = useState(null)
    const [masterErrors, setMasterErrors] = useState({});
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const emailChange = (e) =>{
        setEmail(e.target.value)
    }
    const passwordChange = (e) =>{
        setPassWord(e.target.value)
    }

    const handleKeyDown = (e) =>{
        if(e.key === 'Enter'){
            handleLogin(e)
        }
    }

    const handleCaptcha = (token) =>{
        setCapthcaToken(token)
    }
    //method to validate the input feilds
    const validateForm = async (data) =>{
        try{
            await loginSchema.validate(data, {abortEarly: false})
            setMasterErrors({})
            return true
        } catch(err){
            const validationErrors = {};
            err.inner?.forEach(error => {
                validationErrors[error.path] = error.message
            })
            console.log(validationErrors)
            console.log(err)
            setMasterErrors(validationErrors)
            return false;
        }
    }


    const cancelLogin = (e) =>{
        e.preventDefault()
        navigate('/')
    }
    const handleLogin = async (e) =>{
        e.preventDefault()
        const data ={
            email: email,
            passWord: passWord,
            captchaToken: captchaToken
        }
        const isValid = await validateForm(data)
        //console.log(isCaptchaChecked)
        if(isValid && captchaToken){
            try{
                const {status, result} = await login(data)
               
               if(status === 201)
               {
                const user = result

                navigate('/otp-confirmation', { 
                    state: {
                        user: user,
                    } 
                })


               } else if(status === 200){
                
                    const token = result.access
                    const tokenRefresh = result.refresh
                    localStorage.setItem('fb-token', token)
                    localStorage.setItem('fb-token-refresh', tokenRefresh)

                    const usr = await getUser(token)
                   // const usr = result.user
                    dispatch(setUser({detail:usr, token: token, tokenRefresh:tokenRefresh}))
                    
                  // localStorage.setItem('fb-user', JSON.stringify(usr))
                   localStorage.setItem('fb-user', usr)

                    navigate('/dashboard')
               }

                
                
            } catch(err){
                if(err.response){
                    setMasterErrors({...masterErrors, 'loginError': err.response.data.detail || 'Login failed. Please check your credentials.'})
                }else {
                    setMasterErrors({ ...masterErrors, 'loginError': 'Login failed. Please check your credentials.' });
                }
               
            }
            
        } else if(!captchaToken){
            setMasterErrors({...masterErrors, 'loginError': 'Please tick the recapthca.'})
        }
    }
   
    
    return (
        <div className="login-container">
            <form  className="login">
                <div className="login-hdr">
                    <Link to="/" className='link'><img src={logofootball} alt='myFootball' className='logo'/></Link>
                    <p className="login-title">Welcome Back</p>
                </div>
                {masterErrors.loginError && <p className="form-error-login-hdr">{masterErrors.loginError}</p>}
                <div className="log-input">
                    {masterErrors['email'] && <span className="form-error-login">{masterErrors['email']}</span>}
                   <div className="login-div">
                        <i className="bx bx-user login-icon"></i>
                        <input name="email" value={email} onChange={emailChange} placeholder="email" />
                    </div> 
                </div>
                <div className="log-input">
                    {masterErrors['passWord'] && <span className="form-error-login">{masterErrors['passWord']}</span>}
                    <div className="login-div"><i className="bx bx-key login-icon"></i><input type="password" name="passWord" value={passWord} onChange={passwordChange} onKeyDown={handleKeyDown} placeholder="password" /></div> 
                </div>
                <div className="recaptcha-div">
                    <ReCAPTCHA 
                    sitekey={captcha_site_key}
                    action="login"
                    onChange={handleCaptcha}
                    style={{ width: '100%'}}
                    />
                </div>
                <div className="forgot-password">
                    <Link to="/password-reset-request" className='forgot-link'>Forgot Password?</Link>
                </div>
               
                <div className="log-input-btn">
                    <button type="submit" className="btn-login" onClick={handleLogin} disabled={captchaToken ? false: true} style={{cursor: !captchaToken ? 'not-allowed': 'pointer'}}>Login</button>
                    <button type="submit" className="btn-login" onClick={cancelLogin}>Cancel</button>
                </div>
            </form>
        </div>
    )
    
}

export default Login;