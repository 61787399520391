import React, { useState } from "react";
import { Link } from "react-router-dom";
import logofootball from '../Assets/images/myfootball.png'
import { useLocation, useNavigate } from "react-router-dom";
import { otpVerificationSchema } from "../Validations/otpValidation";
import { verifyOTP } from "../services/usersService";
import { setUser } from "../slices/authSlice";
import { useDispatch } from 'react-redux'

function OTPConfirmation(){
    const [otp, setOtp] = useState()
    const [masterErrors, setMasterErrors] = useState({});

    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const {user} = location.state || {};

    const otpOnchange = (e) =>{
        setOtp(e.target.value)
    }

    const validateForm = async (data) =>{
        try{
            await otpVerificationSchema.validate(data, {abortEarly: false})
            setMasterErrors({})
            return true
        } catch(err){
            const validationErrors = {};
            err.inner?.forEach(error => {
                validationErrors[error.path] = error.message
            })
            console.log(validationErrors)
            console.log(err)
            setMasterErrors(validationErrors)
            return false;
        }
    }
    const handleOTPConfirmation = async (e) =>{
        e.preventDefault()
        const data ={
            otp: otp,
        }
        const isValid = await validateForm(data)
        if(isValid){
            try{
                const res = await verifyOTP(otp, user)
                
                if(res.status === 200){
                    const token = res.data.access
                    const tokenRefresh = res.data.refresh

                    localStorage.setItem('fb-token', token)
                    localStorage.setItem('fb-token-refresh', tokenRefresh)

                    dispatch(setUser({detail:user, token: token, tokenRefresh:tokenRefresh}))
                    
                    localStorage.setItem('fb-user', JSON.stringify(user))

                    navigate('/dashboard')
                    
                } else{
                    setMasterErrors({...masterErrors, 'OTPError': res.detail || 'OTP Verification failed'})
                }
                

            } catch(err){
                if(err.response){
                    setMasterErrors({...masterErrors, 'OTPError': err.response.data.detail || 'OTP Verification failed'})
                }else {
                    setMasterErrors({ ...masterErrors, 'OTPError': 'OTP Verification failed.' });
                }
               
            }
            
        }
    }
    return(
        <div className="login-container">
            <form  className="login">
                <div className="login-hdr">
                    <Link to="/" className='link'><img src={logofootball} alt='myFootball' className='logo'/></Link>
                    <p className="login-title">OTP Confirmation</p>
                </div>
                {
                 user && <p className="success-msg2">OTP Number sent successfully, check your email({user.email})</p>
                                    
                } 
                {masterErrors.OTPError && <p className="form-error-login-hdr">{masterErrors.OTPError}</p>}
                <div className="log-input">
                    {masterErrors['otp'] && <span className="form-error-login">{masterErrors['otp']}</span>}
                    
                   <div className="login-div">
                        <i className="bx bx-key login-icon"></i>
                        <input name="otp" value={otp} onChange={otpOnchange} placeholder="OTP Number from your email" />
                    </div> 
                </div>
                <div className="password-reset-div">
                    <button type="submit" className="btn-password-reset" onClick={handleOTPConfirmation}>Verify OTP</button>
                </div>
            
            </form>
        </div>
    )
}
export default OTPConfirmation