import * as yup from 'yup'

export const passwordResetConfirmSchema = yup.object().shape({
    newPassWord: yup.string()
                .required('* password required')
                .min(8, 'password must be at least 8 characters long')
                .max(20, 'password must be at most 20 characters long')
                .matches(/[a-z]/, 'password must contain lowercase letters')
                .matches(/[A-Z]/, 'password must contain uppercase letters')
                .matches(/[0-9]/, 'password must contain digits')
                .matches(/[@$!%*?&#^()]/, 'password must contain special characters'),

    newPassWordConfirm: yup.string()
    .required('* new password confirm required')
    .oneOf([yup.ref('newPassWord'), null], 'confirm password must match with the new password'),

    token: yup.string()
        .required('user token required')
        .matches(/^[A-Za-z0-9-_.+]*\.[A-Za-z0-9-_.+]*\.[A-Za-z0-9-_.+]*$/, 'Invalid token')
});

