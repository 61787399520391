import React, { useEffect, useRef, useState } from "react";
import './highlightMatchfact.css'
import Select from "react-select"
import Modal from "../../Utils/modal";
import person from '../../Assets/images/person-2.png'

import getFixtures from "../../services/fixturesService";
import { getlookupDetail } from "../../services/lookupService";
import getPlayers from "../../services/playersService";
import getclubsDetail from "../../services/clubDetailService";
import { matchFieldSchema } from "../../Validations/matchValidationo";
import { addMatch, deleteMatch, editMatchs } from "../../services/matchService";
import getLeagues from "../../services/leaguesService";

import MatchFilter from "../../Utils/matchFilter";
import getleaguesDetail from "../../services/leaguesDetailService";
import ConfirmationPopup from "../../Utils/confirmationPopup";
import { addMatchFact, deleteMatchFact, editMatchFacts } from "../../services/matchFactService";
import { deleteMatchAnalysis } from "../../services/matchAnalysis";
import { matchFactSchema } from "../../Validations/matchFactValidation";
import Analysis from "./analysis";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


//const backEndUrl = "http://localhost:8000/media/"
const backEndUrl = "https://footballback.zeaye.com/media/"

function HighlightMatchFact(){


    const userState = useSelector((state) => state.auth)
    const {detail} = userState || {}
    const updatedBy = detail?.id
    const createdBy = detail?.id

    const tblNames = [
        {name: 'lookupDetail', method: getlookupDetail},
        {name: 'staffs', method:getPlayers},
        {name: 'fixtures', method:getFixtures},
        {name: 'clubsDetail', method: getclubsDetail}
    ] 
    const [matchOptions , setMatchOptions] = useState([])
    const [masterErrors, setMasterErrors] = useState({});
    const [masterSuccess, setMasterSuccess] = useState({}); 
    const [isEdit, setIsEdit] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [clubsOptions, setClubsOptions] = useState([])
    const [leagueOptions, setLeagueOptions] = useState()
    const [showFilter, setShowFilter] = useState(false)
    const [tblType, setTblType] = useState()
    const [PenalityOptions, setPenalityOptions] = useState([])
    const [homePlayersOption, setHomePlayersOption] = useState()
    const [awayPlayersOption, setAwayPlayersOption] = useState()

    const [matchField, setMatchField] = useState({
        id : '' ,
        matchId: '',
        fixtureId : '',
        leagueName :'' ,
        home: '', 
        away: '',
        country: '',
        stadium :'',
        dop :'',
        url1:'',
        url2:'',
        url3:'',
        createdAt :'',
        createdBy :'',
        updatedAt :'',
        updatedBy :'',
        matchFactId :'',
        homeBallPosession :'',
        awayBallPosession :'',
        homeAttempts :'',
        awayAttempts:'',
        homeCorners :'',
        awayCorners :'',
        homeKeyPass :'',
        awayKeyPass :'',
        homeOnTargets :'',
        awayOnTargets :'',
        homeLineupDict: '',
        awayLineupDict: '',
        homeGoalNo: '',
        awayGoalNo:'',
        homeLineup :'',
        awayLineup :'',
        homeLineupShort :'',
        awayLineupShort :'',
        homeSubstitutionAll:'',
        awaySubstitutionAll:'',
        homeGoalScorers :'',
        awayGoalScorers :'',
        homeGoalScoredAt :'',
        awayGoalScoredAt :'',
        homeGoalAssist :'',
        awayGoalAssist :'',
        homePenality :'',
        awayPenality :'',
        homePenalityAt :'',
        awayPenalityAt :'',
        homePenalityStatus :'',
        awayPenalityStatus :'',
        homeYellowCards :'',
        awayYellowCards :'',
        homeYellowCardsAt :'',
        awayYellowCardsAt :'',
        homeRedCards :'',
        awayRedCards :'',
        homeRedCardsAt :'',
        awayRedCardsAt :'',
        homeInjury :'',
        awayInjury:'',
        homeInjuryAt :'',
        awayInjuryAt :'',
        homeSubstitutionsIn :'',
        awaySubstitutionsIn :'',
        homeSubstitutionsOut :'',
        awaySubstitutionsOut :'',
        homeSubstitutionsAt :'',
        awaySubstitutionsAt :'',
        analysisId :'',
        analysis :''
       
    })
    const [matchFieldSearch, setMatchFieldSearch] = useState({
        url1:'',
        url2:'',
        url3:'',
        fixtureId: '',
        home: '',
        away: '',
        leagueName: ''
       
    })
   

    const filterRef = useRef()


    //const [selectedMatch, setSelectedMatch] = useState('');
    const [homeGoalScorers, setHomeGoalScorers] = useState([]);
    const [awayGoalScorers, setAwayGoalScorers] = useState([]);
    const [homeGoalScoredAt, setHomeGoalScoredAt] = useState([]);
    const [awayGoalScoredAt, setAwayGoalScoredAt] = useState([]);
    const [homeGoalAssisters, setHomeGoalAssisters] = useState([]);
    const [awayGoalAssisters, setAwayGoalAssisters] = useState([]);
    const [homeGoalScoredAtFields, setHomeGoalScoredAtFields] = useState([]);
    const [awayGoalScoredAtFields, setAwayGoalScoredAtFields] = useState([]);
    const [isHome, setIsHome] = useState('');
    const [isAway, setIsAway] = useState('');
    const [PenalityNoHome, setPenalityNoHome] = useState(0);
    const [PenalityNoAway, setPenalityNoAway] = useState(0);
    const [homePenality, setHomePenality] = useState([]);
    const [awayPenality, setAwayPenality] = useState([]);
    const [homePenalityFields, setHomePenalityFields] = useState([]);
    const [awayPenalityFields, setAwayPenalityFields] = useState([]);
    const [homePenalityStatus, setHomePenalityStatus] = useState([]);
    const [awayPenalityStatus, setAwayPenalityStatus] = useState([]);
    const [homePenalityAt, setHomePenalityAt] = useState([]);
    const [awayPenalityAt, setAwayPenalityAt] = useState([]);

    const [YellowCardNoHome, setYellowCardNoHome] = useState(0);
    const [YellowCardNoAway, setYellowCardNoAway] = useState(0);
    const [homeYellowCard, setHomeYellowCard] = useState([]);
    const [awayYellowCard, setAwayYellowCard] = useState([]);
    const [homeYellowCardFields, setHomeYellowCardFields] = useState([]);
    const [awayYellowCardFields, setAwayYellowCardFields] = useState([]);
    const [homeYellowCardAt, setHomeYellowCardAt] = useState([]);
    const [awayYellowCardAt, setAwayYellowCardAt] = useState([]);

    const [RedCardNoHome, setRedCardNoHome] = useState(0);
    const [RedCardNoAway, setRedCardNoAway] = useState(0);
    const [homeRedCard, setHomeRedCard] = useState([]);
    const [awayRedCard, setAwayRedCard] = useState([]);
    const [homeRedCardFields, setHomeRedCardFields] = useState([]);
    const [awayRedCardFields, setAwayRedCardFields] = useState([]);
    const [homeRedCardAt, setHomeRedCardAt] = useState([]);
    const [awayRedCardAt, setAwayRedCardAt] = useState([]);

    const [InjuryNoHome, setInjuryNoHome] = useState(0);
    const [InjuryNoAway, setInjuryNoAway] = useState(0);
    const [homeInjury, setHomeInjury] = useState([]);
    const [awayInjury, setAwayInjury] = useState([]);
    const [homeInjuryFields, setHomeInjuryFields] = useState([]);
    const [awayInjuryFields, setAwayInjuryFields] = useState([]);
    const [homeInjuryAt, setHomeInjuryAt] = useState([]);
    const [awayInjuryAt, setAwayInjuryAt] = useState([]);

    const [homeFormation, setHomeFormation] = useState({});
    const [awayFormation, setAwayFormation] = useState({});
    const [homeSubstitutionAll, setHomeSubsitutionAll] = useState({});
    const [awaySubstitutionAll, setAwaySubstitutionAll] = useState({});
    const [dragging, setDragging] = useState(false);
   
   
   


    const [substitutionNoHome, setSubstitutionNoHome] = useState(0);
    const [substitutionNoAway, setSubstitutionNoAway] = useState(0);

    const [homeSubstitutionIn, setHomeSubsitutionIn] = useState([]);
    const [homeSubstitutionOut, setHomeSubsitutionOut] = useState([]);
    const [awaySubstitutionIn, setAwaySubsitutionIn] = useState([]);
    const [awaySubstitutionOut, setAwaySubsitutionOut] = useState([]);
    const [homeSubstitutionFields, setHomeSubstitutionFields] = useState([]);
    const [awaySubstitutionFields, setAwaySubstitutionFields] = useState([]);

    const [homeSubstitutionAt, setHomeSubstitutionAt] = useState([]);
    const [awaySubstitutionAt, setAwaySubstitutionAt] = useState([]);

    const[homePlayers, setHomePlayers] = useState();
    const[awayPlayers, setAwayPlayers] = useState();
    const[homePlayersTemp, setHomePlayersTemp] = useState();
    const[awayPlayersTemp, setAwayPlayersTemp] = useState();


    const [modalContentShow, setModalContentShow] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    
    const findPlayer = (arg, id) =>{
       if(arg === 'home'){
        return homePlayers.filter((p) => p.staffId === id);
       } else{
        return awayPlayers.filter((p) => p.staffId === id);
       }
    }
    
    
    const handleDragOver = (e) =>{
        e.preventDefault();
        setDragging(true);
        e.target.classList.add('dragging'); 
    }

    const handleDragEnd = (e) => {
        e.preventDefault();
        setDragging(false);
        e.target.classList.remove('dragging');
    }
    
    
    const handleDragLeave = (e) =>{
        e.preventDefault();
        setDragging(false);
        e.target.classList.remove('dragging');
    }

    const isChildOf = (child, parent) => {
        while (child !== null) {
          if (child === parent) return true;
          child = child.parentNode;
        }
        return false;
      };
    
    const isOcupied = (line, col, suf) =>{
        const loc = String(line) + String(col) + String(suf)

        if(isHome === 'home' && loc in homeFormation){
            return true;
        }
        if(isAway === 'away' && loc in awayFormation){
            return true;
        }
        return false;
    }
  
    const handleDrop = (e, line, col, suf) =>{
        //dont allow droping when the number of players is greater than 11
        if((isHome === 'home' && (Object.keys(homeFormation).length <= 11 || line ==='back')) || (isAway === 'away' && (Object.keys(awayFormation).length <= 11 || line ==='back'))){
            e.preventDefault();
            e.target.classList.remove('dragging');
            e.target.classList.remove('drop');
           // var playerId = e.dataTransfer.getData('text/plain');
           var data = e.dataTransfer.getData('text/plain')
           var {playerId, src} = JSON.parse(data)

            var dropZone = e.target;
          
           
           
        
            //prevent dropping to itself
            if( parseInt(playerId) === parseInt(dropZone.id)  || parseInt(playerId)  === parseInt(e.target.parentNode.id) ){
                return;
            }

           
           // console.log('id, line, col, sufix , src & dropzone id', playerId, line, col, suf, src, dropZone.id)
           

            //prevent droping to occupied space
            if(isOcupied(line, col, suf)){
                console.log('occupied space')
                return
            }


            const draggedItem = document.getElementById(playerId);
            const img = draggedItem?.querySelector('img');
            const prgs = draggedItem?.querySelectorAll('p');
            draggedItem?.classList.remove('resized-div');
            
            if(img){
                if(line === 'back'){
                    img.classList.remove('resized-image');
                    img.classList.remove('resized-image-sub');
                } else{
                    img.classList.add('resized-image');
                }
                
            }
            if(prgs){
                prgs.forEach(function(prg){
                    if(line === 'back'){
                        prg.classList.remove('resized-font');
                        prg.classList.remove('resized-font-sub')
                    } else{
                        prg.classList.add('resized-font');
                    }
                    
                })
            }

            if (isChildOf(draggedItem, dropZone)) {
                console.error('Cannot append parent to its child');
                return;
            }
           
            if(draggedItem instanceof Node){
                updateFormationState(playerId, line, col, suf, src);
                if(line !== 'back'){
                    dropZone.appendChild(draggedItem);
                }
                
            } else{
                console.log('am not node')
            }
            
        } else{
            console.log('more than 11 players')
        }
        
        
    }

    
    const updateFormationState = (playerId, line, col, suffix, src) =>{
       
        if(isHome === 'home' && line !== 'back'){
            setHomeFormation(prevFormation => {
                const updatedFormation = {...prevFormation };
                for(const existingKey in updatedFormation){
                    if(parseInt(updatedFormation[existingKey]) === parseInt(playerId)){
                        delete updatedFormation[existingKey]
                    }
                }
                updatedFormation[String(line) + String(col)+ String(suffix)] = playerId.toString();
                return updatedFormation
            });
            if(src === 'players'){
               setHomePlayersTemp(homePlayersTemp.filter(plr => plr.staffId !== playerId));
            } else if(src === 'substitution'){
                setHomeSubsitutionAll(prevFormation =>{
                    const updateFormations = { ...prevFormation };
                    delete updateFormations[playerId];
                    return updateFormations;
                })
            }
            


        } else if(isAway === 'away' && line !== 'back'){
            setAwayFormation(prevFormation => {
                const updatedFormation = {...prevFormation };
                for(const existingKey in updatedFormation){
                    if(updatedFormation[existingKey] === playerId){
                        delete updatedFormation[existingKey]
                    }
                }
                updatedFormation[String(line) + String(col)+ String(suffix)] = playerId;
                return updatedFormation
            });
           if(src === 'players'){
                setAwayPlayersTemp(awayPlayersTemp.filter(plr => plr.staffId !== playerId));
           } else if(src === 'substitution'){
                setAwaySubstitutionAll(prevFormation =>{
                    const updateFormations = { ...prevFormation };
                    delete updateFormations[playerId];
                    return updateFormations;
                })
           }
        }

        if(suffix === undefined && line === 'back'){
            if(isHome === 'home'){
                if(src === 'substitution'){
                    setHomeSubsitutionAll(prevFormation =>{
                        const updateFormations = { ...prevFormation };
                        delete updateFormations[playerId];
                        return updateFormations;
                    })
                } else if(src === 'best-11'){
                    setHomeFormation(prevFormation =>{
                        const updatedFormation = {...prevFormation };
                        for(const existingKey in updatedFormation){
                          
                            if(parseInt(updatedFormation[existingKey]) === parseInt(playerId) ){
                                delete updatedFormation[existingKey]
                                console.log('deleting:', playerId)
                            } 
                        }
                        return updatedFormation;
                    })
                  

                }
                
               
            } else if(isAway === 'away'){
                if(src === 'substitution'){
                    setAwaySubstitutionAll(prevFormation =>{
                        const updateFormations = { ...prevFormation };
                        delete updateFormations[playerId];
                        return updateFormations;
                    })
                } else if(src === 'best-11'){
                    setAwayFormation(prevFormation =>{
                        const updatedFormation = {...prevFormation };
                        for(const existingKey in updatedFormation){
                            if(updatedFormation[existingKey] === playerId){
                                delete updatedFormation[existingKey]
                            }
                        }
                        return updatedFormation;
                    })
                }
                
            }
        }
        
    }

    const updateSubstitutionState = (playerId, line, src) =>{
      
        if(isHome === 'home' && line !== 'back'){
            setHomeSubsitutionAll(prevFormation =>{
                const updateFormations = { ...prevFormation };
                updateFormations[playerId] = playerId;
                return updateFormations;
            })
            if(src === 'players'){
                setHomePlayersTemp(homePlayersTemp.filter(plr => plr.staffId !== playerId));
            } else if (src === 'best-11'){
                setHomeFormation(prevFormation =>{
                    const updatedFormation = {...prevFormation };
                    for(const existingKey in updatedFormation){
                        if(updatedFormation[existingKey] === playerId){
                            delete updatedFormation[existingKey]
                        }
                    }
                    return updatedFormation;
                })
            }
        } else if(isAway === 'away' && line !== 'back'){
            setAwaySubstitutionAll(prevFormation =>{
                const updateFormations = { ...prevFormation };
                updateFormations[playerId] = playerId
                return updateFormations;
            })
            if(src === 'players'){
                setAwayPlayersTemp(awayPlayersTemp.filter(plr => plr.staffId !== playerId));

            } else if(src === 'best-11'){
                setAwayFormation(prevFormation =>{
                    const updatedFormation = {...prevFormation };
                    for(const existingKey in updatedFormation){
                        if(updatedFormation[existingKey] === playerId){
                            delete updatedFormation[existingKey]
                        }
                    }
                    return updatedFormation;
                })
            }
        }

        
    }
    
   
    const handleDropSubstitute = (e, type) =>{
        e.preventDefault();
        e.target.classList.remove('dragging');
        e.target.classList.remove('drop');
       // const playerId = e.dataTransfer.getData('text/plain');
       var data = e.dataTransfer.getData('text/plain');
       var {playerId, src} = JSON.parse(data)
       
        const draggedItem = document.getElementById(playerId);
        const img = draggedItem?.querySelector('img');
        const prgs = draggedItem?.querySelectorAll('p');
        draggedItem?.classList.add('resized-div');

        updateSubstitutionState(playerId, type, src)

        if(img){
            if(type === 'back'){
                img.classList.remove('resized-image-sub');
            } else{
                img.classList.add('resized-image-sub');
            }
        }

        if(prgs){
            prgs.forEach(function(prg){
                if(type === 'back'){
                    prg.classList.remove('resized-font-sub');
                } else{
                    prg.classList.add('resized-font-sub');
                }
                
            })
        }

        const liElem = document.createElement('li')
        liElem.classList.add('col-1-2');

        liElem.addEventListener('mousedown', function(e){
            if(e.target !== liElem){
                return;
            }
            e.preventDefault();
            liElem.style.cursor = 'grabbing';
            e.dataTransfer.setData('text/plain', 'anything');
        })

        liElem.draggable = true;
        liElem.addEventListener('dragstart', function(e){
            e.dataTransfer.setData('text/plain', 'anything');
        })

        liElem.appendChild(draggedItem);
       // e.target.appendChild(liElem);
    }

    const handleDragStart = (e, playerId, src) =>{
        const data = JSON.stringify({playerId, src})
        e.dataTransfer.setData('text/plain', data);
        if(dragging){
            setDragging(false);
        } else{
            setDragging(true);
        }
        
    }
   
    const notInDictionary = (dict, item) =>{
        return Object.values(dict).every((value) => value !== String(item))
    }
 
    useEffect(() =>{
        const initialFields = Array.from({ length: matchField?.homeGoalNo}, (_, index) =>(index + 1));
        setHomeGoalScoredAtFields(initialFields);
    }, [matchField?.homeGoalNo])

    useEffect(() =>{
        const initialFields = Array.from({ length: matchField?.awayGoalNo}, (_, index) =>(index + 1));
        setAwayGoalScoredAtFields(initialFields);
    }, [matchField?.awayGoalNo])

    useEffect(() =>{
        const initialFields = Array.from({ length: PenalityNoHome}, (_, index) =>(index + 1));
        setHomePenalityFields(initialFields);
    }, [PenalityNoHome])

    useEffect(() =>{
        const initialFields = Array.from({ length: PenalityNoAway}, (_, index) =>(index + 1));
        setAwayPenalityFields(initialFields);
    }, [PenalityNoAway])

    useEffect(() =>{
        const initialFields = Array.from({ length: YellowCardNoHome}, (_, index) =>(index + 1));
        setHomeYellowCardFields(initialFields);
    }, [YellowCardNoHome])

    useEffect(() =>{
        const initialFields = Array.from({ length: YellowCardNoAway}, (_, index) =>(index + 1));
        setAwayYellowCardFields(initialFields);
    }, [YellowCardNoAway])

    useEffect(() =>{
        const initialFields = Array.from({ length: RedCardNoHome}, (_, index) =>(index + 1));
        setHomeRedCardFields(initialFields);
    }, [RedCardNoHome])

    useEffect(() =>{
        const initialFields = Array.from({ length: RedCardNoAway}, (_, index) =>(index + 1));
        setAwayRedCardFields(initialFields);
    }, [RedCardNoAway])

    useEffect(() =>{
        const initialFields = Array.from({ length: InjuryNoHome}, (_, index) =>(index + 1));
        setHomeInjuryFields(initialFields);
    }, [InjuryNoHome])

    useEffect(() =>{
        const initialFields = Array.from({ length: InjuryNoAway}, (_, index) =>(index + 1));
        setAwayInjuryFields(initialFields);
    }, [InjuryNoAway])

    useEffect(() =>{
        const initialFields = Array.from({ length: substitutionNoHome}, (_, index) =>(index + 1));
        setHomeSubstitutionFields(initialFields);
    }, [substitutionNoHome])

    useEffect(() =>{
        const initialFields = Array.from({ length: substitutionNoAway}, (_, index) =>(index + 1));
        setAwaySubstitutionFields(initialFields);
    }, [substitutionNoAway])

    
   
    const handleHomeGoals = (e) =>{
        e.preventDefault();

        

        const homeGoalValues = homeGoalScorers.map(obj => obj.value);
        console.log('final home goal scorers: ', homeGoalValues.join(','));
       
        const homeAssistVaues = homeGoalAssisters.map(obj => obj.value);
        console.log('final home goal assisters: ', homeAssistVaues.join(','));
       
        console.log('final home goal scored at: ', homeGoalScoredAt.join(','));

        setMatchField({ ...matchField, homeGoalScorers:homeGoalValues.join(','), homeGoalAssist: homeAssistVaues.join(','), homeGoalScoredAt: homeGoalScoredAt.join(',') })

        closeModal();
    }

    const handleAwayGoals = (e) =>{
        e.preventDefault();

        const awayGoalValues = awayGoalScorers.map(obj => obj.value);
        console.log('final away goal scorers: ', awayGoalValues.join(', '));
       
        const awayAssistVaues = awayGoalAssisters.map(obj => obj.value);
        console.log('final away goal assisters: ', awayAssistVaues.join(', '));
       
        console.log('final away goal scored at: ', awayGoalScoredAt.join(', '));

        setMatchField({ ...matchField, awayGoalScorers:awayGoalValues.join(','), awayGoalAssist: awayAssistVaues.join(','), awayGoalScoredAt: awayGoalScoredAt.join(',') })

       
        closeModal();
    }
   
    const handleHomePenalitys = (e) =>{
        e.preventDefault();

        const homePenalityValues = homePenality.map(obj => obj.value);
        const homePenalityStatusValues = homePenalityStatus.map(obj => obj.value);

        console.log('final home penalitys: ', homePenalityValues.join(', '));
        console.log('final home penality at: ', homePenalityAt.join(', '));
        console.log('final home penality status: ', homePenalityStatusValues.join(', '));

        setMatchField({ ...matchField, homePenality: homePenalityValues.join(','), homePenalityAt:homePenalityAt.join(','), homePenalityStatus: homePenalityStatusValues.join(',')})

        closeModal();
    }
    const handleAwayPenalitys = (e) =>{
        e.preventDefault();

        const awayPenalityValues = awayPenality.map(obj => obj.value);
        const awayPenalityStatusValues = awayPenalityStatus.map(obj => obj.value);

        console.log('final away penalitys: ', awayPenalityValues.join(', '));
        console.log('final away penality at: ', awayPenalityAt.join(', '));
        console.log('final away penality status: ', awayPenalityStatusValues.join(', '));

        setMatchField({ ...matchField, awayPenality: awayPenalityValues.join(','), awayPenalityAt:homePenalityAt.join(','), awayPenalityStatus: awayPenalityStatusValues.join(',')})


        closeModal();
    }
   
    const handleHomeYellowCard = (e) =>{
        e.preventDefault();

        const homeYellowCardValues = homeYellowCard.map(obj => obj.value);
        console.log('final Home Yellow cards: ', homeYellowCardValues.join(', '));
       
        console.log('final Home Yellow cards at: ', homeYellowCardAt.join(', '));

        setMatchField({ ...matchField, homeYellowCards: homeYellowCardValues.join(','), homeYellowCardsAt:homeYellowCardAt.join(',')})

       
        closeModal();
    }

    const handleHomeRedCard = (e) =>{
        e.preventDefault();

        const homeRedCardValues = homeRedCard.map(obj => obj.value);
        console.log('final Home Red cards: ', homeRedCardValues.join(', '));
       
        console.log('final Home Red cards at: ', homeRedCardAt.join(', '));

        setMatchField({ ...matchField, homeRedCards: homeRedCardValues.join(','), homeRedCardsAt:homeRedCardAt.join(',')})

       
        closeModal();
    }
    const handleAwayYellowCard = (e) =>{
        e.preventDefault();

        const awayYellowCardValues = awayYellowCard.map(obj => obj.value);
        console.log('final away Yellow cards: ', awayYellowCardValues.join(', '));
       
        console.log('final away Yellow cards at: ', awayYellowCardAt.join(', '));

        setMatchField({ ...matchField, awayYellowCards: awayYellowCardValues.join(','), awayYellowCardsAt:awayYellowCardAt.join(',')})

       
        closeModal();
    }
    const handleAwayRedCard = (e) =>{
        e.preventDefault();

        const awayRedCardValues = awayRedCard.map(obj => obj.value);
        console.log('final away Red cards: ', awayRedCardValues.join(', '));
       
        console.log('final away Red cards at: ', awayRedCardAt.join(', '));

        setMatchField({ ...matchField, awayRedCards: awayRedCardValues.join(','), awayRedCardsAt:awayRedCardAt.join(',')})

       
        closeModal();
    }

    const handleHomeInjury = (e) =>{
        e.preventDefault();
        const homeInjuryValues = homeInjury.map(obj => obj.value);
        console.log('final Home injury: ', homeInjuryValues.join(', '));
        console.log('final Home injury at: ', homeInjuryAt.join(', '));

        setMatchField({ ...matchField, homeInjury: homeInjuryValues.join(','), homeInjuryAt:homeInjuryAt.join(',')})

        closeModal();
    }

    const handleAwayInjury = (e) =>{
        e.preventDefault();
        const awayInjuryValues = awayInjury.map(obj => obj.value);
        console.log('final Away injury: ', awayInjuryValues.join(', '));
        console.log('final Away injury at: ', awayInjuryAt.join(', '));

        setMatchField({ ...matchField, awayInjury: awayInjuryValues.join(','), awayInjuryAt:awayInjuryAt.join(',')})

        closeModal();
    }
    

    const handleHomeSubstution = (e) =>{
        e.preventDefault();

        const homeSubstitutionValues = homeSubstitutionIn.map(obj => obj.value);
        console.log('final Home substitution in : ', homeSubstitutionValues.join(', '));
       
        const homeSubstitutionOutVaues = homeSubstitutionOut.map(obj => obj.value);
        console.log('final Home substitution out: ', homeSubstitutionOutVaues.join(', '));
       
        console.log('final Home substitution at: ', homeSubstitutionAt.join(', '));

        setMatchField({ ...matchField, homeSubstitutionsIn: homeSubstitutionValues.join(','), homeSubstitutionsOut:homeSubstitutionOutVaues.join(', '), homeSubstitutionsAt:homeSubstitutionAt.join(',')})

       
        closeModal();
    }
    const handleAwaySubstution = (e) =>{
        e.preventDefault();

        const awaySubstitutionValues = awaySubstitutionIn.map(obj => obj.value);
        console.log('final Away substitution in : ', awaySubstitutionValues.join(', '));
       
        const awaySubstitutionOutVaues = awaySubstitutionOut.map(obj => obj.value);
        console.log('final Away substitution out: ', awaySubstitutionOutVaues.join(', '));

        console.log('final Away substitution at: ', awaySubstitutionAt.join(', '));

        setMatchField({ ...matchField, awaySubstitutionsIn: awaySubstitutionValues.join(','), awaySubstitutionsOut:awaySubstitutionOutVaues.join(', '), awaySubstitutionsAt:awaySubstitutionAt.join(',')})

       
        closeModal();
    }
   
    const handleHomeFormation = (e) =>{
        e.preventDefault();

        let zeroLine = '', oneLine = '', oneLineNbr = 0, twoLine = '', twoLineNbr = 0, threeLine = '', threeLineNbr = 0, fourLine = '', fourLineNbr = 0, fiveLine = '', fiveLineNbr = 0;
        console.log('home formation: ', homeFormation);

        for(const [key, value] of Object.entries(homeFormation)){
            if(key.slice(0, 1) === '0'){
                zeroLine += String(value);
            }
            else if(key.slice(0,1) === '1' ){
                oneLine += String(value) + key.slice(2, 3) + '-';
                oneLineNbr += 1;
            }
            else if(key.slice(0,1) === '2' ){
                twoLine += String(value) + key.slice(2, 3) + '-';
                twoLineNbr += 1;
            }
            else if(key.slice(0,1) === '3' ){
                threeLine += String(value) +  key.slice(2, 3) + '-';
                threeLineNbr += 1;
            }
            else if(key.slice(0,1) === '4' ){
                fourLine += String(value)  + key.slice(2, 3) + '-';
                fourLineNbr += 1;
            }
            else if(key.slice(0,1) === '5' ){
                fiveLine += String(value)  + key.slice(2, 3) + '-';
                fiveLineNbr += 1;
            }
        };
       
        var homeFormationValues = '';
       if(zeroLine !== ''){
        homeFormationValues += zeroLine + ', ';
       }
       if(oneLine !== ''){
        homeFormationValues += oneLine.slice(0, -1) + ', ';
       }
       if(twoLine !== ''){
        homeFormationValues += twoLine.slice(0, -1) + ', ';
       }
       if(threeLine !== ''){
        homeFormationValues += threeLine.slice(0, -1) + ', ';
       }
       if(fourLine !== ''){
        homeFormationValues += fourLine.slice(0, -1) + ', ';
       }
       if(fiveLine !== ''){
        homeFormationValues += fiveLine.slice(0, -1) + ', ';
       }
        homeFormationValues = homeFormationValues.slice(0, -2);

        var homeFormationNbr = '';
        if(oneLineNbr > 0){
            homeFormationNbr += String(oneLineNbr) + '-';
        }
        if(twoLineNbr > 0){
            homeFormationNbr += String(twoLineNbr) + '-';
        }
        if(threeLineNbr > 0){
            homeFormationNbr += String(threeLineNbr) + '-';
        }
        if(fourLineNbr > 0){
            homeFormationNbr += String(fourLineNbr) + '-';
        }
        if(fiveLineNbr > 0){
            homeFormationNbr += String(fiveLineNbr) + '-';
        }
       
        console.log('final Home Formation : ', homeFormationValues);
       // setHomeNoFormation(homeFormationNbr.slice(0, -1));
        console.log('final home formation short hand: ', homeFormationNbr.slice(0, -1));

        const homeSubstitutionAllValues = Object.values(homeSubstitutionAll);
       
        console.log('final Home Substitution All : ', homeSubstitutionAllValues.join(', '));

        setMatchField({ ...matchField, homeLineup: homeFormationValues, homeLineupShort:homeFormationNbr.slice(0, -1), homeLineupDict: homeFormation, homeSubstitutionAll: homeSubstitutionAll })

       
       // setHomeNoFormation(homeFormationNbr.slice(0, -1))
    
        closeModal();
    }

    const handleAwayFormation = (e) =>{
        e.preventDefault();

        let zeroLine = '', oneLine = '', oneLineNbr = 0, twoLine = '', twoLineNbr = 0, threeLine = '', threeLineNbr = 0, fourLine = '', fourLineNbr = 0, fiveLine = '', fiveLineNbr = 0;
       
        /*
        for (let playerId in awayFormation){
            const {line, position} = awayFormation[playerId][0];

            if(line === 0){
                zeroLine += String(playerId);
            } else if(line === 1){
                oneLine += String(playerId) + String(position) + '-';
                oneLineNbr += 1;
            } else if(line === 2){
                twoLine += String(playerId) + String(position) + '-';
                twoLineNbr += 1;
            } else if(line === 3){
                threeLine += String(playerId) + String(position) + '-';
                threeLineNbr += 1
            } else if(line === 4){
                fourLine += String(playerId) + String(position) + '-';
                fourLineNbr += 1;
            } else if(line === 5){
                fiveLine += String(playerId) + String(position) + '-';
                fiveLineNbr += 1;
            }
        }
        */
        for(const [key, value] of Object.entries(awayFormation)){
            if(key.slice(0, 1) === '0'){
                zeroLine += String(value);
            }
            else if(key.slice(0,1) === '1' ){
                oneLine += String(value) + key.slice(2, 3) + '-';
                oneLineNbr += 1;
            }
            else if(key.slice(0,1) === '2' ){
                twoLine += String(value) + key.slice(2, 3) + '-';
                twoLineNbr += 1;
            }
            else if(key.slice(0,1) === '3' ){
                threeLine += String(value) +  key.slice(2, 3) + '-';
                threeLineNbr += 1;
            }
            else if(key.slice(0,1) === '4' ){
                fourLine += String(value)  + key.slice(2, 3) + '-';
                fourLineNbr += 1;
            }
            else if(key.slice(0,1) === '5' ){
                fiveLine += String(value)  + key.slice(2, 3) + '-';
                fiveLineNbr += 1;
            }
        };

        var awayFormationValues = '';
       
        if(zeroLine !== ''){
            awayFormationValues += zeroLine;
        }
        if(oneLine !== ''){
            awayFormationValues += oneLine.slice(0, -1) + ', ';
        }
        if(twoLine !== ''){
            awayFormationValues += twoLine.slice(0, -1) + ', ';
        }
        if(threeLine !== ''){
            awayFormationValues += threeLine.slice(0, -1) + ', ';
        }
        if(fourLine !== ''){
            awayFormationValues += fourLine.slice(0, -1) + ', ';
        }
        if(fiveLine !== ''){
            awayFormationValues += fiveLine.slice(0, -1) + ', ';
        }
        awayFormationValues = awayFormationValues.slice(0, -2);
 
        
        var awayFormationNbr = '';
        if(oneLineNbr > 0){
            awayFormationNbr += String(oneLineNbr) + '-';
        }
        if(twoLineNbr > 0){
            awayFormationNbr += String(twoLineNbr) + '-';
        }
        if(threeLineNbr > 0){
            awayFormationNbr += String(threeLineNbr) + '-';
        }
        if(fourLineNbr > 0){
            awayFormationNbr += String(fourLineNbr) + '-';
        }
        if(fiveLineNbr > 0){
            awayFormationNbr += String(fiveLineNbr) + '-';
        }
        setMatchField({ ...matchField, awayLineup: awayFormationValues, awayLineupShort:awayFormationNbr.slice(0, -1), awayLineupDict: awayFormation })

        console.log('final Away Formation : ', awayFormationValues);
       // setAwayNoFormation(awayFormationNbr.slice(0, -1));
        console.log('final Away formation short hand: ', awayFormationNbr.slice(0, -1));

        const awaySubstitutionAllValues = Object.values(awaySubstitutionAll);
        console.log('final Away Substitution All : ', awaySubstitutionAllValues.join(', '));
       
        setMatchField({ ...matchField, awayLineup: awayFormationValues, awayLineupShort:awayFormationNbr.slice(0, -1), awayLineupDict: awayFormation, awaySubstituionAll: awaySubstitutionAll })

        closeModal();
    }

    const handleGoalNoHomeChange = (e) =>{
        setIsHome('home');
        setIsAway('');
        setModalContentShow('goal')
        setMatchField({ ...matchField, homeGoalNo:e.target.value })
    }
   

    const handlePenalityNoHomeChange = (e) =>{
        setPenalityNoHome(e.target.value);
        setIsHome('home');
        setIsAway('');
        setModalContentShow('penality');
    }
    const handleGoalNoAwayChange = (e) =>{
        setIsHome('');
        setIsAway('away');
        setModalContentShow('goal');
        setMatchField({ ...matchField, awayGoalNo:e.target.value })
    }
    const handlePenalityNoAwayChange = (e) =>{
        setPenalityNoAway(e.target.value);
        setIsHome('');
        setIsAway('away');
        setModalContentShow('penality');
    }

    const handleYellowCardNoHomeChange = (e) =>{
        setYellowCardNoHome(e.target.value);
        setIsHome('home');
        setIsAway('');
        setModalContentShow('yellow');
    }
    const handleRedCardNoHomeChange = (e) =>{
        setRedCardNoHome(e.target.value);
        setIsHome('home');
        setIsAway('');
        setModalContentShow('red');
    }
    const handleYellowCardNoAwayChange = (e) =>{
        setYellowCardNoAway(e.target.value);
        setIsHome('');
        setIsAway('away');
        setModalContentShow('yellow');
    }
    const handleRedCardNoAwayChange = (e) =>{
        setRedCardNoAway(e.target.value);
        setIsHome('');
        setIsAway('away');
        setModalContentShow('red');
    }
    const handleInjuryNoHomeChange = (e) =>{
        setInjuryNoHome(e.target.value);
        setIsHome('home');
        setIsAway('');
        setModalContentShow('injury');
    }
    const handleInjuryNoAwayChange = (e) =>{
        setInjuryNoAway(e.target.value);
        setIsHome('');
        setIsAway('away');
        setModalContentShow('injury');
    }
    const handleSubstitutionNoHomeChange = (e) =>{
        setSubstitutionNoHome(e.target.value);
        setIsHome('home');
        setIsAway('');
        setModalContentShow('substitution');
    }
    const handleSubstitutionNoAwayChange = (e) =>{
        setSubstitutionNoAway(e.target.value);
        setIsHome('');
        setIsAway('away');
        setModalContentShow('substitution');
    }

    
    const handleMatchFieldReset = (fixId) =>{
        setMatchField({
            id : '' ,
            matchId: '',
            fixtureId : fixId,
            leagueName :'' ,
            home: '', 
            away: '',
            country: '',
            stadium :'',
            dop :'',
            url1:'',
            url2:'',
            url3:'',
            createdAt :'',
            createdBy :'',
            updatedAt :'',
            updatedBy :'',
            matchFactId :'',
            homeBallPosession :'',
            awayBallPosession :'',
            homeAttempts :'',
            awayAttempts:'',
            homeCorners :'',
            awayCorners :'',
            homeKeyPass :'',
            awayKeyPass :'',
            homeOnTargets :'',
            awayOnTargets :'',
            homeLineupDict: '',
            awayLineupDict: '',
            homeLineup :'',
            awayLineup :'',
            homeLineupShort :'',
            awayLineupShort :'',
            homeSubstitutionAll:'',
            awaySubstitutionAll:'',
            homeGoalNo: '',
            awayGoalNo:'',
            homeGoalScorers :'',
            awayGoalScorers :'',
            homeGoalScoredAt :'',
            awayGoalScoredAt :'',
            homeGoalAssist :'',
            awayGoalAssist :'',
            homePenality :'',
            awayPenality :'',
            homePenalityAt :'',
            awayPenalityAt :'',
            homePenalityStatus :'',
            awayPenalityStatus :'',
            homeYellowCards :'',
            awayYellowCards :'',
            homeYellowCardsAt :'',
            awayYellowCardsAt :'',
            homeRedCards :'',
            awayRedCards :'',
            homeRedCardsAt :'',
            awayRedCardsAt :'',
            homeInjury :'',
            awayInjury:'',
            homeInjuryAt :'',
            awayInjuryAt :'',
            homeSubstitutionsIn :'',
            awaySubstitutionsIn :'',
            homeSubstitutionsOut :'',
            awaySubstitutionsOut :'',
            homeSubstitutionsAt :'',
            awaySubstitutionsAt :'',
            analysisId :'',
            analysis :''
           
        })
        setPenalityNoHome('')
        setPenalityNoAway('')
        setYellowCardNoAway('')
        setYellowCardNoHome('')
        setRedCardNoAway('')
        setRedCardNoHome('')
        setInjuryNoAway('')
        setInjuryNoHome('')
        setSubstitutionNoAway('')
        setSubstitutionNoHome('')
        setIsEdit(false)
        setMasterErrors({})
        setMasterSuccess({})
    }

    const handleMatchChange = async (selectedOption) =>{
      //  setSelectedMatch(selectedOption)
        handleMatchFieldReset(selectedOption)
       // setMatchField({ ...matchField, fixtureId: selectedOption})
        const homeId = selectedOption.homeId
        const awayId = selectedOption.awayId
        var criteria = {
            clubId: homeId,
            status: 'Active',
            position: 'Player'
        }
        var players = await getDataFromTable('clubsDetail', criteria)
        setHomePlayers(players);
        setHomePlayersTemp(players)
      
        criteria = {
            clubId: awayId,
            status: 'Active',
            position: 'Player'
        }
        players = await getDataFromTable('clubsDetail', criteria)
        setAwayPlayers(players);
        setAwayPlayersTemp(players)
        
        
    }

   
   
    const handlePlayersOption = async (row) =>{
        
        if(row){
            var criteria = {
                clubId: row.homeId,
                status: 'Active',
                position: 'Player'
            }
            var players = await getDataFromTable('clubsDetail', criteria)
  
            const opts = players;
            const homePlayOption = opts.map(opt => ({
                  value: opt.staffId,
                  label: `${opt.shirtNbr} | ${opt.staffName}`
              }))
            setHomePlayersOption(homePlayOption)
            setHomePlayers(players);
            setHomePlayersTemp(players)
          
            criteria = {
                clubId: row.awayId,
                status: 'Active',
                position: 'Player'
            }
            players = await getDataFromTable('clubsDetail', criteria)
            const optsAw = players;
            const awayPlayOption = optsAw.map(opt => ({
                  value: opt.staffId,
                  label: `${opt.shirtNbr} | ${opt.staffName}`
              }))
            setAwayPlayersOption(awayPlayOption)
            setAwayPlayers(players);
            setAwayPlayersTemp(players)

            // update  formations
            if(row.homeLineupDict){
                setHomeFormation(row.homeLineupDict)
            }
            if(row.awayLineupDict){
                setAwayFormation(row.awayLineupDict)
            }
            if(row.homeSubstitutionAll){
                setHomeSubsitutionAll(row.homeSubstitutionAll)
            }
            if(row.awaySubstituionAll){
                setAwaySubstitutionAll(row.awaySubstituionAll)
            }


  
              //update goal scorers
              if(row.homeGoalScorers){
                  const goalScore = row.homeGoalScorers.split(',')
                
                  const newValues = [...homeGoalScorers];
                  for(let index = 0; index < goalScore.length; index++){
                    const option = homePlayOption?.find(opt => opt.value === parseInt(goalScore[index]))
                    newValues[index] = option;
                  }
                  setHomeGoalScorers(newValues);
              }
  
              if(row.awayGoalScorers){
                  const goalScoreAw = row.awayGoalScorers.split(',')
                  const newValuesAw = [...awayGoalScorers];
        
                  for(let index = 0; index < goalScoreAw.length; index++){
                    const option = awayPlayOption?.find(opt => opt.value === parseInt(goalScoreAw[index]))
                    newValuesAw[index] = option;
                  }
                  setAwayGoalScorers(newValuesAw);
              }
           
  
           
  
  
             //update goal assists
             if(row.homeGoalAssist){
              const goalAssist = row.homeGoalAssist.split(',')
              const assistValues = [...homeGoalAssisters];
              for(let index = 0; index < goalAssist.length; index++){
                  const option = homePlayOption?.find(opt => opt.value === parseInt(goalAssist[index]))
                  assistValues[index] = option;
              }
              setHomeGoalAssisters(assistValues);
             }
             
             if(row.awayGoalAssist){
              const goalAssistAw = row.awayGoalAssist.split(',')
              const assistValuesAw = [...awayGoalAssisters];
      
              for(let index = 0; index < goalAssistAw.length; index++){
                  const option = awayPlayOption?.find(opt => opt.value === parseInt(goalAssistAw[index]))
                  assistValuesAw[index] = option;
              }
              setAwayGoalAssisters(assistValuesAw);
             }
             
             
             
  
              //update goal scored at
              if( row.homeGoalScoredAt){
                  const goalScoreAt = row.homeGoalScoredAt.split(',')
                  const scoreAtValues = [...homeGoalScoredAt];
                  for(let index = 0; index < goalScoreAt.length; index++){
                      scoreAtValues[index] = goalScoreAt[index];
                  }
                  setHomeGoalScoredAt(scoreAtValues);
              }
             
              if(row.awayGoalScoredAt){
                  const goalScoreAtAw = row.awayGoalScoredAt.split(',')
                  const scoreAtValuesAw = [...awayGoalScoredAt];
      
                  for(let index = 0; index < goalScoreAtAw.length; index++){
                      scoreAtValuesAw[index] = goalScoreAtAw[index];
                  }
                  setAwayGoalScoredAt(scoreAtValuesAw);
              }
    
          
  
              //update penality
              if(row.homePenality) {
                  const penal = row.homePenality.split(',')
                  const penValues = [...homePenality];
                  for(let index = 0; index < penal.length; index++){
                      const option = homePlayOption?.find(opt => opt.value === parseInt(penal[index]))
                      penValues[index] = option;
                  }
                  setHomePenality(penValues);
                  setPenalityNoHome(penal.length)
              }
            
              if(row.awayPenality){
                  const penalAw = row.awayPenality.split(',')
                  const penValuesAw = [...awayPenality];
  
                  for(let index = 0; index < penalAw.length; index++){
                      const option = awayPlayOption?.find(opt => opt.value === parseInt(penalAw[index]))
                      penValuesAw[index] = option;
                  }
                  setAwayPenality(penValuesAw);
                  setPenalityNoAway(penalAw.length)
              }
  
              //update penality status
              if(row.homePenalityStatus) {
                  const penalSts = row.homePenalityStatus.split(',')
                  const penStsValues = [...homePenalityStatus];
                  for(let index = 0; index < penalSts.length; index++){
                      const option = PenalityOptions?.find(opt => opt.value === parseInt(penalSts[index]))
                      penStsValues[index] = option;
                  }
                  setHomePenalityStatus(penStsValues);
          
              }
            
              if(row.awayPenalityStatus){
                  const penalStsAw = row.awayPenalityStatus.split(',')
                  const penStsValuesAw = [...awayPenalityStatus];
  
                  for(let index = 0; index < penalStsAw.length; index++){
                      const option = PenalityOptions?.find(opt => opt.value === parseInt(penalStsAw[index]))
                      penStsValuesAw[index] = option;
                  }
                  setAwayPenalityStatus(penStsValuesAw);
              }
  
               //update penality at
               if(row.homePenalityAt) {
                  const penalAt = row.homePenalityAt.split(',')
                  const penAtValues = [...homePenalityAt];
                  for(let index = 0; index < penalAt.length; index++){
                      const option = penalAt[index]
                      penAtValues[index] = option;
                  }
                  setHomePenalityAt(penAtValues);
          
              }
            
              if(row.awayPenalityAt){
                  const penalAtAw = row.awayPenalityAt.split(',')
                  const penAtValuesAw = [...awayPenalityAt];
  
                  for(let index = 0; index < penalAtAw.length; index++){
                      const option = penalAtAw[index]
                      penAtValuesAw[index] = option;
                  }
                  setAwayPenalityAt(penAtValuesAw);
              }
  
              //update yellow card
              if(row.homeYellowCards) {
                  const yel = row.homeYellowCards.split(',')
                  const yelValues = [...homeYellowCard];
                  for(let index = 0; index < yel.length; index++){
                      const option = homePlayOption?.find(opt => opt.value === parseInt(yel[index]))
                      yelValues[index] = option;
                  }
                  setHomeYellowCard(yelValues);
                  setYellowCardNoHome(yel.length)
              }
            
              if(row.awayYellowCards){
                  const yelAw = row.awayYellowCards.split(',')
                  const yelValuesAw = [...awayYellowCard];
  
                  for(let index = 0; index < yelAw.length; index++){
                      const option = awayPlayOption?.find(opt => opt.value === parseInt(yelAw[index]))
                      yelValuesAw[index] = option;
                  }
                  setAwayYellowCard(yelValuesAw);
                  setYellowCardNoAway(yelAw.length)
              }
  
               //update yellow card at
               if(row.homeYellowCardsAt) {
                  const yelAt = row.homeYellowCardsAt.split(',')
                  const yelAtValues = [...homeYellowCardAt];
                  for(let index = 0; index < yelAt.length; index++){
                      const option = yelAt[index]
                      yelAtValues[index] = option;
                  }
                  setHomeYellowCardAt(yelAtValues);
          
              }
            
              if(row.awayYellowCardsAt){
                  const yelAtAw = row.awayYellowCardsAt.split(',')
                  const yelAtValuesAw = [...awayYellowCardAt];
  
                  for(let index = 0; index < yelAtAw.length; index++){
                      const option = yelAtAw[index]
                      yelAtValuesAw[index] = option;
                  }
                  setAwayYellowCardAt(yelAtValuesAw);
              }
  
               //update red card
               if(row.homeRedCards) {
                  const red = row.homeRedCards.split(',')
                  const redValues = [...homeRedCard];
                  for(let index = 0; index < red.length; index++){
                      const option = homePlayOption?.find(opt => opt.value === parseInt(red[index]))
                      redValues[index] = option;
                  }
                  setHomeRedCard(redValues);
                  setRedCardNoHome(red.length)
              }
            
              if(row.awayRedCards){
                  const redAw = row.awayRedCards.split(',')
                  const redValuesAw = [...awayRedCard];
  
                  for(let index = 0; index < redAw.length; index++){
                      const option = awayPlayOption?.find(opt => opt.value === parseInt(redAw[index]))
                      redValuesAw[index] = option;
                  }
                  setAwayRedCard(redValuesAw);
                  setRedCardNoAway(redAw.length)
              }
  
              //update red card at
              if(row.homeRedCardsAt) {
                  const redAt = row.homeRedCardsAt.split(',')
                  const redAtValues = [...homeRedCardAt];
                  for(let index = 0; index < redAt.length; index++){
                      const option = redAt[index]
                      redAtValues[index] = option;
                  }
                  setHomeRedCardAt(redAtValues);
          
              }
            
              if(row.awayRedCardsAt){
                  const redAtAw = row.awayRedCardsAt.split(',')
                  const redAtValuesAw = [...awayRedCardAt];
  
                  for(let index = 0; index < redAtAw.length; index++){
                      const option = redAtAw[index]
                      redAtValuesAw[index] = option;
                  }
                  setAwayRedCardAt(redAtValuesAw);
              }
  
  
               //update injury
               if(row.homeInjury) {
                  const inj = row.homeInjury.split(',')
                  const injValues = [...homeInjury];
                  for(let index = 0; index < inj.length; index++){
                      const option = homePlayOption?.find(opt => opt.value === parseInt(inj[index]))
                      injValues[index] = option;
                  }
                  setHomeInjury(injValues);
                  setInjuryNoHome(inj.length)
              }
            
              if(row.awayInjury){
                  const injAw = row.awayInjury.split(',')
                  const injValuesAw = [...awayInjury];
  
                  for(let index = 0; index < injAw.length; index++){
                      const option = awayPlayOption?.find(opt => opt.value === parseInt(injAw[index]))
                      injValuesAw[index] = option;
                  }
                  setAwayInjury(injValuesAw);
                  setInjuryNoAway(injAw.length)
              }
  
               //update injury at
               if(row.homeInjuryAt) {
                  const injAt = row.homeInjuryAt.split(',')
                  const injAtValues = [...homeInjuryAt];
                  for(let index = 0; index < injAt.length; index++){
                      const option = injAt[index]
                      injAtValues[index] = option;
                  }
                  setHomeInjuryAt(injAtValues);
          
              }
            
              if(row.awayInjuryAt){
                  const injAtAw = row.awayInjuryAt.split(',')
                  const injAtValuesAw = [...awayInjuryAt];
  
                  for(let index = 0; index < injAtAw.length; index++){
                      const option = injAtAw[index]
                      injAtValuesAw[index] = option;
                  }
                  setAwayInjuryAt(injAtValuesAw);
              }
            
              //update substitution in
              if(row.homeSubstitutionsIn) {
                  const sub = row.homeSubstitutionsIn.split(',')
                  const subValues = [...homeSubstitutionIn];
                  for(let index = 0; index < sub.length; index++){
                      const option = homePlayOption?.find(opt => opt.value === parseInt(sub[index]))
                      subValues[index] = option;
                  }
                  setHomeSubsitutionIn(subValues);
                  setSubstitutionNoHome(sub.length)
              }
            
              if(row.awaySubstitutionsIn){
                  const subAw = row.awaySubstitutionsIn.split(',')
                  const subValuesAw = [...awaySubstitutionIn];
  
                  for(let index = 0; index < subAw.length; index++){
                      const option = awayPlayOption?.find(opt => opt.value === parseInt(subAw[index]))
                      subValuesAw[index] = option;
                  }
                  setAwaySubsitutionIn(subValuesAw);
                  setSubstitutionNoAway(subAw.length)
              }
  
               //update substitution out
               if(row.homeSubstitutionsOut) {
                  const subOut = row.homeSubstitutionsOut.split(',')
                  const subOutValues = [...homeSubstitutionOut];
                  for(let index = 0; index < subOut.length; index++){
                      const option = homePlayOption?.find(opt => opt.value === parseInt(subOut[index]))
                      subOutValues[index] = option;
                  }
                  setHomeSubsitutionOut(subOutValues);
              }
            
              if(row.awaySubstitutionsOut){
                  const subOutAw = row.awaySubstitutionsOUt.split(',')
                  const subOutValuesAw = [...awaySubstitutionOut];
  
                  for(let index = 0; index < subOutAw.length; index++){
                      const option = awayPlayOption?.find(opt => opt.value === parseInt(subOutAw[index]))
                      subOutValuesAw[index] = option;
                  }
                  setAwaySubsitutionOut(subOutValuesAw);
                 
              }
  
               //update substitution at
               if(row.homeSubstitutionsAt) {
                  const subAt = row.homeSubstitutionsAt.split(',')
                  const subAtValues = [...homeSubstitutionAt];
                  for(let index = 0; index < subAt.length; index++){
                      const option = subAt[index]
                      subAtValues[index] = option;
                  }
                  setHomeSubstitutionAt(subAtValues);
          
              }
            
              if(row.awaySubstitutionsAt){
                  const subAtAw = row.awaySubstitutionsAt.split(',')
                  const subAtValuesAw = [...awaySubstitutionAt];
  
                  for(let index = 0; index < subAtAw.length; index++){
                      const option = subAtAw[index]
                      subAtValuesAw[index] = option;
                  }
                  setAwaySubstitutionAt(subAtValuesAw);
              }
        }
          
      }

    const deleteOptions = async (arg) =>{
       // const now = new Date();
       // const updatedAt = now.toISOString();
        if(arg === 'goal' && isHome === 'home'){
            setMatchField({ ...matchField, homeGoalScorers:null, homeGoalAssist:null,homeGoalScoredAt:null, homeGoalNo:0, homePoints: 0})
        } else if(arg === 'goal' && isAway === 'away'){
            setMatchField({ ...matchField, awayGoalScorers:null, awayGoalAssist:null, awayGoalScoredAt:null, awayGoalNo: 0, awayPoints: 0})
        } else if(arg === 'penality' && isHome === 'home'){
            setMatchField({ ...matchField, homePenality:null, homePenalityStatus:null,homePenalityAt:null})
        } else if(arg === 'penality' && isAway === 'away'){
            setMatchField({ ...matchField, awayPenality:null, awayPenalityStatus:null, awayPenalityAt:null})
        }  else if(arg === 'yellowCard' && isHome === 'home'){
            setMatchField({ ...matchField, homeYellowCards:null, homeYellowCardsAt:null})
        } else if(arg === 'yellowCard' && isAway === 'away'){
            setMatchField({ ...matchField,awayYellowCards:null,  awayYellowCardsAt:null})
        }  else if(arg === 'redCard' && isHome === 'home'){
            setMatchField({ ...matchField, homeRedCards:null,  homeRedCardsAt:null})
        }else if(arg === 'redCard' && isAway === 'away'){
            setMatchField({ ...matchField, awayRedCards:null, awayRedCardsAt:null})
        }  else if(arg === 'injury' && isHome === 'home'){
            setMatchField({ ...matchField, homeInjury:null, homeInjuryAt:null,})
        } else if(arg === 'injury' && isAway === 'away'){
            setMatchField({ ...matchField, awayInjury:null, awayInjuryAt:null})
        } else if(arg === 'substitution' && isHome === 'home'){
            setMatchField({ ...matchField, homeSubstitutionsIn:null, homeSubstitutionsOut:null, homeSubstitutionsAt:null})
        } else if(arg === 'substitution' && isAway === 'away'){
            setMatchField({ ...matchField, awaySubstitutionsIn:null, awaySubstitutionsOut:null, awaySubstitutionsAt:null})
        }

        // const addData = { ...matchField, updatedAt, updatedBy}
        // const res = await editMatchFacts(addData.matchFactId, addData)
        // if(!res.ok){
        //     setMasterErrors({...masterErrors, 'Error': res.error})
        // } else{
        //     setIsModalOpen(false)
        // }
    }
    
    const handleHomeGoalScorerChange = (index, selectedOption) =>{
        const newValues = [...homeGoalScorers];
        newValues[index] = selectedOption;
        setHomeGoalScorers(newValues);
    }
    const handleAwayGoalScorerChange = (index, selectedOption) =>{
        const newValues = [...awayGoalScorers];
        newValues[index] = selectedOption;
        setAwayGoalScorers(newValues);
    }

    const handleHomePenalityChange = (index, selectedOption) =>{
        const newValues = [...homePenality];
        newValues[index] = selectedOption;
        setHomePenality(newValues);
    }
    const handleAwayPenalityChange = (index, selectedOption) =>{
        const newValues = [...awayPenality];
        newValues[index] = selectedOption;
        setAwayPenality(newValues);
    }

    const handleHomePenalityStatusChange = (index, selectedOption) =>{
        const newValues = [...homePenalityStatus];
        newValues[index] = selectedOption;
        setHomePenalityStatus(newValues);
    }
    const handleAwayPenalityStatusChange = (index, selectedOption) =>{
        const newValues = [...awayPenalityStatus];
        newValues[index] = selectedOption;
        setAwayPenalityStatus(newValues);
    }

   
    const handleHomeGoalAssisterChange = (index, selectedOption) =>{
        const newValues = [...homeGoalAssisters];
        newValues[index] = selectedOption;
        setHomeGoalAssisters(newValues);
    }
    
    
    const handleAwayGoalAssisterChange = (index, selectedOption) =>{
        const newValues = [...awayGoalAssisters];
        newValues[index] = selectedOption;
        setAwayGoalAssisters(newValues);
    }

    const handleHomeYellowCardChange = (index, selectedOption) =>{
        const newValues = [...homeYellowCard];
        newValues[index] = selectedOption;
        setHomeYellowCard(newValues);
    }
    
    
    const handleHomeRedCardChange = (index, selectedOption) =>{
        const newValues = [...homeRedCard];
        newValues[index] = selectedOption;
        setHomeRedCard(newValues);
    }

    const handleAwayYellowCardChange = (index, selectedOption) =>{
        const newValues = [...awayYellowCard];
        newValues[index] = selectedOption;
        setAwayYellowCard(newValues);
    }
    
    
    const handleAwayRedCardChange = (index, selectedOption) =>{
        const newValues = [...awayRedCard];
        newValues[index] = selectedOption;
        setAwayRedCard(newValues);
    }
    const handleHomeInjuryChange = (index, selectedOption) =>{
        const newValues = [...homeInjury];
        newValues[index] = selectedOption;
        setHomeInjury(newValues);
    }
    const handleAwayInjuryChange = (index, selectedOption) =>{
        const newValues = [...awayInjury];
        newValues[index] = selectedOption;
        setAwayInjury(newValues);
    }

    const handleHomeSubstitutionInChange = (index, selectedOption) =>{
        const newValues = [...homeSubstitutionIn];
        newValues[index] = selectedOption;
        setHomeSubsitutionIn(newValues);
    }
    const handleHomeSubstitutionOutChange = (index, selectedOption) =>{
        const newValues = [...homeSubstitutionOut];
        newValues[index] = selectedOption;
        setHomeSubsitutionOut(newValues);
    }

    const handleAwaySubstitutionInChange = (index, selectedOption) =>{
        const newValues = [...awaySubstitutionIn];
        newValues[index] = selectedOption;
        setAwaySubsitutionIn(newValues);
    }
    const handleAwaySubstitutionOutChange = (index, selectedOption) =>{
        const newValues = [...awaySubstitutionOut];
        newValues[index] = selectedOption;
        setAwaySubsitutionOut(newValues);
    }
    
    


    const handleHomeGoalScoredAt = (index, e) =>{
        const newValues = [...homeGoalScoredAt];
        newValues[index] = e.target.value;
        setHomeGoalScoredAt(newValues);  
  
    }
    const handleAwayGoalScoredAt = (index, e) =>{
        const newValues = [...awayGoalScoredAt];
        newValues[index] = e.target.value;
        setAwayGoalScoredAt(newValues);
    }

    const handleHomePenalityAtChange = (index, e) =>{
        const newValues = [...homePenalityAt];
        newValues[index] = e.target.value;
        setHomePenalityAt(newValues);  
  
    }
    const handleAwayPenalityAtChange = (index, e) =>{
        const newValues = [...awayPenalityAt];
        newValues[index] = e.target.value;
        setAwayPenalityAt(newValues);  
    }

    const handleHomeYellowCardAtChange = (index, e) =>{
        const newValues = [...homeYellowCardAt];
        newValues[index] = e.target.value;
        setHomeYellowCardAt(newValues);  
    }
    const handleHomeRedCardAtChange = (index, e) =>{
        const newValues = [...homeRedCardAt];
        newValues[index] = e.target.value;
        setHomeRedCardAt(newValues);  
    }
    
    const handleAwayYellowCardAtChange = (index, e) =>{
        const newValues = [...awayYellowCardAt];
        newValues[index] = e.target.value;
        setAwayYellowCardAt(newValues);  
    }
    const handleAwayRedCardAtChange = (index, e) =>{
        const newValues = [...awayRedCardAt];
        newValues[index] = e.target.value;
        setAwayRedCardAt(newValues);  
    }

    const handleHomeInjuryAtChange = (index, e) =>{
        const newValues = [...homeInjuryAt];
        newValues[index] = e.target.value;
        setHomeInjuryAt(newValues);  
    }
    const handleAwayInjuryAtChange = (index, e) =>{
        const newValues = [...awayInjuryAt];
        newValues[index] = e.target.value;
        setAwayInjuryAt(newValues);  
    }

    const handleHomeSubstitutionAtChange = (index, e) =>{
        const newValues = [...homeSubstitutionAt];
        newValues[index] = e.target.value;
        setHomeSubstitutionAt(newValues);  
    }

    const handleAwaySubstitutionAtChange = (index, e) =>{
        const newValues = [...awaySubstitutionAt];
        newValues[index] = e.target.value;
        setAwaySubstitutionAt(newValues);  
    }
   
    
    //handle match field data change
    const handleMatchFieldChange = (e)=>{
        const {name, value} = e.target;
        setMatchField({ ...matchField, [name]: value})
    }

    const findTableNames = (tbl) =>{
        return tblNames.filter(f => f.name === tbl)
    } 

    //method to get select options based on table name, criteria
    const getDataFromTable = async (tblName, criteria) => {
        try{
                const tbl = findTableNames(tblName) 
                const response = await tbl[0]?.method(criteria);
                return response;
        } catch(err){
            console.log('Error fetching rowData: ', err)
        }
    };
     

    

    let HomePlayersOptions = () => {
        const opts = homePlayers;
        const formatedOptions = opts.map(opt => ({
            value: opt.staffId,
            label: `${opt.shirtNbr} | ${opt.staffName}`
        }))
       return formatedOptions;
    };
    
    
  

    let AwayPlayersOptions = () => {
        const opts = awayPlayers;
        const formatedOptions = opts.map(opt => ({
            value: opt.staffId,
            label: `${opt.shirtNbr} | ${opt.staffName}`
        }))
       return formatedOptions;
    } 

    
    
    
    const closeModal = () =>{
        setIsModalOpen(false)
    }

    
   
const handleMatchFactType = (arg, show) =>{
    if(arg === 'home'){
        setIsHome('home');
        setIsAway('')
    } else{
        setIsHome('');
        setIsAway('away')
    }

    if(show === 'goal'){
        setModalContentShow('goal');
    } else if(show === 'penality'){
        setModalContentShow('penality');
    } else if(show === 'yellow'){
        setModalContentShow('yellow');
    } else if(show === 'red'){
        setModalContentShow('red');
    } else if(show === 'injury'){
        setModalContentShow('injury');
    } else if(show === 'substitution'){
        setModalContentShow('substitution');
    } else if(show === 'formation'){
        setModalContentShow('formation');

    }

    
    setIsModalOpen(true) ; 
}

function findLineUps(plyr){
    const LineUps = [];
    for(let i = 1; i < 6; i++){
        const ln = <div className="same-line">
                        <div className="player-one">
                            {
                                plyr.hasOwnProperty(`${i}1D`) &&
                                <span className='player-down'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 1, 'D')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}1D`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}1D`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}1D`][0].playerPhoto? `${backEndUrl}${plyr[`${i}1D`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}1D`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}1D`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}1D`) && <span className="player-down drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 1, 'D')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }

                            {
                                plyr.hasOwnProperty(`${i}1S`) &&
                                <span className='player-along'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 1, 'S')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}1S`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}1S`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}1S`][0].playerPhoto? `${backEndUrl}${plyr[`${i}1S`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}1S`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}1S`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}1S`) && <span className="player-along drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 1, 'S')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }

                            {
                                plyr.hasOwnProperty(`${i}1U`) &&
                                <span className='player-up'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 1, 'U')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}1U`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}1U`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}1U`][0].playerPhoto? `${backEndUrl}${plyr[`${i}1U`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}1U`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}1U`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}1U`) && <span className="player-up drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 1, 'U')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }
                        </div>
                        <div className="player-two">
                        {
                                plyr.hasOwnProperty(`${i}2D`) &&
                                <span className='player-down'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 2, 'D')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}2D`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}2D`][0].staffId,'best-11')}  >
                                        <img  src={plyr[`${i}2D`][0].playerPhoto? `${backEndUrl}${plyr[`${i}2D`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}2D`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}2D`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}2D`) && <span className="player-down drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 2, 'D')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }

                            {
                                plyr.hasOwnProperty(`${i}2S`) &&
                                <span className='player-along'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 2, 'S')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}2S`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}2S`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}2S`][0].playerPhoto? `${backEndUrl}${plyr[`${i}2S`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}2S`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}2S`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}2S`) && <span className="player-along drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 2, 'S')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }

                            {
                                plyr.hasOwnProperty(`${i}2U`) &&
                                <span className='player-up'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 2, 'U')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}2U`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}2U`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}2U`][0].playerPhoto? `${backEndUrl}${plyr[`${i}2U`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}2U`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}2U`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}2U`) && <span className="player-up drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 2, 'U')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }
                        </div>
                        <div className="player-three">
                        {
                                plyr.hasOwnProperty(`${i}3D`) &&
                                <span className='player-down'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 3, 'D')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}3D`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}3D`][0].staffId,'best-11')}  >
                                        <img  src={plyr[`${i}3D`][0].playerPhoto? `${backEndUrl}${plyr[`${i}3D`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}3D`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}3D`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}3D`) && <span className="player-down drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 3, 'D')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }

                            {
                                plyr.hasOwnProperty(`${i}3S`) &&
                                <span className='player-along'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 3, 'S')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}3S`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}3S`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}3S`][0].playerPhoto? `${backEndUrl}${plyr[`${i}3S`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}3S`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}3S`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}3S`) && <span className="player-along drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 3, 'S')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }

                            {
                                plyr.hasOwnProperty(`${i}3U`) &&
                                <span className='player-up'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 3, 'U')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}3U`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}3U`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}3U`][0].playerPhoto? `${backEndUrl}${plyr[`${i}3U`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}3U`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}3U`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}3U`) && <span className="player-up drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 3, 'U')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }
                        </div>
                        <div className="player-four">
                        {
                                plyr.hasOwnProperty(`${i}4D`) &&
                                <span className='player-down'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 4, 'D')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}4D`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}4D`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}4D`][0].playerPhoto? `${backEndUrl}${plyr[`${i}4D`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}4D`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}4D`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}4D`) && <span className="player-down drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 4, 'D')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }

                            {
                                plyr.hasOwnProperty(`${i}4S`) &&
                                <span className='player-along'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 4, 'S')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}4S`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}4S`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}4S`][0].playerPhoto? `${backEndUrl}${plyr[`${i}4S`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}4S`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}4S`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}4S`) && <span className="player-along drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 4, 'S')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }

                            {
                                plyr.hasOwnProperty(`${i}4U`) &&
                                <span className='player-up'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 4, 'U')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}4U`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}4U`][0].staffId,'best-11')}  >
                                        <img  src={plyr[`${i}4U`][0].playerPhoto? `${backEndUrl}${plyr[`${i}4U`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}4U`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}4U`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}4U`) && <span className="player-up drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 4, 'U')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }


                        </div>
                        <div className="player-five">
                        {
                                plyr.hasOwnProperty(`${i}5D`) &&
                                <span className='player-down'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 5, 'D')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}5D`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}5D`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}5D`][0].playerPhoto? `${backEndUrl}${plyr[`${i}5D`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}5D`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}5D`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}5D`) && <span className="player-down drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 5, 'D')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }

                            {
                                plyr.hasOwnProperty(`${i}5S`) &&
                                <span className='player-along'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 5, 'S')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}5S`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}5S`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}5S`][0].playerPhoto? `${backEndUrl}${plyr[`${i}5S`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}5S`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}5S`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}5S`) && <span className="player-along drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 5, 'S')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }

                            {
                                plyr.hasOwnProperty(`${i}5U`) &&
                                <span className='player-up'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 5, 'U')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}5U`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}5U`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}5U`][0].playerPhoto? `${backEndUrl}${plyr[`${i}5U`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}5U`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}5U`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}5U`) && <span className="player-up drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 5, 'U')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }

                       </div>
                        <div className="player-six">
                        {
                                plyr.hasOwnProperty(`${i}6D`) &&
                                <span className='player-down'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 6, 'D')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}6D`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}6D`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}6D`][0].playerPhoto? `${backEndUrl}${plyr[`${i}6D`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}6D`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}6D`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}6D`) && <span className="player-down drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 6, 'D')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }

                            {
                                plyr.hasOwnProperty(`${i}6S`) &&
                                <span className='player-along'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 6, 'S')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}6S`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}6S`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}6S`][0].playerPhoto? `${backEndUrl}${plyr[`${i}6S`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}6S`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}6S`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}6S`) && <span className="player-along drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 6, 'S')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }

                            {
                                plyr.hasOwnProperty(`${i}6U`) &&
                                <span className='player-up'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 6, 'U')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}6U`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}6U`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}6U`][0].playerPhoto? `${backEndUrl}${plyr[`${i}6U`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}6U`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}6U`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}6U`) && <span className="player-up drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 6, 'U')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }
                       </div>
                        <div className="player-seven">
                        {
                                plyr.hasOwnProperty(`${i}7D`) &&
                                <span className='player-down'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 7, 'D')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}7D`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}7D`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}7D`][0].playerPhoto? `${backEndUrl}${plyr[`${i}7D`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}7D`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}7D`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}7D`) && <span className="player-down drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 7, 'D')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }

                            {
                                plyr.hasOwnProperty(`${i}7S`) &&
                                <span className='player-along'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 7, 'S')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}7S`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}7S`][0].staffId, 'best-11')}  >
                                        <img  src={plyr[`${i}7S`][0].playerPhoto? `${backEndUrl}${plyr[`${i}7S`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}7S`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}7S`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}7S`) && <span className="player-along drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 7, 'S')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }

                            {
                                plyr.hasOwnProperty(`${i}7U`) &&
                                <span className='player-up'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 7, 'U')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                    <div className="players-draggable" id={plyr[`${i}7U`][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr[`${i}7U`][0].staffId,'best-11')}  >
                                        <img  src={plyr[`${i}7U`][0].playerPhoto? `${backEndUrl}${plyr[`${i}7U`][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                        <p className="player-thirtNo resized-font">{plyr[`${i}7U`][0].shirtNbr}</p>
                                        <p className="Player-name resized-font">{plyr[`${i}7U`][0].staffName}</p>
                                    </div>
                                </span>
                            }
                            {
                                !plyr.hasOwnProperty(`${i}7U`) && <span className="player-up drop"  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, i, 7, 'U')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>

                            }
                        </div>      
                </div>

                LineUps.push(ln);
    }

    return LineUps;
}

function findSubstitution(plyr){

      var subst = <div>
        {
            plyr.map(p =>(
                <li className="col-1-2" key={p[0].staffId} draggable onDragStart={(e) => handleDragStart(e, p[0].staffId, 'substitution')}>
                    <div className="players-draggable resized-div" id={p[0].staffId} draggable onDragStart={(e) => handleDragStart(e, p[0].staffId, 'substitution')}>
                        <img src={p[0].playerPhoto? `${backEndUrl}${p[0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image-sub" />
                        <p className="player-thirtNo resized-font-sub">{p[0].shirtNbr}</p>
                        <p className="Player-name resized-font-sub">{p[0].staffName}</p>
                    </div>
                </li>
            ))
                                  
        }
      
    </div>
    
   return subst 
}



function findContent(){
    var cont = '';
    if(modalContentShow === 'goal'){
        if(isHome === 'home' && matchField?.fixtureId){
            //const club = findMatchDetail(selectedMatch.value)[0].home;
            const club = matchField?.fixtureId.home;
            cont = <form onSubmit={handleHomeGoals}>
                    <table className='modal-table'>
                        <thead>
                            <tr><td colSpan={4} className='modal-title'>{club}'s Goal Detail</td></tr>
                            <tr><td>{isEdit && <button className="delete-all" onClick={() => deleteOptions('goal')}>Delete All</button>}</td></tr>
                            <tr>
                                <th></th>
                                <th>Scored By</th>
                                <th>Assisted By</th>
                                <th>at(minutes)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {homeGoalScoredAtFields.map((_, index) =>(
        
                                    <tr  key={index}>
                                        <th><span className='icon-football'><i className='bx bx-football'></i></span></th>
                                        <td><Select name={`HomeGoalScorer-${index + 1}`} id= {`HomeGoalScorer-${index + 1}`} value={homeGoalScorers[index]} onChange={(e) => handleHomeGoalScorerChange(index, e)} options={homePlayersOption} placeholder="Scored By..." /></td>
                                        <td><Select name={`HomeGoalAssister-${index + 1}`} id={`HomeGoalAssister-${index + 1}`} value={homeGoalAssisters[index]} onChange={(e) => handleHomeGoalAssisterChange(index, e)} options={homePlayersOption} placeholder="Assisted By..." /></td>
                                        <td ><input style={{height: '37px', width: '100%', border: '1px solid #b8c0c0', outline: 'none', borderRadius: '7%' }} type="number" name={`HomeGoalScoredAt-${index + 1}`} id={`HomeGoalScoredAt-${index + 1}`} value={homeGoalScoredAt[index] || ''}   onChange={(e) => handleHomeGoalScoredAt(index, e)} /></td>
                                    </tr>
                                    
                            )) }
                            
                        
                        </tbody>
    
                    
                    
                    </table>
                    <div className="goalScorersDetail">
                        <button type="submit" name="HomeGoalScorersDetail" >Save</button>
                    </div>
                    
                </form>
    
        } else if(isAway === 'away' && matchField?.fixtureId){
            const club = matchField?.fixtureId.away;
            cont = <form onSubmit={handleAwayGoals}>
                    <table className='modal-table'>
                        <thead>
                            <tr><td colSpan={4} className='modal-title'>{club}'s Goal Detail</td></tr>
                            <tr><td>{isEdit && <button className="delete-all" onClick={() => deleteOptions('goal')}>Delete All</button>}</td></tr>

                            <tr>
                                <th></th>
                                <th>Scored By</th>
                                <th>Assisted By</th>
                                <th>at(minutes)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {awayGoalScoredAtFields.map((_, index) =>(
        
                                    <tr  key={index}>
                                        <th><span className='icon-football'><i className='bx bx-football'></i></span></th>
                                        <td><Select name={`AwayGoalScorer-${index + 1}`} id= {`AwayGoalScorer-${index + 1}`} value={awayGoalScorers[index]} onChange={(e) => handleAwayGoalScorerChange(index, e)} options={awayPlayersOption} placeholder="Scored By..." /></td>
                                        <td><Select name={`AwayGoalAssister-${index + 1}`} id={`AwayGoalAssister-${index + 1}`} value={awayGoalAssisters[index]} onChange={(e) => handleAwayGoalAssisterChange(index, e)} options={awayPlayersOption} placeholder="Assisted By..." /></td>
                                        <td ><input style={{height: '37px', width:'100%', border: '1px solid #b8c0c0', outline: 'none', borderRadius: '7%' }} type="number" name={`AwayGoalScoredAt-${index + 1}`} id={`AwayGoalScoredAt-${index + 1}`} value={awayGoalScoredAt[index] || ''}   onChange={(e) => handleAwayGoalScoredAt(index, e)} /></td>
                                    </tr>
                                    
                            )) }
                            
                        
                        </tbody>
    
                    
                    
                    </table>
                    <div className="goalScorersDetail">
                        <button type="submit" name="AwayGoalScorersDetail" >Save</button>
                    </div>
                    
                </form>
        } else{
            cont = '';
        }
       // setModalType('md');
        return cont;
    } else if(modalContentShow === 'penality'){
        if(isHome === 'home' && matchField?.fixtureId){
            const club = matchField?.fixtureId.home;
            cont = <form onSubmit={handleHomePenalitys}>
                    <table className='modal-table'>
                        <thead>
                            <tr><td colSpan={4} className='modal-title'>{club}'s Penalities Detail</td></tr>
                            <tr><td>{isEdit && <button className="delete-all" onClick={() => deleteOptions('penality')}>Delete All</button>}</td></tr>

                            <tr>
                                <th></th>
                                <th>Shot By</th>
                                <th>At(minutes)</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {homePenalityFields.map((_, index) =>(
        
                                    <tr  key={index}>
                                        <th><span className='icon-football'><i className='bx bx-football'></i></span></th>
                                        <td><Select name={`HomePenalityShoter-${index + 1}`} id= {`HomePenalityShoter-${index + 1}`} value={homePenality[index]} onChange={(e) => handleHomePenalityChange(index, e)} options={HomePlayersOptions()} placeholder="Shot By..." /></td>
                                        <td><input style={{height: '37px', width: '100%', border: '1px solid #b8c0c0', outline: 'none', borderRadius: '7%' }} type="number" name={`HomePenalityAt-${index + 1}`} id={`HomePenalityAt-${index + 1}`} value={homePenalityAt[index] || ''}   onChange={(e) => handleHomePenalityAtChange(index, e)} /></td>
                                        <td><Select name={`HomePenalityStatus-${index + 1}`} id= {`HomePenalityStatus-${index + 1}`} value={homePenalityStatus[index]} onChange={(e) => handleHomePenalityStatusChange(index, e)} options={PenalityOptions} placeholder="Status..." /></td>
                                    </tr>
                                    
                            )) }
                            
                        
                        </tbody>
    
                    
                    
                    </table>
                    <div className="PenalityDetail">
                        <button type="submit" name="HomePenalityDetail" >Save</button>
                    </div>
                    
                </form>
    
        } else if(isAway === 'away' && matchField?.fixtureId ){
            const club = matchField?.fixtureId.away;
            cont = <form onSubmit={handleAwayPenalitys}>
                    <table className='modal-table'>
                        <thead>
                            <tr><td colSpan={4} className='modal-title'>{club}'s Penalities Detail</td></tr>
                            <tr><td>{isEdit && <button className="delete-all" onClick={() => deleteOptions('penality')}>Delete All</button>}</td></tr>

                            <tr>
                                <th></th>
                                <th>Shot By</th>
                                <th>At(minutes)</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {awayPenalityFields.map((_, index) =>(
        
                                    <tr  key={index}>
                                        <th><span className='icon-football'><i className='bx bx-football'></i></span></th>
                                        <td><Select name={`AwayPenalityShoter-${index + 1}`} id= {`AwayPenalityShoter-${index + 1}`} value={awayPenality[index]} onChange={(e) => handleAwayPenalityChange(index, e)} options={AwayPlayersOptions()} placeholder="Shot By..." /></td>
                                        <td><input style={{height: '37px', width: '100%', border: '1px solid #b8c0c0', outline: 'none', borderRadius: '7%' }} type="number" name={`AwayPenalityAt-${index + 1}`} id={`AwayPenalityAt-${index + 1}`} value={awayPenalityAt[index] || ''}   onChange={(e) => handleAwayPenalityAtChange(index, e)} /></td>
                                        <td><Select name={`AwayPenalityStatus-${index + 1}`} id= {`AwayPenalityStatus-${index + 1}`} value={awayPenalityStatus[index]} onChange={(e) => handleAwayPenalityStatusChange(index, e)} options={PenalityOptions} placeholder="Status..." /></td>
                                    </tr>
                                    
                            )) }
                            
                        
                        </tbody>
    
                    
                    
                    </table>
                    <div className="PenalityDetail">
                        <button type="submit" name="AwayPenalityDetail" >Save</button>
                    </div>
                    
                </form>
        } else{
            cont = '';
        }
        
       // setModalType('md');
        return cont;
    } else if(modalContentShow === 'yellow'){
        if(isHome === 'home' && matchField?.fixtureId){
            const club = matchField?.fixtureId.home;
            cont = <form onSubmit={handleHomeYellowCard}>
                    <table className='modal-table'>
                        <thead>
                            <tr><td colSpan={4} className='modal-title'>{club}'s Yellow Cards Detail</td></tr>
                            <tr><td>{isEdit && <button className="delete-all" onClick={() => deleteOptions('yellowCard')}>Delete All</button>}</td></tr>
                            <tr>
                                <th></th>
                                <th>Given to</th>
                                <th>At(minutes)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {homeYellowCardFields.map((_, index) =>(
        
                                    <tr  key={index}>
                                        <th><span className='icon-yellow-card'><i className='bx bx-book'></i></span></th>
                                        <td><Select name={`HomeYellowCard-${index + 1}`} id= {`HomeYellowCard-${index + 1}`} value={homeYellowCard[index]} onChange={(e) => handleHomeYellowCardChange(index, e)} options={HomePlayersOptions()} placeholder="Yellow Card to..." /></td>
                                        <td><input style={{height: '37px', width: '100%', border: '1px solid #b8c0c0', outline: 'none', borderRadius: '7%' }} type="number" name={`HomeYellowCardAt-${index + 1}`} id={`HomeYellowCardAt-${index + 1}`} value={homeYellowCardAt[index] || ''}   onChange={(e) => handleHomeYellowCardAtChange(index, e)} /></td>
                                    </tr>
                                    
                            )) }
                            
                        
                        </tbody>
    
                    
                    
                    </table>
                    <div className="YellowCardDetail">
                        <button type="submit" name="HomeYellowCardDetail" >Save</button>
                    </div>
                    
                </form>
    
        } else if(isAway === 'away' && matchField?.fixtureId){
            const club = matchField?.fixtureId.away;
            cont = <form onSubmit={handleAwayYellowCard}>
                    <table className='modal-table'>
                        <thead>
                            <tr><td colSpan={4} className='modal-title'>{club}'s Yellow Cards Detail</td></tr>
                            <tr><td>{isEdit && <button className="delete-all" onClick={() => deleteOptions('yellowCard')}>Delete All</button>}</td></tr>
                            <tr>
                                <th></th>
                                <th>Given to</th>
                                <th>At(minutes)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {awayYellowCardFields.map((_, index) =>(
        
                                    <tr  key={index}>
                                        <th><span className='icon-yellow-card'><i className='bx bx-book'></i></span></th>
                                        <td><Select name={`AwayYellowCard-${index + 1}`} id= {`AwayYellowCard-${index + 1}`} value={awayYellowCard[index]} onChange={(e) => handleAwayYellowCardChange(index, e)} options={AwayPlayersOptions()} placeholder="Yellow Card to..." /></td>
                                        <td><input style={{height: '37px', width: '100%', border: '1px solid #b8c0c0', outline: 'none', borderRadius: '7%' }} type="number" name={`AwayYellowCardAt-${index + 1}`} id={`AwayYellowCardAt-${index + 1}`} value={awayYellowCardAt[index] || ''}   onChange={(e) => handleAwayYellowCardAtChange(index, e)} /></td>
                                    </tr>
                                    
                            )) }
                            
                        
                        </tbody>
    
                    
                    
                    </table>
                    <div className="YellowCardDetail">
                        <button type="submit" name="AwayYellowCardDetail" >Save</button>
                    </div>
                    
                </form>
        } else{
            cont = '';
        }
        
       // setModalType('md');
        return cont;
    } else if(modalContentShow === 'red'){
        if(isHome === 'home' && matchField?.fixtureId){
            const club = matchField?.fixtureId.home;
            cont = <form onSubmit={handleHomeRedCard}>
                    <table className='modal-table'>
                        <thead>
                            <tr><td colSpan={4} className='modal-title'>{club}'s Red Cards Detail</td></tr>
                            <tr><td>{isEdit && <button className="delete-all" onClick={() => deleteOptions('redCard')}>Delete All</button>}</td></tr>
                            <tr>
                                <th></th>
                                <th>Given to</th>
                                <th>At(minutes)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {homeRedCardFields.map((_, index) =>(
        
                                    <tr  key={index}>
                                        <th><span className='icon-red-card'><i className='bx bx-book'></i></span></th>
                                        <td><Select name={`HomeRedCard-${index + 1}`} id= {`HomeRedCard-${index + 1}`} value={homeRedCard[index]} onChange={(e) => handleHomeRedCardChange(index, e)} options={HomePlayersOptions()} placeholder="Red Card to..." /></td>
                                        <td><input style={{height: '37px', width: '100%', border: '1px solid #b8c0c0', outline: 'none', borderRadius: '7%' }} type="number" name={`HomeRedCardAt-${index + 1}`} id={`HomeRedCardAt-${index + 1}`} value={homeRedCardAt[index] || ''}   onChange={(e) => handleHomeRedCardAtChange(index, e)} /></td>
                                    </tr>
                                    
                            )) }
                            
                        
                        </tbody>
    
                    
                    
                    </table>
                    <div className="RedCardDetail">
                        <button type="submit" name="HomeRedCardDetail" >Save</button>
                    </div>
                    
                </form>
    
        } else if(isAway === 'away' && matchField?.fixtureId){
            const club = matchField?.fixtureId.away;
            cont = <form onSubmit={handleAwayRedCard}>
                    <table className='modal-table'>
                        <thead>
                            <tr><td colSpan={4} className='modal-title'>{club}'s Red Cards Detail</td></tr>
                            <tr><td>{isEdit && <button className="delete-all" onClick={() => deleteOptions('redCard')}>Delete All</button>}</td></tr>
                            <tr>
                                <th></th>
                                <th>Given to</th>
                                <th>At(minutes)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {awayRedCardFields.map((_, index) =>(
        
                                    <tr  key={index}>
                                        <th><span className='icon-red-card'><i className='bx bx-book'></i></span></th>
                                        <td><Select name={`AwayRedCard-${index + 1}`} id= {`AwayRedCard-${index + 1}`} value={awayRedCard[index]} onChange={(e) => handleAwayRedCardChange(index, e)} options={AwayPlayersOptions()} placeholder="Red Card to..." /></td>
                                        <td><input style={{height: '37px', width: '100%', border: '1px solid #b8c0c0', outline: 'none', borderRadius: '7%' }} type="number" name={`AwayRedCardAt-${index + 1}`} id={`AwayRedCardAt-${index + 1}`} value={awayRedCardAt[index] || ''}   onChange={(e) => handleAwayRedCardAtChange(index, e)} /></td>
                                    </tr>
                                    
                            )) }
                            
                        
                        </tbody>
    
                    
                    
                    </table>
                    <div className="RedCardDetail">
                        <button type="submit" name="AwayRedCardDetail" >Save</button>
                    </div>
                    
                </form>
        } else{
            cont = '';
        }
        
       // setModalType('md');
        return cont;
    } else if(modalContentShow === 'injury'){
        if(isHome === 'home' && matchField?.fixtureId){
            const club = matchField?.fixtureId.home;
            cont = <form onSubmit={handleHomeInjury}>
                    <table className='modal-table'>
                        <thead>
                            <tr><td colSpan={4} className='modal-title'>{club}'s Injuries Detail</td></tr>
                            <tr><td>{isEdit && <button className="delete-all" onClick={() => deleteOptions('injury')}>Delete All</button>}</td></tr>
                            <tr>
                                <th></th>
                                <th>Injured</th>
                                <th>At(minutes)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {homeInjuryFields.map((_, index) =>(
        
                                    <tr  key={index}>
                                        <th><span className='icon-injury'><i className='bx bx-handicap'></i></span></th>
                                        <td><Select name={`HomeInjury-${index + 1}`} id= {`HomeInjury-${index + 1}`} value={homeInjury[index]} onChange={(e) => handleHomeInjuryChange(index, e)} options={HomePlayersOptions()} placeholder="Injured Player..." /></td>
                                        <td><input style={{height: '37px', width: '100%', border: '1px solid #b8c0c0', outline: 'none', borderRadius: '7%' }} type="number" name={`HomeInjuriedAt-${index + 1}`} id={`HomeInjuriedAt-${index + 1}`} value={homeInjuryAt[index] || ''}   onChange={(e) => handleHomeInjuryAtChange(index, e)} /></td>
                                    </tr>
                                    
                            )) }
                            
                        
                        </tbody>
    
                    
                    
                    </table>
                    <div className="InjuryDetail">
                        <button type="submit" name="HomeInjuryDetail" >Save</button>
                    </div>
                    
                </form>
    
        } else if(isAway === 'away' && matchField?.fixtureId){
            const club = matchField?.fixtureId.away;
            cont = <form onSubmit={handleAwayInjury}>
                    <table className='modal-table'>
                        <thead>
                            <tr><td colSpan={4} className='modal-title'>{club}'s Injuries Detail</td></tr>
                            <tr><td>{isEdit && <button className="delete-all" onClick={() => deleteOptions('injury')}>Delete All</button>}</td></tr>
                            <tr>
                                <th></th>
                                <th>Injured</th>
                                <th>At(minutes)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {awayInjuryFields.map((_, index) =>(
        
                                    <tr  key={index}>
                                        <th><span className='icon-injury'><i className='bx bx-handicap'></i></span></th>
                                        <td><Select name={`AwayInjuried-${index + 1}`} id= {`AwayInjuried-${index + 1}`} value={awayInjury[index]} onChange={(e) => handleAwayInjuryChange(index, e)} options={AwayPlayersOptions()} placeholder="Injuried Player..." /></td>
                                        <td><input style={{height: '37px', width: '100%', border: '1px solid #b8c0c0', outline: 'none', borderRadius: '7%' }} type="number" name={`AwayInjuriedAt-${index + 1}`} id={`AwayInjuriedAt-${index + 1}`} value={awayInjuryAt[index] || ''}   onChange={(e) => handleAwayInjuryAtChange(index, e)} /></td>
                                    </tr>
                                    
                            )) }
                            
                        
                        </tbody>
    
                    
                    
                    </table>
                    <div className="InjuryDetail">
                        <button type="submit" name="AwayInjuryDetail" >Save</button>
                    </div>
                    
                </form>
        } else{
            cont = '';
        }
        
        //setModalType('md');
        return cont;
    } else if(modalContentShow === 'substitution'){
        if(isHome === 'home' && matchField?.fixtureId){
            const club = matchField?.fixtureId.home;
            cont = <form onSubmit={handleHomeSubstution}>
                    <table className='modal-table'>
                        <thead>
                            
                            <tr><td colSpan={4} className='modal-title'>{club}'s Substitutions Detail</td></tr>
                            <tr><td>{isEdit && <button className="delete-all" onClick={() => deleteOptions('substitution')}>Delete All</button>}</td></tr>

                            <tr>
                                <th></th>
                                <th>Player In</th>
                                <th>Player Out</th>
                                <th>at(minutes)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {homeSubstitutionFields.map((_, index) =>(
        
                                    <tr  key={index}>
                                        <th><span className='icon-substitution'><i className='bx bx-caret-down'></i><i className='bx bx-caret-up'></i></span></th>
                                        <td><Select name={`HomePlayerIn-${index + 1}`} id= {`HomePlayerIn-${index + 1}`} value={homeSubstitutionIn[index]} onChange={(e) => handleHomeSubstitutionInChange(index, e)} options={HomePlayersOptions()} placeholder="Player In..." /></td>
                                        <td><Select name={`HomePlayerOut-${index + 1}`} id={`HomePlayerOut-${index + 1}`} value={homeSubstitutionOut[index]} onChange={(e) => handleHomeSubstitutionOutChange(index, e)} options={HomePlayersOptions()} placeholder="Player Out..." /></td>
                                        <td ><input style={{height: '37px', width: '100%', border: '1px solid #b8c0c0', outline: 'none', borderRadius: '7%' }} type="number" name={`HomeSubstitutionAt-${index + 1}`} id={`HomeSubstitutionAt-${index + 1}`} value={homeSubstitutionAt[index] || ''}   onChange={(e) => handleHomeSubstitutionAtChange(index, e)} /></td>
                                    </tr>
                                    
                            )) }
                            
                        
                        </tbody>
    
                    
                    
                    </table>
                    <div className="SubstitutionDetail">
                        <button type="submit" name="HomeSubstitutionDetail" >Save</button>
                    </div>
                    
                </form>
    
        } else if(isAway === 'away' && matchField?.fixtureId){
            const club = matchField?.fixtureId.away;
            cont = <form onSubmit={handleAwaySubstution}>
                    <table className='modal-table'>
                        <thead>
                            
                            <tr><td colSpan={4} className='modal-title'>{club}'s Substitutions Detail</td></tr>
                            <tr><td>{isEdit && <button className="delete-all" onClick={() => deleteOptions('substitution')}>Delete All</button>}</td></tr>
                            <tr>
                                <th></th>
                                <th>Player In</th>
                                <th>Player Out</th>
                                <th>at(minutes)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {awaySubstitutionFields.map((_, index) =>(
        
                                    <tr  key={index}>
                                        <th><span className='icon-substitution'><i className='bx bx-caret-down'></i><i className='bx bx-caret-up'></i></span></th>
                                        <td><Select name={`AwayPlayerIn-${index + 1}`} id= {`AwayPlayerIn-${index + 1}`} value={awaySubstitutionIn[index]} onChange={(e) => handleAwaySubstitutionInChange(index, e)} options={AwayPlayersOptions()} placeholder="Player In..." /></td>
                                        <td><Select name={`AwayPlayerOut-${index + 1}`} id={`AwayPlayerOut-${index + 1}`} value={awaySubstitutionOut[index]} onChange={(e) => handleAwaySubstitutionOutChange(index, e)} options={AwayPlayersOptions()} placeholder="Player Out..." /></td>
                                        <td ><input style={{height: '37px', width: '100%', border: '1px solid #b8c0c0', outline: 'none', borderRadius: '7%' }} type="number" name={`AwaySubstitutionAt-${index + 1}`} id={`AwaySubstitutionAt-${index + 1}`} value={awaySubstitutionAt[index] || ''}   onChange={(e) => handleAwaySubstitutionAtChange(index, e)} /></td>
                                    </tr>
                                    
                            )) }
                            
                        
                        </tbody>
    
                    
                    
                    </table>
                    <div className="SubstitutionDetail">
                        <button type="submit" name="AwaySubstitutionDetail" >Save</button>
                    </div>
                    
                </form>
        } else{
            cont = '';
        }
        //setModalType('md');
        return cont;
    } else if(modalContentShow === 'formation'){
        if(isHome === 'home' && matchField?.fixtureId){
    
                const plyr = { ...homeFormation } 
                const subst = []
            
                for(const key in plyr){
                   plyr[key] = findPlayer('home',parseInt(homeFormation[key]))
                   
                }
           
                for(const sb in homeSubstitutionAll){
                   subst.push(findPlayer('home',parseInt(sb)))   
                }
                cont = <form onSubmit={handleHomeFormation}>
                            <div className="formation-analysis">
                                <ul className="row">
                                    <li className="col-1-5">
                                            <ul className="row">
                                                <div className="players-analysis" onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, 'back', 0)}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                                    {homePlayersTemp.map((p) => (
                                                       !homeSubstitutionAll.hasOwnProperty(p.staffId)  && notInDictionary(homeFormation, p.staffId) &&
                                                        <li className="col-1-2" key={p.staffId}  >
                                                            <div className="players-draggable" id={p.staffId} draggable onDragStart={(e)=>handleDragStart(e, p.staffId, 'players')}  >
                                                                    <img  src={p.playerPhoto? `${backEndUrl}${p.playerPhoto}` : person} alt="person" height='60px' width='60px' />
                                                                    <p className="player-thirtNo">{p.shirtNbr}</p>
                                                                    <p className="Player-name">{p.staffName}</p>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </div>
                                            </ul>
                                    </li>
                                    <li className="col-2-5" >
                                        <div className="linueup-analysis">
                                                <div className='best-11-analysis'>
                                                    <div className='penality-aria-analysis'>
                                                        <div className='goal-post-analysis'>
                                                            <p></p>
                                                        </div>
                                                        <p className='penality-dot-analysis'></p>
                                                        {
                                                            plyr.hasOwnProperty('000') &&
                                                            <span className='goal-keepper-analysis drop'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, 0, 0, 0)}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                                                <div className="players-draggable" id={plyr['000'][0]?.staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr['000'][0]?.staffId, 'best-11')}  >
                                                                    <img  src={plyr['000'][0]?.playerPhoto? `${backEndUrl}${plyr['000'][0].playerPhoto}` :person} alt="person" height='60px' width='60px' className="resized-image" />
                                                                    <p className="player-thirtNo resized-font">{plyr['000'][0]?.shirtNbr}</p>
                                                                    <p className="Player-name resized-font">{plyr['000'][0]?.staffName}</p>
                                                                </div>
                                                            </span>
                                                        }
                                                        {
                                                            !plyr.hasOwnProperty('000') && <span className='goal-keepper-analysis drop'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, 0, 0, 0)}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>
                                                        }

                                                    </div>
                                                    <div className='penality-header-analysis'>
                                                    </div>
                                                    <div className='half-space-analysis'>
                                                        <p className='ball-circle-analysis'></p>
                                                    </div>
                                                    <div className='best-10-analysis'>
                                                        {findLineUps(plyr)}
                                                    </div>
                                                    <div className='penality-header-up-analysis'>
                                                    </div> 
                                                    <div className='penality-aria-up-analysis'>
                                                        <p className='penality-dot-up-analysis'></p>
                                                        <p className='goal-keepper-analysis'></p>
                                                        <div className='goal-post-up-analysis'>
                                                            <p></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='substitues-analysis'>
                                                    <p className='sub-title-analysis'>Substitutions</p>
                                                    <ul className="substitues-body row" onDragOver={handleDragOver} onDrop={(e) => handleDropSubstitute(e, 'sb')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                                        { findSubstitution(subst)}
                                                    </ul>
                                                </div>     
                                            </div>   
                                            
                                    </li>

                                </ul>

                            </div>

                            <div className="FormationDetail">
                                <button type="submit" name="HomeFormationDetail" >Save</button>
                            </div>
                </form>
            
        } else if(isAway === 'away' && matchField?.fixtureId){
           
            const plyr = { ...awayFormation } 
            const subst = []
            for(const key in plyr){
                plyr[key] = findPlayer('away',parseInt(awayFormation[key]))
            }
            for(const sb in awaySubstitutionAll){
                subst.push(findPlayer('away',parseInt(sb)))   
            }
            cont = <form onSubmit={handleAwayFormation}>
                        <div className="formation-analysis">
                            <ul className="row">
                                <li className="col-1-5">
                                        <ul className="row">
                                            <div className="players-analysis">
                                                {awayPlayersTemp.map((p) => (
                                                   !awaySubstitutionAll.hasOwnProperty(p.staffId)  && notInDictionary(awayFormation, p.staffId) &&
                                                    <li className="col-1-2" key={p.staffId}  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, 'back', 0)}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                                        <div className="players-draggable" id={p.staffId} draggable onDragStart={(e)=>handleDragStart(e, p.staffId, 'best-11')}  >
                                                                <img  src={p.playerPhoto? `${backEndUrl}${p.playerPhoto}` : person} alt="person" height='60px' width='60px' />
                                                                <p className="player-thirtNo">{p.shirtNbr}</p>
                                                                <p className="Player-name">{p.staffName}</p>
                                                        </div>
                                                    </li>
                                                ))}
                                            </div>
                                        </ul>
                                </li>
                                <li className="col-2-5" >
                                    <div className="linueup-analysis">
                                            <div className='best-11-analysis'>
                                                <div className='penality-aria-analysis'>
                                                    <div className='goal-post-analysis'>
                                                        <p></p>
                                                    </div>
                                                    <p className='penality-dot-analysis'></p>
                                                    {
                                                        plyr.hasOwnProperty('000') &&
                                                        <span className='goal-keepper-analysis drop'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, 0, 0, 0)}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                                            <div className="players-draggable" id={plyr['000'][0].staffId} draggable onDragStart={(e)=>handleDragStart(e, plyr['000'][0].staffId, 'best-11')}  >
                                                                <img  src={plyr['000'][0].playerPhoto? `${backEndUrl}${plyr['000'][0].playerPhoto}` : person} alt="person" height='60px' width='60px' className="resized-image" />
                                                                <p className="player-thirtNo resized-font">{plyr['000'][0].shirtNbr}</p>
                                                                <p className="Player-name resized-font">{plyr['000'][0].staffName}</p>
                                                            </div>
                                                        </span>
                                                    }
                                                    {
                                                        !plyr.hasOwnProperty('000') && <span className='goal-keepper-analysis drop'  onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, 0, 0, 0)}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}></span>
                                                    }

                                                </div>
                                                <div className='penality-header-analysis'>
                                                </div>
                                                <div className='half-space-analysis'>
                                                    <p className='ball-circle-analysis'></p>
                                                </div>
                                                <div className='best-10-analysis'>
                                                    {findLineUps(plyr)}
                                                </div>
                                                <div className='penality-header-up-analysis'>
                                                </div> 
                                                <div className='penality-aria-up-analysis'>
                                                    <p className='penality-dot-up-analysis'></p>
                                                    <p className='goal-keepper-analysis'></p>
                                                    <div className='goal-post-up-analysis'>
                                                        <p></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='substitues-analysis'>
                                                <p className='sub-title-analysis'>Substitutions</p>
                                                <ul className="substitues-body row" onDragOver={handleDragOver} onDrop={(e) => handleDropSubstitute(e, 'sb')}  onDragLeave={handleDragLeave} onDragEnd={handleDragEnd}>
                                                    { findSubstitution(subst)}
                                                </ul>
                                            </div>     
                                        </div>   
                                        
                                </li>

                            </ul>

                        </div>

                        <div className="FormationDetail">
                            <button type="submit" name="HomeFormationDetail" >Save</button>
                        </div>
            </form>
        } else{
            cont = '';
        }
        
        //setModalType('lg');
        return cont;
    }
    
}

const dateFormatter = (params) =>{
   // console.log('given date and converted data', params, moment(params).format("MMM D, YYYY hA"))
   // const parsedDate = moment.utc(params, "DD-MMM-YY hh.mm.ss.SSSSSSSSS A");
   // console.log('first converted date:', parsedDate)
    return moment.utc(params).format("MMM D, YYYY h:mmA");
}

useEffect(() =>{
    const selectOptions = async()=>{
        
        try{
            const crtiteria = {dateDiff: "yes"}
            const response = await getFixtures(crtiteria);
            
            const formatedOptions = response.map(opt => ({
                value: opt.id,
                homeId: opt.homeId,
                awayId: opt.awayId,
                home: opt.home,
                away: opt.away,
                label: `${opt.home} vs ${opt.away} | ${dateFormatter(opt.dop)}`
            }))
            
            setMatchOptions(formatedOptions)

            const leagResponse = await getLeagues();
                
            const leag = leagResponse.map(opt => ({
                value: opt.id,
                label: opt.name
            }))

            setLeagueOptions(leag)

            const penalityRes = await getlookupDetail(11);
          
            const pnlty = penalityRes.map(opt => ({
                value: opt.detailId,
                label: opt.value
            }))
            
            setPenalityOptions(pnlty)
           
        } catch(err){
            console.log('Error fetching rowData: ', err)
        }
        
    }; 
   
    selectOptions()

}, [])
const getSelectArgOptions = async (arg, selectedOpt) => {
    try{
            let response
            if(arg === 'league') {
                response = await getLeagues();
                
                const formatedOptions = response.map(opt => ({
                    value: opt.id,
                    label: opt.name
                }))
                return formatedOptions;
            } else if(arg === 'club') {
                
                const clb ={leagueId: selectedOpt?.value}
                response = await getleaguesDetail(clb);
                const formatedOptions = response.map(opt => ({
                    value: opt.clubId,
                    label: opt.clubName
                }))
                return formatedOptions;
            }
       
    } catch(err){
        console.log('Error fetching rowData: ', err)
    }
};

 // method to set clubs option
 const HandleclubsOption =  async (selectedOpt) =>{
    const clb = await getSelectArgOptions('club', selectedOpt);
    setClubsOptions(clb)
}
 
    
 //method to validate the input feilds
 const validateForm = async () =>{
    try{
        await matchFieldSchema.validate(matchField, {abortEarly: false})
        setMasterErrors({})
        return true
    } catch(err){
        const validationErrors = {};
        err.inner?.forEach(error => {
            validationErrors[error.path] = error.message
        })
        console.log(validationErrors)
        console.log(err)
        setMasterErrors(validationErrors)
        return false;
    }
   }

   const validateFormMatchFact = async () =>{
        try{
            await matchFactSchema.validate(matchField, {abortEarly: false})
            setMasterErrors({})
            return true
        } catch(err){
            const validationErrors = {};
            err.inner?.forEach(error => {
                validationErrors[error.path] = error.message
            })
            console.log(validationErrors)
            console.log(err)
            setMasterErrors(validationErrors)
            return false;
        }
   }
 

   const clearMatchFields = () =>{
        setMatchField({
            id : '' ,
            matchId: '',
            fixtureId : '',
            leagueName :'' ,
            home: '', 
            away: '',
            country: '',
            stadium :'',
            dop :'',
            url1:'',
            url2:'',
            url3:'',
            createdAt :'',
            createdBy :'',
            updatedAt :'',
            updatedBy :'',
            matchFactId :'',
            homeBallPosession :'',
            awayBallPosession :'',
            homeAttempts :'',
            awayAttempts:'',
            homeCorners :'',
            awayCorners :'',
            homeKeyPass :'',
            awayKeyPass :'',
            homeOnTargets :'',
            awayOnTargets :'',
            homeLineupDict: '',
            awayLineupDict: '',
            homeLineup :'',
            awayLineup :'',
            homeLineupShort :'',
            awayLineupShort :'',
            homeSubstitutionAll:'',
            awaySubstitutionAll:'',
            homeGoalNo: '',
            awayGoalNo:'',
            homeGoalScorers :'',
            awayGoalScorers :'',
            homeGoalScoredAt :'',
            awayGoalScoredAt :'',
            homeGoalAssist :'',
            awayGoalAssist :'',
            homePenality :'',
            awayPenality :'',
            homePenalityAt :'',
            awayPenalityAt :'',
            homePenalityStatus :'',
            awayPenalityStatus :'',
            homeYellowCards :'',
            awayYellowCards :'',
            homeYellowCardsAt :'',
            awayYellowCardsAt :'',
            homeRedCards :'',
            awayRedCards :'',
            homeRedCardsAt :'',
            awayRedCardsAt :'',
            homeInjury :'',
            awayInjury:'',
            homeInjuryAt :'',
            awayInjuryAt :'',
            homeSubstitutionsIn :'',
            awaySubstitutionsIn :'',
            homeSubstitutionsOut :'',
            awaySubstitutionsOut :'',
            homeSubstitutionsAt :'',
            awaySubstitutionsAt :'',
            analysisId :'',
            analysis :''
           
        })
        setPenalityNoHome('')
        setPenalityNoAway('')
        setYellowCardNoAway('')
        setYellowCardNoHome('')
        setRedCardNoAway('')
        setRedCardNoHome('')
        setInjuryNoAway('')
        setInjuryNoHome('')
        setSubstitutionNoAway('')
        setSubstitutionNoHome('')
   }
    

   const clearSuccessMsg = () =>{
    const emptyState = Object.keys(masterSuccess).reduce((acc, key) => {
        acc[key] = '';
        return acc;
      }, {});
      setMasterSuccess(emptyState);
   }
   const handleMatchFiledFactSubmit = async (e)=>{
        e.preventDefault();
        const isValid = await validateFormMatchFact()
        if(isValid){
            try{
                if(isEdit && matchField.matchFactId){
                    const now = new Date();
                    const updatedAt = now.toISOString();
                    const addData = { ...matchField, updatedAt, updatedBy}
                    const res = await editMatchFacts(addData.matchFactId, addData)
                    if(!res.ok){
                        setMasterErrors({...masterErrors, 'matchFactError': res.error})
                    }
                    setMasterSuccess({ ...masterSuccess, 'matchFactMsg': 'Match Fact updated successfully'})

                } else{
                    const now = new Date();
                    const createdAt = now.toISOString();
                    const updatedAt = now.toISOString();
                    const addData = { ...matchField, createdAt, createdBy, updatedAt, updatedBy}
                    const res = await addMatchFact(addData)
                    if(!res.ok){
                        setMasterErrors({...masterErrors, 'matchFactError': res.error})
                    }
                    setMasterSuccess({ ...masterSuccess, 'matchFactMsg': 'Match  Fact saved successfully '})
                    setIsEdit(false)

                }
                
            } catch(err){
                if(err.response){
                    const validationErrors = {};
                    validationErrors['matchFactError'] = err.response.data.error
                    setMasterErrors(validationErrors)
                    
                } else if(err.request){
                    console.error(err.request)
                    console.log('no respone from server')
                } else{
                    console.error('error', err.message)
                    console.log('unkown error')
                }
            }
        } else {
        console.log('validation error: ', )
        }
   }
   

    const handleMatchFiledSubmit = async (e)=>{
        e.preventDefault();
        const isValid = await validateForm()
        if(isValid){
            try{
                  if(isEdit){
                    const now = new Date();
                    const updatedAt = now.toISOString();
                    const addData = { ...matchField, updatedAt, updatedBy}
                    const res = await editMatchs(addData.matchId, addData)
                    if(!res.ok){
                        setMasterErrors({...masterErrors, 'matchError': res.error})
                    }
                    setMasterSuccess({ ...masterSuccess, 'matchMsg': 'Match updated successfully'})

                  } else{
                    const now = new Date();
                    const createdAt = now.toISOString();
                    const updatedAt = now.toISOString();
                    const addData = { ...matchField, createdAt, createdBy, updatedAt, updatedBy}
                    const res = await addMatch(addData)
                    if(!res.ok){
                        setMasterErrors({...masterErrors, 'matchError': res.error})
                    }
                    setMatchField({ ...matchField, matchId: res.id})
                    
                    setMasterSuccess({ ...masterSuccess, 'matchMsg': 'Match saved successfully '})
                    setIsEdit(false)

                  }
                  
            } catch(err){
                if(err.response){
                    const validationErrors = {};
                    validationErrors['matchError'] = err.response.data.error
                    setMasterErrors(validationErrors)
                    
                } else if(err.request){
                    console.error(err.request)
                    console.log('no respone from server')
                } else{
                    console.error('error', err.message)
                    console.log('unkown error')
                }
            }
        } else {
           console.log('validation error: ', )
        }
    }
   
    //method to be executed when delete button clicks
    const showDeleteDiv = (e) =>{
        setShowPopup(true);
        setTblType('all')
    }
     //method to be executed when delete button clicks
     const showDeleteDivFact = (e) =>{
        setShowPopup(true);
        setTblType('fact')
    }
     //method to be executed when delete button clicks
     const showDeleteDivAnalysis = (e) =>{
        setShowPopup(true);
        setTblType('analysis')
    }
    
    const handleMatchSeachSelectChange = (selectedOption, actionMeta)=>{
        const {name } = actionMeta;
        
        setMatchFieldSearch({ ...matchFieldSearch, [name]: selectedOption})
        
        if(name === 'leagueName'){
            HandleclubsOption(selectedOption)
            
        }
    }
   
    //handle master search data change
    const handleMatchSeachChange = (e)=>{
        const {name, value} = e.target;
        setMatchFieldSearch({ ...matchFieldSearch, [name]: value})
    }
   
    const clearSearch = ()=>{
        setMatchFieldSearch({
            url1: '',
            url2: '',
            url3: '',
            match: '',
            leagueName: '',
            home: '',
            away: '',
            dop: ''
        })
    }
     //method to be executed when filter button clicks
     const handleshowFilter = (e) =>{
         if(filterRef.current){
            filterRef.current.setFilteredData([]);
        } 
        clearSearch()
        setShowFilter(true)
    }
    //method to be executed when filter button clicks
    const handleshowFilterMatchFact = (e) =>{
        if(filterRef.current){
        filterRef.current.setFilteredData([]);
    } 
    clearSearch()
    setShowFilter(true)
    }

    //method to be executed when filter button clicks
    const handlehideFilter = (e) =>{
        setShowFilter(false)

    }

    //method to cancel match field
    const handleMatchFiledCancel = () =>{
        clearMatchFields()
        clearSuccessMsg()
        setIsEdit(false)
        
    }
   
    //method to be executed when cancel button clicks
    const handleCancel = () =>{
        setShowPopup(false)
    }

    //method to handle the delete button actions
   const handleDelete = async () =>{
    try{
                if(tblType === 'fact'){
                    const factId = matchField?.matchFactId;
                    if(factId && factId !== ''){
                        const res = await deleteMatchFact(factId)
                        if(!res.ok){
                            setMasterErrors({...masterErrors, 'matchFactError': res.error})
                        } 
                        setShowPopup(false)
                        setIsEdit(false)
                        clearMatchFields()
                    }

                } else if(tblType === 'analysis'){
                    const analId = matchField?.analysisId;
                    if(analId && analId !== ''){
                        const res = await deleteMatchAnalysis(analId)
                        if(!res.ok){
                            setMasterErrors({...masterErrors, 'analysisError': res.error})
                        } 
                        setShowPopup(false)
                        setIsEdit(false)
                        clearMatchFields()
                    }

                }  else{
                    const id = matchField?.matchId;
                    const factId = matchField?.matchFactId;
                    const analysisId = matchField?.analysisId
                    if(factId && factId !== ''){
                        await deleteMatchFact(factId)
                    }
                    if(analysisId && analysisId !== ''){
                        await deleteMatchAnalysis(analysisId)
                    }
                
                    const res = await deleteMatch(id)
                    if(!res.ok){
                    setMasterErrors({...masterErrors, 'matchError': res.error})
                    } 
                    setShowPopup(false)
                    setIsEdit(false)
                    clearMatchFields()
                }
                
        } catch(err){
            if(err.response){
                const validationErrors = {};
                validationErrors['matchError'] = err.response.data.error
                setMasterErrors(validationErrors)
                
            } else if(err.request){
                console.error(err.request)
                console.log('no respone from server')
            } else{
                console.error('error', err.message)
                console.log('unkown error')
            }
        }
   }
  
    // search fields
    const searchFields = [
        {id: 'leagueName', desc:'League Name', name: 'leagueName', type:'Select', options:leagueOptions, placeHolder:'Select league...', value:matchFieldSearch.leagueName, onChange:handleMatchSeachSelectChange},
        {id: 'home', desc:'Home', name: 'home', type:'Select', options:clubsOptions, placeHolder:'Select home...', value:matchFieldSearch.home, onChange:handleMatchSeachSelectChange},
        {id: 'away', desc:'Away', name: 'away', type:'Select', options:clubsOptions, placeHolder:'Select away...', value:matchFieldSearch.away, onChange:handleMatchSeachSelectChange},
        {id: 'dop', desc:'Match Date', name: 'dop', type:'date', placeHolder: 'match date', value:matchFieldSearch.dop, onChange:handleMatchSeachChange},
       
    ];
    
    return (
        <div>
            <div className="current-page">
                    <p ><Link to="/dashboard" className="link">Dashboard</Link></p>
                    <p>/</p>
                    <p>Create Highlight</p>
            </div>
            <div className="create-HMF">
                <MatchFilter showFilterPopup={showFilter} onHideFilterPopup={handlehideFilter} filterAttributes={matchFieldSearch}   setAddAttributes={setMatchField} searchFields={searchFields}  tableName="matchs" matchOptions={matchOptions} setIsEdit={setIsEdit} handlePlayersOption={handlePlayersOption} ref={filterRef}   />
                <ConfirmationPopup showPopup={showPopup} onDeleteHandler={handleDelete} onCancelHandler={handleCancel} content={tblType === 'fact'? "Are you sure you want to delete this record(only match Fact)?": tblType === 'analysis'? "Are you sure you want to delete this record(only match Analysis)?" : "Are you sure you want to delete these records(match, match Fact, match Analysis)?" } />  
                <Modal isOpen={isModalOpen} onClose={closeModal} content={findContent()} modalType={modalContentShow === 'formation' ? 'xlg': 'md'} />
                <div className="create-Hl">
                    <p className="instruction">Fill the following Match Fields</p>
                    <div className="match-actions">
                                <div className="tooltip-div">
                                    <button className="btn" data-tooltip="search" onClick={handleshowFilter} ><i className="bx bx-search" ></i></button>
                                </div>
                                <div className="tooltip-div">
                                    <button className="btn" data-tooltip="delete" onClick={showDeleteDiv} disabled={!isEdit ? true: false} style={{cursor: !isEdit ? 'not-allowed': 'pointer'}}><i className="bx bx-x"></i></button>
                                </div>
                    </div>
                    <div >
                        {masterErrors.matchError && <p className="form-error-hdr">{masterErrors.matchError}</p>}
                        {masterSuccess.matchMsg && <p className="form-success-hdr">{masterSuccess.matchMsg}</p>}
                        <ul className="row">
                            <li className="col-1-1">
                                <div className="field-input">
                                    {masterErrors['fixtureId'] && <span className="form-error">{masterErrors['fixtureId']}</span>}
                                    <div className="add-row">
                                        <label htmlFor="match">Match between:</label>
                                        <Select className="search-select" name="fixtureId" id="fixtureId" value={matchField?.fixtureId} onChange={handleMatchChange} options={matchOptions} placeholder="Search matchs here..." />
                                    </div>
                                </div>
                            
                            </li>
                            <li className="col-1-2 urls">
                                <div className="field-input">
                                    {masterErrors['url1'] && <span className="form-error">{masterErrors['url1']}</span>}
                                    <div className="add-row">
                                        <label htmlFor="url1">Highlight Url-1</label>
                                        <input id="url1" name="url1" type="text" value={matchField?.url1} onChange={handleMatchFieldChange} placeholder="url-1"/>
                                    </div>
                                </div>
                                
                                <div className="field-input">
                                {masterErrors['url2'] && <span className="form-error">{masterErrors['url2']}</span>}
                                <div className="add-row">
                                    <label htmlFor="url2">Highlight Url-2</label>
                                    <input id="url2" name="url2" type="text" value={matchField?.url2} onChange={handleMatchFieldChange} placeholder="url-2"/>
                                </div>
                                </div>
                            
                            </li>
                            <li className="col-1-2">
                            <div className="field-input">
                            {masterErrors['url3'] && <span className="form-error">{masterErrors['url3']}</span>}
                            <div className="add-row">
                                    <label htmlFor="url3">Highlight Url-3</label>
                                    <input id="url3" name="url3" type="text" value={matchField?.url3} onChange={handleMatchFieldChange} placeholder="url-3"/>
                                </div>
                            </div>
                            

                            </li>
                        </ul>
                    
                        <div className="form-button">
                            { isEdit ?
                                <div>
                                    <button type="submit" className="form-btn" name="match-submit" onClick={handleMatchFiledSubmit}>Update</button>
                                    <button type="submit" className="form-btn" name="match-submit"onClick={handleMatchFiledCancel} >Cancel</button>
                                </div>
                                :
                                <button type="submit" className="form-btn" name="match-submit" onClick={handleMatchFiledSubmit} disabled={matchField?.fixtureId ? false: true} style={{cursor: !matchField?.fixtureId  ? 'not-allowed': 'pointer'}}>Save</button>
                            }
                    </div>
                    </div>
                
                </div>
                <div className="create-MF">
                    <p className="instruction">Fill the following Match Fact Fields</p>
                    <div>
                        <div className="match-actions">
                                    <div className="tooltip-div">
                                        <button className="btn" data-tooltip="search" onClick={handleshowFilterMatchFact} ><i className="bx bx-search" ></i></button>
                                    </div>
                                    <div className="tooltip-div">
                                        <button className="btn" data-tooltip="delete" onClick={showDeleteDivFact} disabled={!(isEdit && matchField?.matchFactId) ? true: false} style={{cursor: !(isEdit && matchField?.matchFactId) ? 'not-allowed': 'pointer'}}><i className="bx bx-x"></i></button>
                                    </div>
                        </div>
                        {masterErrors.matchFactError && <p className="form-error-hdr">{masterErrors.matchFactError}</p>}
                        {masterSuccess.matchFactMsg && <p className="form-success-hdr">{masterSuccess.matchFactMsg}</p>}
                        <ul className="row">
                            <li className="col-1-2">
                                <p className="form-title-home">Home{matchField?.fixtureId ? ` (${matchField.fixtureId.home})` : ''   } </p>
                            
                                <div className="field-input">
                                    {masterErrors['homeGoalNo'] && <span className="form-error">{masterErrors['homeGoalNo']}</span>}
                                    <div className="add-row-span">
                                        <label htmlFor="homeGoalNo">Goals</label>
                                        <div className="form-input-span">
                                            <input id="homeGoalNo" name="homeGoalNo" type="number" value={matchField?.homeGoalNo} onChange={handleGoalNoHomeChange} placeholder="home goal No"/>
                                            <span className="goalScorers"  onClick={(matchField?.fixtureId && matchField?.homeGoalNo > 0)? () => handleMatchFactType('home', 'goal'): () => ''}>...</span>
                                        </div>
                                    </div>
                                </div>

                            
                            
                                <div className="field-input">
                                    {masterErrors['homeLineupShort'] && <span className="form-error">{masterErrors['homeLineupShort']}</span>}
                                    <div className="add-row-span">
                                        <label htmlFor="homeLineupShort">Formations</label>
                                        <div className="form-input-span">
                                            <input id="homeLineupShort" name="homeLineupShort" type="text" value={matchField?.homeLineupShort} onChange={handleMatchFieldChange} placeholder="home formation"  readOnly/>
                                            <span className="formations"  onClick={(matchField?.fixtureId)? () => handleMatchFactType('home', 'formation'): () => ''} >...</span>
                                        </div>
                                    </div>
                                </div>
                                
                                
                            

                                <div className="field-input">
                                    {masterErrors['penalityH'] && <span className="form-error">{masterErrors['penalityH']}</span>}
                                    <div className="add-row-span">
                                        <label htmlFor="penalityH">Penalities</label>
                                        <div className="form-input-span">
                                            <input id="penalityH" name="penalityH" type="number" value={PenalityNoHome} onChange={handlePenalityNoHomeChange} placeholder="home penality"/>
                                            <span className="penality"  onClick={(matchField?.fixtureId && PenalityNoHome > 0)? () => handleMatchFactType('home', 'penality'): () => ''}>...</span>

                                        </div>
                                    </div>
                                </div>

                                <div className="field-input">
                                    {masterErrors['YellowcardH'] && <span className="form-error">{masterErrors['YellowcardH']}</span>}
                                    <div className="add-row-span">
                                        <label htmlFor="cardH">Yellow Cards</label>
                                        <div className="form-input-span">
                                            <input id="YellowcardH" name="YellowcardH" type="number" value={YellowCardNoHome} onChange={handleYellowCardNoHomeChange} placeholder="home yellow cards"/>
                                            <span className="Fbcard"  onClick={(matchField?.fixtureId && YellowCardNoHome > 0)? () => handleMatchFactType('home', 'yellow'): () => ''}>...</span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="field-input">
                                    {masterErrors['RedcardH'] && <span className="form-error">{masterErrors['RedcardH']}</span>}
                                    <div className="add-row-span">
                                        <label htmlFor="cardH">Red Cards</label>
                                        <div className="form-input-span">
                                            <input id="RedcardH" name="RedcardH" type="number" value={RedCardNoHome} onChange={handleRedCardNoHomeChange} placeholder="home red cards"/>
                                            <span className="Fbcard"  onClick={(matchField?.fixtureId && RedCardNoHome > 0)? () => handleMatchFactType('home', 'red'): () => ''}>...</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="field-input">
                                    {masterErrors['injuryH'] && <span className="form-error">{masterErrors['injuryH']}</span>}
                                    <div className="add-row-span">
                                        <label htmlFor="injuryH">Injuries</label>
                                        <div className="form-input-span">
                                            <input id="injuryH" name="injuryH" type="number" value={InjuryNoHome} onChange={handleInjuryNoHomeChange} placeholder="home injury"/>
                                            <span className="injury"  onClick={(matchField?.fixtureId && InjuryNoHome > 0)? () => handleMatchFactType('home', 'injury'): () => ''}>...</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="field-input">
                                    {masterErrors['substitutionH'] && <span className="form-error">{masterErrors['substitutionH']}</span>}
                                    <div className="add-row-span">
                                        <label htmlFor="substitutionH">Substitutions</label>
                                        <div className="form-input-span">
                                            <input id="substitutionH" name="substitutionH" type="number" value={substitutionNoHome} onChange={handleSubstitutionNoHomeChange} placeholder="home substitution"/>
                                            <span className="substitution"  onClick={(matchField?.fixtureId && substitutionNoHome > 0)? () => handleMatchFactType('home', 'substitution'): () => ''}>...</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="field-input">
                                    {masterErrors['homeBallPosession'] && <span className="form-error">{masterErrors['homeBallPosession']}</span>}
                                    <div className="add-row">
                                        <label htmlFor="homeBallPosession">Ball Posession</label>
                                        <input id="homeBallPosession" name="homeBallPosession" type="number" className="input-away" value={matchField?.homeBallPosession} onChange={handleMatchFieldChange} placeholder="home ball posession"/>
                                    </div>
                                </div>

                                <div className="field-input">
                                    {masterErrors['homeAttempts'] && <span className="form-error">{masterErrors['homeAttempts']}</span>}
                                    <div className="add-row">
                                        <label htmlFor="homeAttempts">Attempts</label>
                                        <input id="homeAttempts" name="homeAttempts" type="number" className="input-away" value={matchField?.homeAttempts} onChange={handleMatchFieldChange} placeholder="home attempts"/>
                                    </div>
                                </div>

                                <div className="field-input">
                                    {masterErrors['homeCorners'] && <span className="form-error">{masterErrors['homeCorners']}</span>}
                                    <div className="add-row">
                                        <label htmlFor="homeCorners">Corners</label>
                                        <input id="homeCorners" name="homeCorners" type="number" className="input-away" value={matchField?.homeCorners} onChange={handleMatchFieldChange} placeholder="home corners"/>
                                    </div>
                                </div>

                                <div className="field-input">
                                    {masterErrors['homeKeyPass'] && <span className="form-error">{masterErrors['homeKeyPass']}</span>}
                                    <div className="add-row">
                                        <label htmlFor="homeKeyPass">Key Passes</label>
                                        <input id="homeKeyPass" name="homeKeyPass" type="number" className="input-away" value={matchField?.homeKeyPass} onChange={handleMatchFieldChange} placeholder="home key pass"/>
                                    </div>
                                </div>

                                <div className="field-input">
                                    {masterErrors['homeOnTargets'] && <span className="form-error">{masterErrors['homeOnTargets']}</span>}
                                    <div className="add-row">
                                        <label htmlFor="homeOnTargets">On Targets</label>
                                        <input id="homeOnTargets" name="homeOnTargets" type="number" className="input-away" value={matchField?.homeOnTargets} onChange={handleMatchFieldChange} placeholder="home on targets"/>
                                    </div>
                                </div>
                                
                                
                            
                            </li>
                            <li className="col-1-2">
                                <p className="form-title-away">Away{matchField?.fixtureId ? ` (${matchField.fixtureId.away})`: '' }</p>
                            
                               
                                <div className="field-input">
                                    {masterErrors['awayGoalNo'] && <span className="form-error">{masterErrors['awayGoalNo']}</span>}
                                    <div className="add-row-span">
                                        <label htmlFor="awayGoalNo">Goals</label>
                                        <div className="form-input-span">
                                            <input id="awayGoalNo" name="awayGoalNo" placeholder="away goal No" type="number" value={matchField?.awayGoalNo} onChange={handleGoalNoAwayChange}/>
                                            <span className="goalScorers" onClick={(matchField?.fixtureId  && matchField?.awayGoalNo > 0)? () => handleMatchFactType('away', 'goal') : () => ''}>...</span>
                                        </div>
                                    </div>
                                </div>

                            
                                <div className="field-input">
                                    {masterErrors['formationA'] && <span className="form-error">{masterErrors['formationA']}</span>}
                                    <div className="add-row-span">
                                        <label htmlFor="formationA">Formations</label>
                                        <div className="form-input-span">
                                            <input id="formationA" name="formationA" placeholder="away formation" type="text" value={matchField?.awayLineupShort} readOnly/>
                                            <span className="formations"  onClick={(matchField?.fixtureId)? () => handleMatchFactType('away', 'formation'): () => ''} >...</span>
                                        </div>
                                    </div>
                                </div>

                                
                                <div className="field-input">
                                    {masterErrors['penalityA'] && <span className="form-error">{masterErrors['penalityA']}</span>}
                                    <div className="add-row-span">
                                        <label htmlFor="penalityA">Penalities</label>
                                        <div className="form-input-span">
                                            <input id="penalityA" name="penalityA" placeholder="away penality" type="number" value={PenalityNoAway} onChange={handlePenalityNoAwayChange}/>
                                            <span className="penality"  onClick={(matchField?.fixtureId  && PenalityNoAway > 0)? () => handleMatchFactType('away', 'penality'): () => ''}>...</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="field-input">
                                    {masterErrors['YellowcardA'] && <span className="form-error">{masterErrors['YellowcardA']}</span>}
                                    <div className="add-row-span">
                                        <label htmlFor="YellowcardA">Yellow Cards</label>
                                        <div className="form-input-span">
                                            <input id="YellowcardA" name="YellowcardA" placeholder="away yellow cards" type="number" value={YellowCardNoAway} onChange={handleYellowCardNoAwayChange}/>
                                            <span className="Fbcard"  onClick={(matchField?.fixtureId  && YellowCardNoAway > 0)? () => handleMatchFactType('away', 'yellow'): () => ''}>...</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="field-input">
                                    {masterErrors['RedcardA'] && <span className="form-error">{masterErrors['RedcardA']}</span>}
                                    <div className="add-row-span">
                                        <label htmlFor="RedcardA">Red Cards</label>
                                        <div className="form-input-span">
                                            <input id="RedcardA" name="RedcardA" placeholder="away red cards" type="number" value={RedCardNoAway} onChange={handleRedCardNoAwayChange}/>
                                            <span className="Fbcard"  onClick={(matchField?.fixtureId  && RedCardNoAway > 0)? () => handleMatchFactType('away', 'red'): () => ''}>...</span>

                                        </div>
                                    </div>
                                </div>
                                <div className="field-input">
                                    {masterErrors['InjuryA'] && <span className="form-error">{masterErrors['InjuryA']}</span>}
                                    <div className="add-row-span">
                                        <label htmlFor="InjuryA">Injuries</label>
                                        <div className="form-input-span">
                                            <input id="InjuryA" name="InjuryA" placeholder="away injury" type="number" value={InjuryNoAway} onChange={handleInjuryNoAwayChange}/>
                                            <span className="injury"  onClick={(matchField?.fixtureId  && InjuryNoAway > 0)? () => handleMatchFactType('away', 'injury'): () => ''}>...</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="field-input">
                                    {masterErrors['InjuryA'] && <span className="form-error">{masterErrors['InjuryA']}</span>}
                                    <div className="add-row-span">
                                        <label htmlFor="substitutionA">Substitutions</label>
                                        <div className="form-input-span">
                                            <input id="substitutionA" name="substitutionA" placeholder="away subsitition" type="number" value={substitutionNoAway} onChange={handleSubstitutionNoAwayChange}/>
                                            <span className="substitution"  onClick={(matchField?.fixtureId  && substitutionNoAway > 0)? () => handleMatchFactType('away', 'substitution'): () => ''}>...</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="field-input">
                                    {masterErrors['awayBallPosession'] && <span className="form-error">{masterErrors['awayBallPosession']}</span>}
                                    <div className="add-row">
                                        <label htmlFor="awayBallPosession">Ball Posession</label>
                                        <input id="awayBallPosession" placeholder="away Ball posession" name="awayBallPosession" type="number" className="input-away" value={matchField?.awayBallPosession} onChange={handleMatchFieldChange}/>
                                    </div>
                                </div>
                                <div className="field-input">
                                    {masterErrors['awayAttempts'] && <span className="form-error">{masterErrors['awayAttempts']}</span>}
                                    <div className="add-row">
                                        <label htmlFor="awayAttempts">Attempts</label>
                                        <input id="awayAttempts" name="awayAttempts" placeholder="away attempts" type="number" className="input-away" value={matchField?.awayAttempts} onChange={handleMatchFieldChange}/>
                                    </div>
                                </div>
                                <div className="field-input">
                                    {masterErrors['awayCorners'] && <span className="form-error">{masterErrors['awayCorners']}</span>}
                                    <div className="add-row">
                                        <label htmlFor="awayCorners">Corners</label>
                                        <input id="awayCorners" name="awayCorners" type="number" placeholder="away corners" className="input-away" value={matchField?.awayCorners} onChange={handleMatchFieldChange}/>
                                    </div>
                                </div>
                                <div className="field-input">
                                    {masterErrors['awayKeyPass'] && <span className="form-error">{masterErrors['awayKeyPass']}</span>}
                                    <div className="add-row">
                                        <label htmlFor="awayKeyPass">Key Passes</label>
                                        <input id="awayKeyPass" name="awayKeyPass" placeholder="away key passes" type="number" className="input-away" value={matchField?.awayKeyPass} onChange={handleMatchFieldChange}/>
                                    </div>
                                </div>
                                <div className="field-input">
                                    {masterErrors['awayOnTargets'] && <span className="form-error">{masterErrors['awayOnTargets']}</span>}
                                    <div className="add-row">
                                        <label htmlFor="awayOnTargets">On Targets</label>
                                        <input id="awayOnTargets" name="awayOnTargets" placeholder="away on targets" type="number" className="input-away" value={matchField?.awayOnTargets} onChange={handleMatchFieldChange}/>
                                    </div>
                                </div>
                                
                            
                            </li>
                        </ul>
                        <div className="form-button">
                            { isEdit && matchField?.matchFactId ?
                                <div>
                                    <button type="submit" className="form-btn" name="match-submit" onClick={handleMatchFiledFactSubmit}>Update</button>
                                    <button type="submit" className="form-btn" name="match-submit"onClick={handleMatchFiledCancel} >Cancel</button>
                                </div>
                                :
                                <button type="submit" className="form-btn" name="match-submit" onClick={handleMatchFiledFactSubmit} disabled={matchField?.matchId ? false: true} style={{cursor: !matchField?.matchId  ? 'not-allowed': 'pointer'}} >Save</button>
                            }
                    </div>

                    </div>
                </div>

            </div>
            <Analysis matchField={matchField} setMatchField={setMatchField} isEdit={isEdit} setIsEdit={setIsEdit}  handleshowFilter={handleshowFilter} showDeleteDiv={showDeleteDivAnalysis} />
        </div>
    )
  
}



export default HighlightMatchFact;