import { createSlice } from '@reduxjs/toolkit'


const authSlice = createSlice({
    name: 'user',
    initialState:{
        detail: JSON.parse(localStorage.getItem('fb-user')) || null,
        token: localStorage.getItem('fb-token') || null,
        tokenRefresh: localStorage.getItem('fb-token-refresh') || null,
        apiKey:localStorage.getItem('api-key') || null,
        publicToken:localStorage.getItem('public-token') || null,
        
    },
    reducers:{
        setUser(state, action){
            state.detail = action.payload.detail
            state.token = action.payload.token
            state.tokenRefresh = action.payload.tokenRefresh
            localStorage.setItem('fb-token', action.payload.token)
            localStorage.setItem('fb-token-refresh', action.payload.tokenRefresh)
            localStorage.setItem('fb-user', JSON.stringify(action.payload.detail))
        },
        setApiKey(state, action){
            state.apiKey = action.payload.apiKey
            state.publicToken = action.payload.publicToken
            localStorage.setItem('api-key', action.payload.apiKey)
            localStorage.setItem('public-token', action.payload.publicToken)
            
        },
        clearUser(state){
            state.detail = null;
            state.token = null;
            state.tokenRefresh = null
            localStorage.removeItem('fb-token')
            localStorage.removeItem('fb-user')
            localStorage.removeItem('fb-token-refresh')
        },
        clearApiKey(state){
            localStorage.removeItem('api-key')
            localStorage.removeItem('public-token')
        }
        
    }
})

//export the actions
export const{ setUser, clearUser, setApiKey, clearApiKey} = authSlice.actions

//export the reducer
export default authSlice.reducer;