import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { passwordResetConfirmSchema } from "../Validations/passwordResetConfirmValidation";
import { passwordResetConfirm } from "../services/usersService";
import logofootball from '../Assets/images/myfootball.png'

function PasswordResetConfirm(){
    const [newPassword, setNewPassword] = useState()
    const [newPasswordConfirm, setNewPasswordConfirm] = useState()
    const [masterErrors, setMasterErrors] = useState({});
    const [isSubmitted, setIsSubmitted]= useState(false)
    const [userToken, setUserToken] = useState()
    const location = useLocation()

 
    const handlePasswordResetConfirm = async (e)=>{
        e.preventDefault()
        const data ={
            newPassWord: newPassword,
            newPassWordConfirm: newPasswordConfirm,
            token: userToken
        }
        const isValid = await validateForm(data)
        if(isValid){
            try{
                const res = await passwordResetConfirm(data)
                if(!res.ok){
                    setMasterErrors({...masterErrors, 'passwordResetConfirmError': res.error})
                }
                setIsSubmitted(true)
                setNewPassword('')
                setNewPasswordConfirm('')
                setMasterErrors({})
            } catch(err){
                if(err.response){
                    setMasterErrors({...masterErrors, 'passwordResetConfirmError': err.response.data.detail || 'Error resetting password. try again'})
                }else {
                    setMasterErrors({ ...masterErrors, 'passwordResetConfirmError': 'Error resetting password. try again' });
                }
               
                
            }
            
        }
    }
    const newPasswordOnChange = (e)=>{
        setNewPassword(e.target.value)
    }
    const newPasswordConfirmOnChange = (e)=>{
        setNewPasswordConfirm(e.target.value)
    }
    const validateForm = async (data) =>{
        try{
            await passwordResetConfirmSchema.validate(data, {abortEarly: false})
            setMasterErrors({})
            return true
        } catch(err){
            const validationErrors = {};
            err.inner?.forEach(error => {
                validationErrors[error.path] = error.message
            })
            console.log(validationErrors)
            console.log(err)
            setMasterErrors(validationErrors)
            return false;
        }
    }
    useEffect(()=>{
        const qry = new URLSearchParams(location.search)
        const token = qry.get('token')
        setUserToken(token)
    }, [location])

    return(
        <div className="login-container">
            <form  className="login">
                <div className="login-hdr">
                    <Link to="/" className='link'><img src={logofootball} alt='myFootball' className='logo'/></Link>
                    <p className="login-title">Rest your Password here</p>
                </div>
                {
                    isSubmitted && <p className="success-msg2">Password reset successfully, <Link to="/login" className='login-link'>Login</Link> in to check</p>
                                    
                } 
                {masterErrors.passwordResetConfirmError && <p className="form-error-login-hdr">{masterErrors.passwordResetConfirmError}</p>}
                <div className="log-input">
                    {masterErrors['newPassWord'] && <span className="form-error-login">{masterErrors['newPassWord']}</span>}
                    <div className="login-div"><i className="bx bx-key login-icon"></i><input type="password" name="newPassWord" value={newPassword} onChange={newPasswordOnChange}  placeholder="new password" /></div> 
                </div>
                <div className="log-input">
                    {masterErrors['newPassWordConfirm'] && <span className="form-error-login">{masterErrors['newPassWordConfirm']}</span>}
                    <div className="login-div"><i className="bx bx-key login-icon"></i><input type="password" name="newPassWordConfirm" value={newPasswordConfirm} onChange={newPasswordConfirmOnChange}  placeholder="new password confirm" /></div> 
                </div>
            
                <div className="password-reset-div">
                    <button type="submit" className="btn-password-reset" onClick={handlePasswordResetConfirm}>Reset Password</button>
                </div>
            
            </form>
        </div>
    )
}
export default PasswordResetConfirm