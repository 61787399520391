import axios from 'axios';
import store from '../store/store';
import {  clearUser, setApiKey, setUser } from '../slices/authSlice';
//import { useNavigate } from "react-router-dom";


//const fbUrl = 'http://localhost:8000/api'
const fbUrl = 'https://footballback.zeaye.com/api'

// Create an Axios instance
const axiosInstance = axios.create({
   baseURL: fbUrl,
   // baseURL: 'https://footballback.zeaye.com/api',
});

let isFetchingApiKey = false;
let apiKeyPromise = null;



const fetchApiKey = async (dispatch) => {
  const userState = store.getState();
  let apiKey = userState.auth.apiKey;


  // Check if the apiKey is undefined or null
  if (apiKey === 'undefined' || apiKey === undefined || apiKey === null || apiKey ==='') { // This checks for both undefined and null
    if (!isFetchingApiKey) {
      isFetchingApiKey = true;

      apiKeyPromise = axiosInstance.get('apiKey')
        .then(response => {
          const apiKey = response.data.Api_key; // Adjust based on your response structure
          dispatch(setApiKey({ apiKey })); // Dispatch to set the API key
          return apiKey;
        })
        .catch(error => {
          console.error('Failed to fetch API key:', error);
          return null; // Handle errors gracefully
        })
        .finally(() => {
          isFetchingApiKey = false; // Reset the flag
        });
    }
  } 
  // else {
  //   console.log('Using cached API Key:', apiKey);
  // }

  return apiKeyPromise || apiKey; // Return the promise or the existing key
};

const verifyToken = async(token) =>{
  try{
    
      const response = await axios.post(fbUrl + '/token/verify',{token})
      return response.status === 200;
  } catch (error){
      console.log("token verification failed:", error) 
      return false
  }
}
const refreshToken = async(token) =>{
  try{
      
        const response = await axios.post(fbUrl + '/token/refresh',{
        refresh: token
      }
    )
      return response;
  } catch (error){
      console.log("token refreshment failed:", error)
      throw error
  }

 
}

  //axios interceptor request to attache headers
 axiosInstance.interceptors.request.use(
   async (config) => {
      //const userState = useAuthToken();
      
      const userState = store.getState()
     // console.log('user state:', userState)

      const authState = userState.auth;
      //console.log('Auth state:', authState); // Log the auth slice    
      //console.log('user state:', userState)
      const token = authState.token
      const tokenRefresh = authState.tokenRefresh
      const usr = authState.detail


     // const apiKey = authState.apiKey
     
    // console.log('in axios instance token:', token)

      if (token) {
        const isValid = await verifyToken(token)
        if(isValid){
          config.headers['Authorization'] = `Bearer ${token}`;
        } else{
          console.log('not valid token')
          try{
            const response = await refreshToken(tokenRefresh) 
           // console.log('refresh response:', response)
            if(response.status === 200){
             // console.log('refreshed successfully');
              const { access, refresh } = response.data


              localStorage.setItem('fb-token', access)
              localStorage.setItem('fb-token-refresh', refresh)
              localStorage.setItem('fb-user', usr)
              store.dispatch(setUser({detail:usr, token: access, tokenRefresh:refresh}))
              config.headers['Authorization'] = `Bearer ${access}`;

            } else{
              console.log('refreshed fail');
              return Promise.reject(response);
            }
            
          
          } catch(err){
            console.error('Token refresh failed', err);
            store.dispatch(clearUser());
            return Promise.reject(err);
          }
        }
      }

      if(!config.url.includes('apiKey')){
        const apiKey = await fetchApiKey(store.dispatch);
        if (apiKey !== null) {
          config.headers['X-API-KEY'] = `${apiKey}`;
        } 
        else {
          console.log('api key missing')
          return Promise.reject(new Error('API key is missing.'));
        }
      }
      
     
    
       // Dynamically set Content-Type based on the request
      if (config.data instanceof FormData) {
          config.headers['Content-Type'] = 'multipart/form-data';
      } else if (!config.headers['Content-Type']) {
          config.headers['Content-Type'] = 'application/json';
      }

     // console.log('config:', config)
      return config;
    },
    (error) => {
      console.log('error at interception')
      return Promise.reject(error);
    }
  );

 

 // ----------------------------------------!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!----------------------------

  // Axios interceptor to handle http errors
  // axiosInstance.interceptors.response.use(
  //   (response) =>{
  //     return response
  //   },
  //   async (error) => {
  //      // const { status, data } = error.response;
  //       const status = error.response?.status;
  //       const originalRequest = error.config;

  //       if (status === 401 && !originalRequest._retry) {
  //         // Check if the error is due to token expiration
  //         if (error.config.url.includes('/token/refresh')) {
  //           // If refresh token request failed, clear tokens and redirect
  //           store.dispatch(clearUser());
  //           return Promise.reject(error);
  //         } else {
  //           const userState = store.getState()
  //           const authState = userState.auth;
  //           const tokenRefresh = authState.tokenRefresh
  //           if(tokenRefresh){
  //            // console.log('in toke refresh', tokenRefresh)
  //             originalRequest._retry = true;
  //             try{
  //               const response = await axiosInstance.post('token/refresh', {
  //                   refresh: tokenRefresh
  //               });
  //               const { access } = response.data
  //               localStorage.setItem('fb-token', access)
  //               //axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${access}`;
  //               originalRequest.headers['Authorization'] = `Bearer ${access}`;
  //               return axiosInstance(originalRequest);

  //             } catch(err){
  //               console.error('Token refresh failed', err);
  //               store.dispatch(clearUser());
  //               return Promise.reject(err);
  //             }
  //           } 
            
  //         }
           
  //       } 
        
  //       return Promise.reject(error);
  //   }
  // );


  // Axios interceptor to handle http errors
  // axiosInstance.interceptors.response.use(
  //   (response) =>{
  //     return response
  //   },
  //   async (error) => {
  //      // const { status, data } = error.response;
  //       const status = error.response?.status;
  //       const data = error.response?.data;
  //       const message = data ? data.detail : null;
  //       const originalRequest = error.config;

  //       if (status === 401 && !originalRequest._retry) {
  //         // Check if the error is due to token expiration
  //         if (error.config.url.includes('/token/refresh')) {
  //           // If refresh token request failed, clear tokens and redirect
  //           store.dispatch(clearUser());
  //           if (typeof window !== 'undefined') {
  //             const navigate = useNavigate();
  //             navigate('/unauthorized');
  //           }
  //           return Promise.reject(error);
  //         } else {
  //           const userState = store.getState()
  //           const authState = userState.auth;
  //           const tokenRefresh = authState.tokenRefresh
  //           if(tokenRefresh){
  //            // console.log('in toke refresh', tokenRefresh)
  //             originalRequest._retry = true;
  //             try{
  //               const response = await axiosInstance.post('token/refresh', {
  //                   refresh: tokenRefresh
  //               });
  //               const { access } = response.data
  //               localStorage.setItem('fb-token', access)
  //               //axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${access}`;
  //               originalRequest.headers['Authorization'] = `Bearer ${access}`;
  //               return axiosInstance(originalRequest);

  //             } catch(err){
  //               console.error('Token refresh failed', err);
  //               store.dispatch(clearUser());
  //               if (typeof window !== 'undefined') {
  //                 const navigate = useNavigate();
  //                 navigate('/unauthorized');
  //               }
  //               return Promise.reject(err);
  //             }
  //           } else if(!message.includes('No active account found')){
  //             store.dispatch(clearUser());
  //             if (typeof window !== 'undefined') {
  //               const navigate = useNavigate();
  //               navigate('/unauthorized');
  //             }
  //             return Promise.reject(error);
  //           }
            
  //         }
           
  //       } else if (status === 403) {
  //         // Redirect to an Unauthorized page or show a message
  //         const navigate = useNavigate();
  //         navigate('/unauthorized');
  //       } // Handle 400 Bad Request (e.g., invalid credentials during login)
  //       else if (status === 400 && data && data.detail) {
  //         // Do not redirect; handle this on the login page
  //         return Promise.reject({
  //           ...error,
  //           response: {
  //             ...error.response,
  //             status: 400,
  //             statusText: 'Invalid login credentials',
  //           },
  //         });
  //       }
  //       return Promise.reject(error);
  //   }
  // );

export { axiosInstance} ;

