import React, { useEffect, useRef, useState } from "react";
import CustomLoadingOverlay from "../Components/Body/loadingOverlay";
import getPlayers, { addPlayer, deletePlayer, editPlayer } from "../services/playersService";
import { playersSchema } from "../Validations/playersValidation";
import { getlookupDetail } from "../services/lookupService";
import ConfirmationPopup from "../Utils/confirmationPopup";
import { Link } from "react-router-dom";
import Actions from "../Components/Header/actions";
import SearchMod from "../Components/Body/searchMod";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css'
import AddMod from "../Components/Body/addMod";
import imageCompression from 'browser-image-compression'
import ImageRenderer, { TextRenderer } from "../Components/Body/imageRenderer";
import ShowDetail from "../Utils/showDetail";
function Players(){
     // state variables
     const [rowData, setRowData] = useState([])
     const [masterErrors, setMasterErrors] = useState({});
     const [showSearch, setShowSearch] = useState(false)
     const [showAdd, setShowAdd] = useState(false)
     const [selectedRows, setSelectedRows] = useState([])
     const [moduleTitle, setModuleTitle] = useState()
     const [isEdit, setIsEdit] = useState(false)
     const [showPopup, setShowPopup] = useState(false)
     const [loading, setLoading] = useState(false);
     const [footOptions, setFootOptions] = useState();
     const [statusOptions, setStatusOptions] = useState();
     const [positionOptions, setPositionOptions] = useState();
     const [nationalityOptions, setNationalityOptions] = useState()
     const [fname, setFName] = useState('No file chosen')
     const [showPlayer, setShowPlayer] = useState(false)
     const [clickedRow, setClickedRow] = useState()
     const [isloading, setIsLoading] = useState(true)
     const [profile, setProfile] = useState(null)

     
 
     //constant variables
     const gridOptions = {loadingOverlayComponent: CustomLoadingOverlay}
     const gridApiRef = useRef(null)
     const loadingTimoutRef = useRef(null);

     //method to format the date displayed in grid
     const dateFormatter = params =>{
         if(!params.value){
             return '';
         }
         const date = new Date(params.value)
         return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
    }

    //method to format the select value
    const selectValueFormatter = (params) =>{
        if (!params.value)
        {
            return ''
        }
        return params.value.label
    }
    
    //method to format the text and number value displayed in grid
    const textValueFormatter = (params) =>{
        if(params.value === null){
            return ''
        }
        return params.value
    }

    //method to show the player detail
    const showDetail = (data) =>{
        setClickedRow(data)
        setShowPlayer(true)
    }
    
    //method to format the number value
   const numberValueFormatter = (params) =>{
    if (!params.value)
        {
            return ''
        }
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
   }
    
     
     const [columnDefs] = useState([
         {headerName:'Id', field: 'id', hide: true },
         {headerName:'Staff ID', field: 'playerId', cellRenderer: TextRenderer, cellRendererParams:{showDetail: showDetail} },
         {headerName:'Staff Photo', field: 'playerPhoto', cellRenderer: ImageRenderer, cellRendererParams:{showDetail: showDetail}},
         {headerName:'First Name', field: 'firstName', valueFormatter: textValueFormatter},
         {headerName:'Middle Name', field: 'middleName', valueFormatter: textValueFormatter},
         {headerName:'Last Name', field: 'lastName', valueFormatter: textValueFormatter},
         {headerName:'Full Name', field: 'fullName', valueFormatter: textValueFormatter},
         {headerName:'Date of Birth', field: 'dob',  valueFormatter:dateFormatter},
         {headerName:'Place of Birth', field: 'placeOfBirth', valueFormatter: textValueFormatter},
         {headerName:'Citizen Ship', field: 'citizenShip', valueFormatter: selectValueFormatter},
         {headerName:'Nationality', field: 'currentNational', valueFormatter: selectValueFormatter},
         {headerName:'Height(m)', field: 'height', valueFormatter: textValueFormatter},
         {headerName:'Weight', field: 'weight',valueFormatter: textValueFormatter},
         {headerName:'Position', field: 'position', valueFormatter: selectValueFormatter},
         {headerName:'Market Value', field: 'marketValue', valueFormatter: numberValueFormatter},
         {headerName:'Foot', field: 'foot', valueFormatter: selectValueFormatter},
         {headerName:'Status', field: 'status', valueFormatter: selectValueFormatter},
         {headerName: 'Created At', field:'createdAt', valueFormatter: dateFormatter, hide:true}
     ]);
     const [masterData, setMasterData] = useState({
        id:'',
        playerId: '',
        playerPhoto: '',
        firstName:'',
        middleName:'',
        lastName: '',
        fullName:'',
        dob: '',
        placeOfBirth:'',
        citizenShip:'',
        currentNational:'',
        height:'',
        weight:'',
        position:'',
        marketValue:'',
        foot:'',
        status:'',
        createdAt:''
     })
     
     const [masterDataSearch, setMasterDataSearch] = useState({
            playerId: '',
            playerPhoto: '',
            firstName:'',
            middleName:'',
            lastName: '',
            fullName: '',
            dob: '',
            placeOfBirth:'',
            citizenShip:'',
            currentNational:'',
            height:'',
            weight:'',
            position:'',
            marketValue:'',
            foot:'',
            status:''
     })
 
     const rowClassRules = {
         'selected-row': (params) => selectedRows.includes(params.data.masterId),
         'ag-row-even': (params) => params.node.rowIndex % 2 === 0,
         'ag-row-odd': (params) => params.node.rowIndex % 2 !== 0,
     }
     

     //use-effect method to persist the selection state and reapply it
     useEffect(() =>{
         if(gridApiRef.current.api){
             gridApiRef.current.api.forEachNode(node =>{
                 node.setSelected(selectedRows.includes(node.data))
             })
         }
     }
     ,[rowData, selectedRows])
     
  
 
     //methods to remove time out 
     const removeTimeout = () =>{
         if(loadingTimoutRef.current){
             clearTimeout(loadingTimoutRef.current)
         } 
     }
 
     //method to be executed on ag-grid ready, resize the ag-grid column as per screen width
     const onGridReady = (params) =>{
         gridApiRef.current = params;
        // params.api.sizeColumnsToFit();
         
         params.api.forEachNode(node =>{
             node.setSelected(selectedRows.includes(node.data))
         })
          
     }
 
     //defaut ag-grid column attributes
     const defaultColDef = {
         resizable: true,
         sortable: true,
        // flex: 1,
         filter: true,
         width:220
        
     }
 
     
     //method to handle selected rows of ag-grid change
     const onSelectionChanged = (e) =>{
         const selectedRows = e.api.getSelectedRows();
         setSelectedRows(selectedRows)
         setMasterData(selectedRows[0]) 
         if(selectedRows[0] && selectedRows[0].playerPhoto) {
            setFName(selectedRows[0].playerPhoto.split('/').pop())
            setProfile(null)
         } else{
            setFName('No file chosen')
         }
                     
     }
    
 
    // method to handle refresh 
    const refresh = async (id) =>{
     try{
         setLoading(true);
         loadingTimoutRef.current = setTimeout(() => {
             setLoading(false);    
         }, 60000);
 
        const crt = {id: id}
        const response = await getPlayers(crt);
        
        const updatedResponse = response.map(rs =>{
            const cShip = nationalityOptions?.find(opt => opt.label === rs.citizenShip)
            const nation = nationalityOptions?.find(opt => opt.label === rs.currentNational)
            const ft = footOptions?.find(opt => opt.label === rs.foot)
            const sts = statusOptions?.find(opt => opt.label === rs.status)
            const pos = positionOptions?.find(opt => opt.label === rs.position)
            return {
                ...rs,
                citizenShip: cShip, 
                currentNational: nation, 
                foot: ft, 
                status: sts, 
                position: pos
            }
        })
        
        
       

         setRowData(updatedResponse)
         setLoading(false)
         removeTimeout()
 
     } catch(err){
         console.log('Error fetching rowData: ', err)
     }
    }

    
    // method to close the popup in player detail
    const closeHandler = () =>{
        setShowPlayer(false)
    }

 
    //method to excute when search button clicked
    const showSearchDiv = () => {
         if(showSearch){
             setShowSearch(false)
         } else{
             setShowSearch(true)
         }
    }
 
    
 
    //method to be excuted when add button click
    const showAddDiv = () => {
         clearAdd()
         setModuleTitle('Create New record')
         setIsEdit(false)
         if(showAdd){
             setShowAdd(false)
         } else{
             setShowAdd(true)
         }
     }
 
     //methods to be excuted when copy button clicks
     const showCopyDiv = () =>{
         setModuleTitle('Copy & Create record')
         setIsEdit(false)
         if(showAdd){
             setShowAdd(false)
         } else{
             setShowAdd(true)
             setMasterData(selectedRows[0]) 
             if(selectedRows[0] && selectedRows[0].playerPhoto) {
                setFName(selectedRows[0].playerPhoto.split('/').pop())
                setProfile(null)
             } else{
                setFName('No file chosen')
             }
         }
         
     }
     //methos to be execute when edit button clicks
     const showEditDiv = () =>{
         setModuleTitle('Edit a record')
         setIsEdit(true)
         if(showAdd){
             setShowAdd(false)
         } else{
             setShowAdd(true)
             setMasterData(selectedRows[0]) 
             if(selectedRows[0] && selectedRows[0].playerPhoto) {
                setFName(selectedRows[0].playerPhoto.split('/').pop())
                setProfile(null)
             } else{
                setFName('No file chosen')
             } 
         }
         
     }
 
     //method to be executed when delete button clicks
     const showDeleteDiv = (e) =>{
         setShowPopup(true);
     }
     
 
     //method to be executed when cancel button clicks
     const handleCancel = () =>{
         setShowPopup(false)
     }
 
 
     //method to be executed when cancel button of search module  clicks
    const cancelSearch = () =>{
         setShowSearch(false)
    }
 
    //method to be excuted when cancel button of add module clicks
    const cancelAdd = () =>{
         setShowAdd(false)
    }
 
   

    //handle master search data change
    const handleMasterSeachChange = (e)=>{
        const {name, value} = e.target;
        setMasterDataSearch({ ...masterDataSearch, [name]: value})
    }

    
    //handle master search Select data change
    const handleMasterSeachSelectChange = (selectedOption, actionMeta)=>{
        const {name} = actionMeta;
        setMasterDataSearch({ ...masterDataSearch, [name]: selectedOption})
    }
 
     //handle master data change
    const handleMasterChange = (e) =>{
         const {name, value} = e.target;
         setMasterData({ ...masterData, [name]: value})
    }
     //handle master data Select change
     const handleMasterSelectChange = (selectedOption, actionMeta) =>{
        const {name } = actionMeta;
        
        setMasterData({ ...masterData, [name]: selectedOption})
   }


    //handle file change
    const handleMasterFilechange =  async (e)=>{
        const image = e.target.files[0]
        const options = {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 300,
            useWebWorker: true
        }
        
        if(image){
            try{
                const compressedFile = await imageCompression(image, options)
                const newImg = new File([compressedFile], image.name, {type: image.type})
                setMasterData({ ...masterData ,playerPhoto: newImg})
                setProfile(newImg)
                setFName(image.name)
            } catch(err){
                console.error('Error compressing the image')
            }
            
        } else{
            setFName('No file chosen')
        }
        
       
    }
 
    // handle seach button actions
    const submitSearch = async () =>{
     try{
         setLoading(true);
         loadingTimoutRef.current = setTimeout(() => {
             setLoading(false);
         }, 60000);
 
         const response = await getPlayers(masterDataSearch);
         if(!response.ok){
            setMasterErrors({...masterErrors, 'error': response.error})
        }

         const updatedResponse = response.map(rs =>{
            const cShip = nationalityOptions?.find(opt => opt.label === rs.citizenShip)
            const nation = nationalityOptions?.find(opt => opt.label === rs.currentNational)
            const ft = footOptions?.find(opt => opt.label === rs.foot)
            const sts = statusOptions?.find(opt => opt.label === rs.status)
            const pos = positionOptions?.find(opt => opt.label === rs.position)
            return {
                ...rs,
                citizenShip: cShip, 
                currentNational: nation, 
                foot: ft, 
                status: sts, 
                position: pos
            }
        })

         setRowData(updatedResponse)
         setShowSearch(false)
         setLoading(false);
         removeTimeout(); 
 
     } catch(err){
         console.log('Error fetching rowData: ', err)
     }
    }
     
    //method to crear the seach module fields
    const clearSearch = () =>{
        setMasterDataSearch({
            playerId: '',
            playerPhoto: '',
            firstName:'',
            middleName:'',
            lastName: '',
            fullName: '',
            dob: '',
            placeOfBirth:'',
            citizenShip:'',
            currentNational:'',
            height:'',
            weight:'',
            position:'',
            marketValue:'',
            foot:'',
            status:''
        })
    }
 
    //method to clear the add module fields
    const clearAdd = () =>{
     setMasterData({
        playerId: '',
        playerPhoto: '',
        firstName:'',
        middleName:'',
        lastName: '',
        fullName: '',
        dob: '',
        placeOfBirth:'',
        citizenShip:'',
        currentNational:'',
        height:'',
        weight:'',
        position:'',
        marketValue:'',
        foot:'',
        status:'',
        createdAt:''
     });
    }
 
   
    //method to validate the input feilds
    const validateForm = async () =>{
     try{
         await playersSchema.validate(masterData, {abortEarly: false})
         setMasterErrors({})
         return true
     } catch(err){
         const validationErrors = {};
         err.inner?.forEach(error => {
             validationErrors[error.path] = error.message
         })
         
         setMasterErrors(validationErrors)
         return false;
     }
    }
 
    //method to handle the delete button actions
    const handleDelete = async () =>{
     try{
             for(const rw of selectedRows){
                 const id = rw.id;
                 const res =await deletePlayer(id)
                 if(!res.ok){
                    setMasterErrors({...masterErrors, 'error': res.error})
                }
             }     
             setShowPopup(false)
             refresh()
         } catch(err){
             if(err.response){
                 const validationErrors = {};
                 validationErrors['error'] = err.response.data.error
                 setMasterErrors(validationErrors)
                 
             } else if(err.request){
                 console.error(err.request)
                 console.log('no respone from server')
             } else{
                 console.error('error', err.message)
                 console.log('unkown error')
             }
         }
    }

    
    
   
    //method to handle save button actions
    const submitAdd = async (e) =>{
         e.preventDefault();
         const now = new Date();
         const createdAt = now.toISOString();

         const addData = { ...masterData, createdAt}
         
         const isValid = await validateForm()
         if(isValid){
             try{
                 if(isEdit){
                    const res = await editPlayer(addData.id, addData, profile)
                     if(!res.ok){
                        setMasterErrors({...masterErrors, 'error': res.error})
                    }
                     setShowAdd(false)
                     refresh(addData.id)
                 } else{
                    const res = await addPlayer(addData)
                    if(!res.ok){
                        setMasterErrors({...masterErrors, 'error': res.error})
                    }
                     clearAdd()
                     setShowAdd(false)
                     refresh(res.id)
                 }
                 
             } catch(err){
                 if(err.response){
                     const validationErrors = {};
                     validationErrors['error'] = err.response.data.error
                     setMasterErrors(validationErrors)
                     
                 } else if(err.request){
                     console.error(err.request)
                     console.log('no respone from server')
                 } else{
                     console.error('error', err.message)
                     console.log('unkown error')
                 }
             }
         } else {
            console.log('validation error: ', )
         }
    }
 
    
    //method to generate the next id
    const generateMasterID = async (e) =>{
         const response = await getPlayers();
         
         if(response.length > 0){
             const plyrId = response[response.length - 1].playerId;
             const nextID = parseInt(plyrId.substring(1)) + 1
             setMasterData({ ...masterData, playerId: 'S' + nextID.toString().padStart(7, '0')})
         } else{
             setMasterData({ ...masterData, playerId: 'S0000001'})
         }
        
    }
 
    //method to handle export button actions
    const handleExport = () =>{
         const params = {
             fileName: 'players_export.csv',
             columnSeparator: ','
         }
         gridApiRef.current.api.exportDataAsCsv(params)
    }
 
    const getSelectOptions = async (masterId) => {
        try{
                const response = await getlookupDetail(masterId);
           
                const formatedOptions = response.map(opt => ({
                    value: opt.detailId,
                    label: opt.value
                }))
                return formatedOptions;
        } catch(err){
            console.log('Error fetching rowData: ', err)
        }
        
        
    };
   
    useEffect(()=>{
       try{
        const footOpt = async()=>{
            const fopt = await getSelectOptions(1);
            const popt = await getSelectOptions(2);
            const sopt = await getSelectOptions(3);
            const nopt = await getSelectOptions(4);

            setFootOptions(fopt);
            setPositionOptions(popt);
            setStatusOptions(sopt);
            setNationalityOptions(nopt);
            setIsLoading(false)
        };
        footOpt()
       } catch(err){
        setIsLoading(true)
       } 

       /*
       const agContainer = document.querySelector('.ag-grid-container')
       if(agContainer){
        const targetDiv = agContainer.querySelector('div[style*="height: 100%"]')
        if(targetDiv){
            targetDiv.style.display = "flex"
            targetDiv.style.flexDirection = "column"
        }
       }
        */
        
    },[])

  

 
    // search fields
    const searchFields = [
         {id: 'playerId', desc:'Player ID', name: 'playerId', type:'text', value:masterDataSearch.playerId, onChange:handleMasterSeachChange},
         {id: 'firstName', desc:'First Name', name: 'firstName', type:'text', value:masterDataSearch.firstName, onChange:handleMasterSeachChange},
         {id: 'middleName', desc:'Middle Name', name: 'middleName', type:'text', value:masterDataSearch.middleName, onChange:handleMasterSeachChange},
         {id: 'lastName', desc:'Last Name', name: 'lastName', type:'text', value:masterDataSearch.lastName, onChange:handleMasterSeachChange},
         {id: 'fullName', desc:'Full Name', name: 'fullName', type:'text', value:masterDataSearch.fullName, onChange:handleMasterSeachChange},
         {id: 'dob', desc:'Birth Date', name: 'dob', type:'date', value:masterDataSearch.dob, onChange:handleMasterSeachChange},
         {id: 'placeOfBirth', desc:'Birth Place', name: 'placeOfBirth', type:'text', value:masterDataSearch.placeOfBirth, onChange:handleMasterSeachChange},
         {id: 'citizenShip', desc:'Citizen Ship', name: 'citizenShip', type:'Select', options:nationalityOptions, placeHolder:'Select country...', value:masterDataSearch.citizenShip, onChange:handleMasterSeachSelectChange},
         {id: 'currentNational', desc:'Nationality', name: 'currentNational', type:'Select', options:nationalityOptions, placeHolder:'Select country...', value:masterDataSearch.currentNational, onChange:handleMasterSeachSelectChange},
         {id: 'height', desc:'Height', name: 'height', type:'text', value:masterDataSearch.height, onChange:handleMasterSeachChange},
         {id: 'weight', desc:'Weight', name: 'weight', type:'text', value:masterDataSearch.weight, onChange:handleMasterSeachChange},
         {id: 'marketValue', desc:'Market Value', name: 'marketValue', type:'text', value:masterDataSearch.marketValue, onChange:handleMasterSeachChange},
         {id: 'position', desc:'Position', name: 'position', type:'Select', options:positionOptions, placeHolder:'Select Position...', value:masterDataSearch.position, onChange:handleMasterSeachSelectChange},
         {id: 'foot', desc:'Foot', name: 'foot', type:'Select', options:footOptions, placeHolder:'Select foot...', value:masterDataSearch.foot, onChange:handleMasterSeachSelectChange},
         {id: 'status', desc:'Status', name: 'status', type:'Select', options:statusOptions, placeHolder:'Select status...', value:masterDataSearch.status, onChange:handleMasterSeachSelectChange},

     ];
 
    

     const addFields = [
         {id: 'playerId', desc:'Player ID', name: 'playerId', type:'text', readOnly:isEdit, isGenerate: true, text:'Generate', onClick:generateMasterID, value:masterData? masterData.playerId : '', onChange:handleMasterChange},
         {id: 'firstName', desc:'First Name', name: 'firstName', type:'text',readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.firstName: '', onChange:handleMasterChange},
         {id: 'middleName', desc:'Middle Name', name: 'middleName', type:'text',readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.middleName:'', onChange:handleMasterChange},
         {id: 'lastName', desc:'Last Name', name: 'lastName', type:'text',readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.lastName:'', onChange:handleMasterChange},
         {id: 'fullName', desc:'Full Name', name: 'fullName', type:'text',readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.fullName:'', onChange:handleMasterChange},
         {id: 'position', desc:'Position', name: 'position', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:positionOptions, placeHolder:'Select Position...', value:masterData? masterData.position:'', onChange:handleMasterSelectChange},
         {id: 'playerPhoto', desc:'Photo', name: 'playerPhoto', type:'file', profile:profile, fname, readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.playerPhoto:'', onChange:handleMasterFilechange},
         {id: 'dob', desc:'Birth Date', name: 'dob', type:'date', readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.dob: '', onChange:handleMasterChange},
         {id: 'placeOfBirth', desc:'Birth Place', name: 'placeOfBirth', type:'text',readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.placeOfBirth:'', onChange:handleMasterChange},
         {id: 'citizenShip', desc:'Citizen Ship', name: 'citizenShip', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:nationalityOptions, placeHolder:'Select country...', value:masterData? masterData.citizenShip:'', onChange:handleMasterSelectChange},
         {id: 'currentNational', desc:'Nationality', name: 'currentNational', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:nationalityOptions, placeHolder:'Select country...', value:masterData? masterData.currentNational:'', onChange:handleMasterSelectChange},
         {id: 'height', desc:'Height(m)', name: 'height', type:'text',readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.height:'', onChange:handleMasterChange},
         {id: 'weight', desc:'Weight', name: 'weight', type:'text',readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.weight:'', onChange:handleMasterChange},
         {id: 'marketValue', desc:'Market Value(m)', name: 'marketValue', type:'text',readOnly:false, isGenerate: false,onClick:'', value:masterData? masterData.marketValue:'', onChange:handleMasterChange},
         {id: 'foot', desc:'Foot', name: 'foot', type:'Select', readOnly:false, isGenerate: false,onClick:'', options:footOptions, placeHolder:'Select foot...', value:masterData? masterData.foot: '', onChange:handleMasterSelectChange},
         {id: 'status', desc:'Status', name: 'status', type:'Select',readOnly:false, isGenerate: false,onClick:'', options:statusOptions, placeHolder:'Select status...', value:masterData? masterData.status:'', onChange:handleMasterSelectChange},

     ];
 
     return(
         <div>
             <ShowDetail showPopup={showPlayer} type='profile' onCancelHandler={closeHandler} content={clickedRow} />
             <ConfirmationPopup showPopup={showPopup} onDeleteHandler={handleDelete} onCancelHandler={handleCancel} content="Are you sure you want to delete the selected records?" />  
                
                 <div>
                     <div className="current-page">
                         <p ><Link to="/dashboard" className="link">DashBoard</Link></p>
                         <p>/</p>
                         <p>Players</p>
                     </div>
                     <div className="page-content">
                         <Actions title='Adjust players here' isLoading={isloading} refresh={refresh} showSearchDiv={showSearchDiv}  showAddDiv={showAddDiv} showCopyDiv={showCopyDiv} showEditDiv={showEditDiv} showDeleteDiv={showDeleteDiv} handleExport={handleExport} rowData={rowData} selectedRows={selectedRows} />
                         
                         <div className="page-body">
 
                             {
                                 showSearch ?
                                 <SearchMod searchFields={searchFields} submitSearch={submitSearch} cancelSearch={cancelSearch} clearSearch={clearSearch}/>
                                 : ''
                             }
                             
                             <div className="ag-theme-quartz ag-grid-container" >
                                 <AgGridReact 
                                     gridOptions={gridOptions}
                                     columnDefs={columnDefs}
                                     rowData={rowData} 
                                     rowSelection="multiple" 
                                     defaultColDef={defaultColDef}
                                     domLayout="autoHeight"
                                     onSelectionChanged={onSelectionChanged}
                                     pagination={true}
                                     paginationPageSize={20}
                                     paginationPageSizeSelector={[20, 40]}
                                     loadingOverlayComponentFramework={CustomLoadingOverlay}
                                     loading={loading}
                                     onGridReady={onGridReady}
                                     ref={gridApiRef}
                                     rowClassRules={rowClassRules}
                                     alwaysShowVerticalScroll={true}
                                     alwaysShowHorizontalScroll={true}
                                 />
                         </div>
 
                            {
                                showAdd &&
                                <AddMod addFields={addFields}  submitAdd={submitAdd} moduleTitle={moduleTitle} masterErrors={masterErrors} cancelAdd={cancelAdd} clearAdd={clearAdd} />
                            }
                        
                         </div>
                         <div className="page-footer">
                         </div>
 
                     </div>
                 </div>
                 
         </div>
     );
}
export default Players;