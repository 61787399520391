
import  { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch,  useSelector } from 'react-redux';
import imageCompression from 'browser-image-compression'
import { getlookupDetail } from '../services/lookupService';
import { usersSchemaEdit } from '../Validations/usersValidation';
import { editUser } from '../services/usersService';
import Select from "react-select"
import person2 from '../Assets/images/person-2.png'
import { setUser } from '../slices/authSlice';

//const backEndUrl = "http://localhost:8000/"
const backEndUrl = "https://footballback.zeaye.com/"

function Profile () {
    const userState = useSelector((state) => state.auth)
    const [profile, setProfile] = useState(null)
    //const [fname, setFName] = useState('No file chosen')
    const [statusOptions, setStatusOptions] = useState()
    const [masterErrors, setMasterErrors] = useState({});
    const [masterSuccess, setMasterSuccess] = useState({});
    const [moduleTitle, setModuleTitle] = useState('My profile');
    const [isEdit, setIsEdit] = useState(true);

    const fileInputRef  = useRef(null)
    const {detail, token } = userState || {}

    const dispatch = useDispatch()

    const [masterData, setMasterData] = useState({
        id:detail?.id,
        photo: detail?.photo,
        first_name: detail?.first_name,
        last_name:detail?.last_name,
        email:detail?.email,
        username:detail?.username,
        phoneNbr:detail?.phoneNbr,
        address: detail?.address,
        date_joined:detail?.date_joined,
        verifiedAt:detail?.verifiedAt,
        loginAttempts:detail?.loginAttempts,
        createdAt:detail?.createdAt,
        is_superuser: statusOptions?.find(opt => opt.value === (detail?.is_superuser  ? 1: 0)),
        is_staff:statusOptions?.find(opt => opt.value === (detail?.is_staff ? 1: 0)),
        is_active:statusOptions?.find(opt => opt.value === (detail?.is_active  ? 1: 0)),
        is_otp:statusOptions?.find(opt => opt.value === (detail?.isOtp  ? 1: 0)),
     })

    const resetProfile = () =>{
        setMasterData({
            id:detail?.id,
            photo: detail?.photo,
            first_name: detail?.first_name,
            last_name:detail?.last_name,
            email:detail?.email,
            username:detail?.username,
            phoneNbr:detail?.phoneNbr,
            address: detail?.address,
            date_joined:detail?.date_joined,
            verifiedAt:detail?.verifiedAt,
            loginAttempts:detail?.loginAttempts,
            createdAt:detail?.createdAt,
            is_superuser: statusOptions?.find(opt => opt.value === (detail?.is_superuser  ? 1: 0)),
            is_staff:statusOptions?.find(opt => opt.value === (detail?.is_staff  ? 1: 0)),
            is_active:statusOptions?.find(opt => opt.value === (detail?.is_active  ? 1: 0)),
            is_otp:statusOptions?.find(opt => opt.value === (detail?.isOtp ? 1: 0)),
        })
        setProfile(null) 
    }

    //handle master data change
    const handleMasterChange = (e) =>{
        const {name, value} = e.target;
        setMasterData({ ...masterData, [name]: value})
    }
    //handle file change
  const handleMasterFilechange =  async (e)=>{
        const image = e.target.files[0]
        const options = {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 300,
            useWebWorker: true
        }
        
        if(image){
            try{
                const compressedFile = await imageCompression(image, options)
                const newImg = new File([compressedFile], image.name, {type: image.type})
                setMasterData({ ...masterData ,photo: newImg})
                setProfile(newImg)
               // setFName(image.name)
            } catch(err){
                console.error('Error compressing the image')
            }
            
        } else{
           // setFName('No file chosen')
        }
    }

    
    //handle master data Select change
    const handleMasterSelectChange = (selectedOption, actionMeta) =>{
        const {name } = actionMeta;
        
        setMasterData({ ...masterData, [name]: selectedOption})
        
    }

    //method to validate the input feilds
  const validateForm = async () =>{
    try{
        await usersSchemaEdit.validate(masterData, {abortEarly: false})
        setMasterErrors({})
        return true
    } catch(err){
        const validationErrors = {};
        err.inner?.forEach(error => {
            validationErrors[error.path] = error.message
        })
        console.log(validationErrors)
        setMasterErrors(validationErrors)
        return false;
    }
   }
 

     //method to handle save button actions
    const submitEdit = async (e) =>{
        e.preventDefault();
        const isValid = await validateForm()
        if(isValid){
            try{                
                const now = new Date();
                const updatedAt = now.toISOString();
                const updatedBy = detail?.id;
                const addData = { ...masterData, updatedBy, updatedAt }
                
                var usr = await editUser(addData.id, addData, profile)

                usr.is_superuser = usr?.is_superuser === 'yes'? true : false
                usr.is_active = usr?.is_active === 'yes'? true : false
                usr.is_otp = usr?.is_otp === 'yes'? true : false
                usr.is_staff = usr?.is_staff === 'yes'? true : false

               // console.log('updated user:', usr)

                setMasterSuccess({ ...masterSuccess, 'profileMsg': 'Profile updated successfully'})
                
                setIsEdit(!isEdit)
                handleEdit()

                dispatch(setUser({detail:usr, token: token}))
                localStorage.setItem('fb-user', usr)

            } catch(err){
                if(err.response){
                    const validationErrors = {};
                    validationErrors['error'] = err.response.data.detail
                    setMasterErrors(validationErrors)
                    
                } else if(err.request){
                    console.error(err.request)
                    setMasterErrors({...masterErrors, 'error': err.request})
                    console.log('no respone from server')
                } else{
                    console.error('error', err.message)
                    setMasterErrors({...masterErrors, 'error': err.message})
                    console.log('unkown error')
                }
            }
        } else {
        console.log('validation error: ', masterErrors)
        }
    }

    // handle select options
    const getSelectOptions = async (masterId) => {
        try{
                const response = await getlookupDetail(masterId);
           
                const formatedOptions = response.map(opt => ({
                    value: opt.detailId,
                    label: opt.value
                }))
                return formatedOptions;
        } catch(err){
            console.log('Error fetching rowData: ', err)
        }
    };

    const getProfilePath = () =>{
        const profilePath = masterData?.photo ?  masterData.photo.startsWith("/media/") ? `${backEndUrl}${masterData.photo}` : `${backEndUrl}/media/${masterData.photo}` : person2
        return profilePath
    }
    
    useEffect(()=>{
        try{
        const userOpt = async()=>{
            const statusBol = await getSelectOptions(13);
            setStatusOptions(statusBol);

            setMasterData(prevMasterData => ({
                ...prevMasterData,
                is_superuser: statusBol?.find(opt => opt.value === (detail?.is_superuser  ? 1: 0)),
                is_staff:statusBol?.find(opt => opt.value === (detail?.is_staff  ? 1: 0)),
                is_active:statusBol?.find(opt => opt.value === (detail?.is_active  ? 1: 0)),
                is_otp:statusBol?.find(opt => opt.value === (detail?.isOtp  ? 1: 0)),
            }))

           // setFName(detail?.photo.split('/').pop())
        };
        userOpt()
        } catch(err){
        console.log('error in getting status')
        } 
        
    },[detail])
   

    const handleEdit = () =>{
        if(isEdit){
            setModuleTitle('Update my profile')
            setIsEdit(false)
        } else{
            setModuleTitle('My profile')
            setIsEdit(true)
            resetProfile()
        }
    }

    const handleImageClick = () =>{
        if(!isEdit){
            fileInputRef.current.click()
        }  
    }
    //method to close the success message
    const closeMsg = () =>{
        setMasterSuccess({'profileMsg': null})
    }
    return (
        <div>
            <div className="current-page">
                            <p ><Link to="/dashboard" className="link">Dashboard</Link></p>
                            <p>/</p>
                            <p>My profile</p>
            </div>
            <div className="page-content">
                <div className="page-body">
                   {
                    token &&
                    <form onSubmit={submitEdit} className="profile-module">
                            <p className="profile-title">{moduleTitle}</p>
                            <ul className='row'>
                                    {masterErrors.error && <p className="form-error-hdr">{masterErrors.error}</p>}

                                    {
                                        masterSuccess['profileMsg'] && <div className='success-msg'>
                                                                            <i className='bx bx-x btn-close' onClick={() => closeMsg()}></i>
                                                                            <p>{masterSuccess['profileMsg']}</p>
                                                                        </div>
                                    } 

                                    <li className='col-1-1'>
                                            <div className={`field-img ${masterErrors['photo'] ? 'validation': ''}`}>
                                                {masterErrors['photo'] && <span className="form-error">{masterErrors['photo']}</span>}
                                                <img src={profile? URL.createObjectURL(profile)  : getProfilePath()} alt="profile-img" onClick={handleImageClick} />
                                                <input type="file" className="prof-input"  ref={fileInputRef} name="photo" accept='image/*'  onChange={handleMasterFilechange}   />   
                                            </div> 
                                    </li>
                                    <li className='col-1-2'>
                                        <div className="field-input">
                                            {masterErrors['first_name'] && <span className="form-error">{masterErrors['first_name']}</span>}
                                            <div className={`add-row ${masterErrors['first_name'] ? 'validation': ''}`}>
                                                <label htmlFor="first_name">First Name</label>
                                                <input type="text" className="first_name"  name="first_name" value={masterData.first_name} onChange={handleMasterChange} readOnly={isEdit} />    
                                            </div>
                                        </div>

                                        <div className="field-input">
                                            {masterErrors['last_name'] && <span className="form-error">{masterErrors['last_name']}</span>}
                                            <div className={`add-row ${masterErrors['last_name'] ? 'validation': ''}`}>
                                                <label htmlFor="last_name">Last Name</label>
                                                <input type="text" className="last_name"  name="last_name" value={masterData.last_name} onChange={handleMasterChange} readOnly={isEdit} />    
                                            </div>
                                        </div>

                                        <div className="field-input">
                                            {masterErrors['email'] && <span className="form-error">{masterErrors['email']}</span>}
                                            <div className={`add-row ${masterErrors['email'] ? 'validation': ''}`}>
                                                <label htmlFor="last_name">Last Name</label>
                                                <input type="email" className="email"  name="email" value={masterData.email} onChange={handleMasterChange} readOnly={isEdit} />    
                                            </div>
                                        </div>

                                        <div className="field-input">
                                            {masterErrors['username'] && <span className="form-error">{masterErrors['username']}</span>}
                                            <div className={`add-row ${masterErrors['username'] ? 'validation': ''}`}>
                                                <label htmlFor="username">User Name</label>
                                                <input type="text" className="username"  name="username" value={masterData.username} onChange={handleMasterChange} readOnly={isEdit} />    
                                            </div>
                                        </div>
                                        <div className="field-input">
                                            {masterErrors['phoneNbr'] && <span className="form-error">{masterErrors['phoneNbr']}</span>}
                                            <div className={`add-row ${masterErrors['phoneNbr'] ? 'validation': ''}`}>
                                                <label htmlFor="phoneNbr">Phone Nbr</label>
                                                <input type="number" className="phoneNbr"  name="phoneNbr" value={masterData.phoneNbr} onChange={handleMasterChange} readOnly={isEdit} />    
                                            </div>
                                        </div>
                                    </li>
                                    <li className='col-1-2'>
                                        <div className="field-input">
                                                {masterErrors['is_superuser'] && <span className="form-error">{masterErrors['is_superuser']}</span>}
                                                <div className={`add-row ${masterErrors['supperuser'] ? 'validation': ''}`}>
                                                    <label htmlFor="is_superuser">Is Admin</label>
                                                    <Select name="is_superuser" className="search-select" classNamePrefix="search-select" id="is_superuser" options={statusOptions} value={masterData.is_superuser} onChange={handleMasterSelectChange}  placeholder="is admin..." isDisabled={isEdit}  />
                                                </div>
                                        </div>

                                        <div className="field-input">
                                                {masterErrors['is_staff'] && <span className="form-error">{masterErrors['is_staff']}</span>}
                                                <div className={`add-row ${masterErrors['is_staff'] ? 'validation': ''}`}>
                                                    <label htmlFor="is_staff">Is Staff</label>
                                                    <Select name="is_staff" className="search-select" classNamePrefix="search-select" id="is_staff" value={masterData.is_staff} onChange={handleMasterSelectChange} options={statusOptions} placeholder="is staff..." isDisabled={isEdit} />
                                                </div>
                                        </div>

                                        <div className="field-input">
                                                {masterErrors['is_active'] && <span className="form-error">{masterErrors['is_active']}</span>}
                                                <div className={`add-row ${masterErrors['is_active'] ? 'validation': ''}`}>
                                                    <label htmlFor="is_active">Is Active</label>
                                                    <Select name="is_active" className="search-select" classNamePrefix="search-select" id="is_active" value={masterData.is_active} onChange={handleMasterSelectChange} options={statusOptions} placeholder="is active..." isDisabled={isEdit} />
                                                </div>
                                        </div>

                                        <div className="field-input">
                                                {masterErrors['is_otp'] && <span className="form-error">{masterErrors['is_otp']}</span>}
                                                <div className={`add-row ${masterErrors['is_otp'] ? 'validation': ''}`}>
                                                    <label htmlFor="is_otp">OTP enabled</label>
                                                    <Select name="is_otp" className="search-select" classNamePrefix="search-select" id="is_otp" value={masterData.is_otp} onChange={handleMasterSelectChange} options={statusOptions} placeholder="is otp..." isDisabled={isEdit} />
                                                </div>
                                        </div>

                                        <div className="field-input">
                                            {masterErrors['address'] && <span className="form-error">{masterErrors['address']}</span>}
                                            <div className={`add-row ${masterErrors['address'] ? 'validation': ''}`}>
                                                <label htmlFor="address">Address</label>
                                                <input type="text" className="address"  name="address" value={masterData.address} onChange={handleMasterChange} readOnly={isEdit} />    
                                            </div>
                                        </div>
                                    </li>
                                   
                            </ul>
                            <div className="profile-footer">
                                {isEdit && 
                                    <button type="button" onClick={handleEdit}>Edit</button>
                                }
                                
                                {!isEdit &&
                                <div>
                                    <button type="submit" onClick={submitEdit}>Save</button>
                                    <button type="button" onClick={handleEdit}>Cancel</button>
                                </div>
                                
                                } 
                            </div>
                    </form>
                   }
                    
                </div>
            </div>

        </div>
    )
}
export default Profile