import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomLoadingOverlay from "../Components/Body/loadingOverlay";
import { emailVerification } from "../services/usersService";

function EmailVerification(){
    const location = useLocation()
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState()
    const navigate = useNavigate();
    

    useEffect(()=>{
        const verifyEmail = async (token) =>{
            try{
                const response = await emailVerification(token);
                setMessage(response.message)
                navigate('/login')
                setLoading(false)
            } catch(err){
                console.log('Error verifying data: ', err)
                if(err.response){
                    setMessage(err.response.data.error)
                } else{
                    setMessage('An error occured. Please try again later')
                }
                setLoading(false)
            }
        };
    
        const qry = new URLSearchParams(location.search)
        const token = qry.get('token')
        if(token){
            verifyEmail(token)
        } else{
            setMessage('No Credentials provided, try again')
            setLoading(false)
        }
    }, [location, navigate])

   
    return(
        <div>
            {loading ? <div className="lodaing-overlay-div"><CustomLoadingOverlay /> <p>Loading..., Please wait</p></div>  : <p className="lodaing-overlay-div">{message}</p>}
        </div>
    )
}
export default EmailVerification