//import Cookies from 'js-cookie';
import { axiosInstance } from '../Utils/axiosInstance';


export  function login(usr){
    //const csrftoken = Cookies.get('csrftoken');  // Get the CSRF token from the cookies
    //return axiosInstance.post('/auth/login',{
    return axiosInstance.post('/login',{
        email: usr.email,
        password: usr.passWord, 
        captchaToken: usr.captchaToken
    },
    // {
    //     withCredentials: true,
    //     headers: {
    //         'X-CSRFToken': csrftoken,  // Add the CSRF token to the headers
    //     },

    // }
    )
    .then(response => {
        return {
            status: response.status,
            result: response.data,
        };
    })
    .catch(err => {
        console.log(err)
        throw err; 
    })


}

export  function getUser(token){
    return axiosInstance.get('user',{
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
    .then(response => {return response.data})
    .catch(err => {
        console.log('error in get user:', err)
        throw err;  
    })
}
export function getUsers(usr){
    return axiosInstance.get('/profiles', {
        params:{
            id: usr ? usr.id : null,
            first_name: usr? usr.first_name: null,
            last_name: usr? usr.last_name: null,
            email: usr? usr.email: null,
            phoneNbr: usr? usr.phoneNbr: null,
            address: usr? usr.address: null,
            username: usr? usr.username: null,
            is_superuser: usr? usr.is_superuser.label:null,
            is_active: usr? usr.is_active.label:null,
            is_staff: usr? usr.is_staff.label:null,
            isOtp: usr? usr.is_otp.label:null,
            date_joined: usr? usr.date_joined: null,
            verifiedAt: usr? usr.verifiedAt: null,
            createdAt: usr? usr.createdAt: null,
            createdBy: usr? usr.createdBy: null,
            updatedAt: usr? usr.updatedAt: null,
            updatedBy: usr? usr.updatedBy: null,
        }
    })
        .then(response => response.data)
        .catch(err => {
            console.log('error in get users:', err)
            throw err; 
            
        })

    
}

export  function passwordReset(email){
    return axiosInstance.post('password/reset',{
      email:email
    })
    .then(response => {return response.data})
    .catch(err => {
        console.log('error:', err)
        throw err;  
    })
}
export  function passwordResetConfirm(usr){
    return axiosInstance.post('password/reset/confirm',{
      token: usr.token,
      newPassword: usr.newPassWordConfirm
    })
    .then(response => {return response.data})
    .catch(err => {
        console.log('error:', err)
        throw err;  
    })
}

export  function changePassword(usr){
    return axiosInstance.post('password/change',{
      token: usr.token,
      currentPassword: usr.currentPassword,
      newPassword: usr.confirmPassword
    })
    .then(response => {return response})
    .catch(err => {
        console.log('error:', err)
        throw err;  
    })
}

export  function emailVerification(token){
    return axiosInstance.get(`email/verify/confirm/?token=${token}`)
    .then(response => {return response.data})
    .catch(err => {
        console.log('error:', err)
        throw err; 
    })
}

export  function verifyOTP(otp, user){
    return axiosInstance.post('otp/verify',
        {
            otp: otp,
            user_id: user.id
        }
    )
    .then(response => {return response})
    .catch(err => {
        console.log('error in verify otp:', err)
        throw err; 
        
    })
}

export function deleteUser(id){
    return axiosInstance.delete('/profiles/' + id + '/',
        { withCredentials: true }
    )
    .then(response => response.data)
    .catch(err => {
        console.log('error in delete user:', err)
        throw err; 
        
    })
}

export function editUser(id, usr, profile){
    let data
    if(profile){
        data = {
            photo: profile,
            first_name: usr.first_name,
            last_name: usr.last_name,
            email: usr.email,
            username: usr.username,
            is_superuser:usr.is_superuser?.value,
            is_active: usr.is_active?.value,
            is_staff: usr.is_staff?.value,
            phoneNbr: usr.phoneNbr,
            address: usr.address,
            isOtp: usr.is_otp?.value,
            updatedAt: usr.updatedAt,
            updatedBy: usr.updatedBy,
        }
    } else{
        data = {
            first_name: usr.first_name,
            last_name: usr.last_name,
            email: usr.email,
            username: usr.username,
            is_superuser:usr.is_superuser?.value,
            is_active: usr.is_active?.value,
            is_staff: usr.is_staff?.value,
            phoneNbr: usr.phoneNbr,
            address: usr.address,
            isOtp: usr.is_otp?.value,
            updatedAt: usr.updatedAt,
            updatedBy: usr.updatedBy,
        }
    }
    
    const formData = createFormData(data)
    return axiosInstance.put('/profiles/' + id + '/',formData,
       { withCredentials: true },
      )
    .then(response => response.data)
    .catch(err => {
        console.log('error in edit user:', err)
        throw err; 
        
    })
}
//method to create form data
const createFormData = (data) => {
       
    const formData = new FormData();

    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    });
    return formData;
};
export  function addUser(usr){
    const data = {
        photo: usr.photo,
        first_name: usr.first_name,
        last_name: usr.last_name,
        email: usr.email,
        username: usr.username,
        password: usr.password,
        is_superuser:usr.is_superuser?.value,
        is_active: usr.is_active?.value,
        is_staff: usr.is_staff?.value,
        phoneNbr: usr.phoneNbr,
        address: usr.address,
        isOtp: usr.is_otp?.value,
        createdAt: usr.createdAt,
       
    }
    
    const formData = createFormData(data)
    return axiosInstance.post('/profiles',formData,
    { withCredentials: true },
    )
    .then(response => response.data)
    .catch(err => {
        console.log('error in add user:', err)
        throw err; 
        
    })
}




